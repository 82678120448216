import { API } from '@/api/base';
import { Response } from '@/api/response';

const backendURL = process.env.REACT_APP_BACKEND_URL as string;

const endpoints = {
  getCallSummary: (callId: string, isPublic: boolean) =>
    backendURL + `/api/calls/${callId}/${isPublic ? 'public/' : ''}summary`,
};

export interface CallSummaryItemResponse {
  id: string;
  callId: string;
  text: string;
  timestamp: string | null;
  createdAt: string;
  updatedAt: string;
}

export const getCallSummary =
  (callId: string, isPublic: boolean) =>
  async (): Promise<CallSummaryItemResponse[]> => {
    try {
      const response = await API(endpoints.getCallSummary(callId, isPublic), {
        method: 'GET',
      });

      const result: Response<CallSummaryItemResponse[]> = await response.json();

      return result.data;
    } catch (err) {
      console.error('fetch call summary', err);
      return [];
    }
  };
