import { put, takeLatest } from 'redux-saga/effects';

import logger from '../../../utils/logger';
import { leadershipDashboardActions as actions } from '../slice';

const backendURL = process.env.REACT_APP_BACKEND_URL;

/**
 *
 * @param action - action.payload.timeFilter
 * @yields fetch
 */
function* doFetchOrganizationAveragePerformance(action) {
  try {
    let query;
    if (action.payload) {
      const { timeFilter } = action.payload;

      if (timeFilter) {
        query = `timeFilter=${timeFilter}`;
      }
    }

    const response = yield fetch(
      backendURL + `/api/organization/performance/average?${query}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      },
    );

    const body = yield response.json();

    yield put(actions.fetchAveragePerformanceSuccess(body));
  } catch (err) {
    logger.error('Fetch organization average performance Error: ', err);
    throw err;
  }
}

/**
 *
 * @param action
 */
function* doFetchRepsPerformance(action) {
  try {
    let query;
    if (action.payload) {
      const { timeFilter, page, size } = action.payload;

      if (page !== undefined && size !== undefined) {
        query = `page=${page}&size=${size}`;
      }

      if (timeFilter) {
        const filterQuery = `timeFilter=${timeFilter}`;
        query += query?.length ? `&${filterQuery}` : filterQuery;
      }
    }

    const response = yield fetch(
      backendURL + `/api/organization/performance/reps?${query}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      },
    );

    const body = yield response.json();

    yield put(actions.fetchRepsPerformanceSuccess(body));
  } catch (err) {
    log('Fetch organization reps performance Error: ', err);
    throw err;
  }
}

/**
 *
 * @param action
 */
function* doFetchTeamInsights(action) {
  try {
    let query;
    if (action.payload) {
      const { timeFilter, metricKey } = action.payload;

      if (timeFilter && metricKey) {
        query = `timeFilter=${timeFilter}&metricKey=${metricKey}`;
      }
    }

    const response = yield fetch(
      backendURL + `/api/organization/team?${query}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      },
    );

    const body = yield response.json();

    yield put(actions.fetchTeamInsightsSuccess(body));
  } catch (err) {
    log('Fetch organization team insights Error: ', err);
    throw err;
  }
}

/**
 *
 */
export function* leadershipDashboardSaga() {
  yield takeLatest(
    actions.fetchAveragePerformance.type,
    doFetchOrganizationAveragePerformance,
  );
  yield takeLatest(actions.fetchRepsPerformance.type, doFetchRepsPerformance);
  yield takeLatest(actions.fetchTeamInsights.type, doFetchTeamInsights);
}
