// import { ChevronLeft, Menu } from 'react-feather';
//
// import { Button } from '@/components/Button/Button';
// import cn from '@/utils/cn';

interface CallsListTogglerProps {
  isOpen: boolean;
  // toggle: () => void;
}

export const CallsListToggleButton: React.FC<CallsListTogglerProps> = (
  {
    // isOpen,
    // toggle,
  }: CallsListTogglerProps,
) => (
  <div className="inline-block mt-0 ml-8 z-50 select-none cursor-pointer">
    {/*<Button*/}
    {/*  disabled={true}*/}
    {/*  onClick={toggle}*/}
    {/*  variant="secondary-inverted"*/}
    {/*  className={cn(*/}
    {/*    isOpen*/}
    {/*      ? 'bg-main-gray hover:bg-[#E0E2E4] disabled:hover:bg-main-gray'*/}
    {/*      : 'bg-light-gray hover:bg-main-gray',*/}
    {/*    'flex gap-1 justify-center items-center rounded-lg-1.5 py-2.5 px-3 h-9',*/}
    {/*  )}*/}
    {/*>*/}
    {/*  {isOpen && <ChevronLeft size={16} />}*/}
    {/*  {!isOpen && <Menu size={16} />}*/}
    {/*  {isOpen && <span className="text-dark text-sm font-semibold">Calls</span>}*/}
    {/*</Button>*/}
  </div>
);
