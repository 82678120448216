import { useQuery } from '@tanstack/react-query';

import {
  CallSummaryItemResponse,
  getCallSummary as getCallSummaryAPI,
} from '@/api/call/get-call-summary';

export const useGetCallSummary = (callId: string, isPublic: boolean) => {
  const { data: callSummary, ...rest } = useQuery<CallSummaryItemResponse[]>({
    queryFn: getCallSummaryAPI(callId, isPublic),
    queryKey: ['callSummary', callId],
  });

  return { callSummary, ...rest };
};
