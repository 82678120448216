import { EventSourcePolyfill } from 'event-source-polyfill';
import { useEffect, useState } from 'react';

import { SseEvents, SseEventsToURL } from '@/hooks/sse/SseEventsEnum';

export function useSSE<T>(event: SseEvents) {
  const [data, setData] = useState<T | null>(null);

  const url = SseEventsToURL[event];

  useEffect(() => {
    const eventSource = new EventSourcePolyfill(url, {
      headers: {
        Authorization: `jwt ${localStorage.getItem('authToken')}`,
      },
    });

    eventSource.onopen = () => {
      console.log('Connection to server opened.');
    };

    eventSource.onerror = err => {
      console.log('Error occurred while connecting.', err);
    };

    eventSource.onmessage = event => {
      try {
        console.log(event);

        const data = JSON.parse(event.data);
        console.log('Received data:', data);
        setData(data);
      } catch {
        console.log('Something went wrong while receiving data');
      }
    };

    return () => {
      eventSource.close();
    };
  }, []);

  return { data };
}
