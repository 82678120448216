import React, { useState } from 'react';

import logger from '@/utils/logger';

import { Button } from '../Button/Button';
import { FileSelector } from '../FileSelector/FileSelector';
import { Label } from '../Label/Label';
import { Select, SelectProps } from '../Select/Select';
import Modal from '../Shared/Modal';
import { TextArea } from '../TextArea/TextArea';
import { toast } from '../Toast/toaster';
import { useHelpModalStore } from './useHelpModalStore';
import { useSendHelpRequest } from './useSendHelpRequest';

const helpOptions = [
  {
    value: 'help',
    title: 'Help with using SilkChart',
  },
  {
    value: 'issue',
    title: 'Report an issue',
  },
  {
    value: 'feature',
    title: 'Request a feature',
  },
  {
    value: 'subscription',
    title: 'Subscription and pricing',
  },
  {
    value: 'else',
    title: 'Something else',
  },
] satisfies SelectProps['options'];

export const HelpModal: React.FC = () => {
  const { isHelpModalOpen, closeHelpModal, initialSelectedOption } =
    useHelpModalStore();
  const [topic, setTopic] = useState(helpOptions[0].value);
  const [details, setDetails] = useState('');
  const [files, setFiles] = useState<File[]>([]);

  const resetForm = () => {
    setTopic(helpOptions[0].value);
    setDetails('');
    setFiles([]);
  };

  React.useEffect(() => {
    if (
      initialSelectedOption &&
      helpOptions.some(option => option.value === initialSelectedOption)
    ) {
      setTopic(initialSelectedOption);
    }
  }, [initialSelectedOption]);

  const { sendHelpRequest, isPending } = useSendHelpRequest();

  return (
    <Modal
      show={isHelpModalOpen}
      onClose={() => {
        if (isPending) {
          return;
        }
        closeHelpModal();
      }}
      showCloseButton={!isPending}
    >
      <div className="min-w-[640px]">
        <Modal.Title>How can we help?</Modal.Title>
        <Modal.Subtitle>We usually respond within a few hours</Modal.Subtitle>

        <div className="mt-6 mb-4 border-t w-[150%] -ml-[25%] border-t-main-gray" />

        <div className="flex flex-col gap-4">
          <div>
            <Label htmlFor="help_topic">Topic</Label>
            <Select
              buttonProps={{
                id: 'help_topic',
              }}
              options={helpOptions}
              value={topic}
              onChange={setTopic}
              disabled={isPending}
            />
          </div>

          <div>
            <Label htmlFor="help_details">Details</Label>
            <TextArea
              id="help_details"
              className="h-28"
              placeholder="Type a message..."
              value={details}
              onChange={e => {
                setDetails(e.target.value);
              }}
              disabled={isPending}
            />
          </div>

          <FileSelector
            files={files}
            onChange={setFiles}
            disabled={isPending}
          />

          <div className="flex gap-1 justify-end">
            <Button variant="secondary" onClick={closeHelpModal}>
              Cancel
            </Button>
            <Button
              disabled={!topic || !details}
              onClick={async () => {
                try {
                  await sendHelpRequest({ topic, details, files });
                  resetForm();
                  toast.success({
                    title: 'Thanks for reaching out!',
                    subtitle:
                      "We'll get back to you as soon as we can (usually within a few hours or less).",
                  });
                  closeHelpModal();
                } catch (error) {
                  logger.error('Failed to send help request', error);
                  toast.error();
                }
              }}
              isLoading={isPending}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
