import React from 'react';
import { Square } from 'react-feather';

import { Button } from '@/components/Button/Button';
import Modal from '@/components/Shared/Modal';

interface StopRecordingModalProps {
  show: boolean;
  isLoading?: boolean;
  subtitle: string;
  onClose: () => void;
  onAccept: () => void;
}

export const StopRecordingModal: React.FC<StopRecordingModalProps> = ({
  show,
  isLoading,
  onClose,
  onAccept,
  subtitle,
}) => (
  <Modal show={show} onClose={onClose} showCloseButton>
    <div className="min-w-[448px]">
      <Modal.Title>Stop recording this meeting?</Modal.Title>
      <Modal.Subtitle>{subtitle}</Modal.Subtitle>

      <div className="flex justify-end gap-1 mt-6">
        <Button variant="secondary" className="w-fit" onClick={onClose}>
          Continue recording
        </Button>
        <Button
          variant="danger"
          className="w-fit"
          onClick={onAccept}
          isLoading={isLoading}
        >
          <Square size={16} />
          Stop recording
        </Button>
      </div>
    </div>
  </Modal>
);
