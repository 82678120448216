export const NotSalesCallIcon: React.FC = () => (
  <div className="relative w-[18px] h-[18px]">
    <img
      src="/call-status/not-sales-call-score.svg"
      width={18}
      height={18}
      alt="not_sales_call"
      className="absolute"
    />
    <span className="absolute text-[8px] font-bold top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      ?
    </span>
  </div>
);
