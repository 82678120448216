import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateCallShowQualitativeFeedback as updateCallShowQualitativeFeedbackAPI } from '@/api/call/updateCallShowQualitativeFeedback';

export const useUpdateCallShowQualitativeFeedback = (callId: string) => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateCallShowQualitativeFeedback, ...rest } =
    useMutation({
      mutationFn: updateCallShowQualitativeFeedbackAPI(callId),
      onSuccess: () => {
        queryClient.refetchQueries({
          queryKey: ['callQualitativeFeedback', callId],
        });
      },
    });

  return { updateCallShowQualitativeFeedback, ...rest };
};
