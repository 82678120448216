import { API } from '@/api/base';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const endpoints = {
  joinRecorderToEvent: (id: string) =>
    backendURL + `/api/calendar/events/${id}/bot-join`,
};

export const joinRecorderToEvent = async (id: string) => {
  await API(endpoints.joinRecorderToEvent(id), {
    method: 'POST',
  });
};
