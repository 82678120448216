import React from 'react';
import { AlertTriangle, Check } from 'react-feather';

import { Banner } from '@/components/Banner/Banner';
import { Button } from '@/components/Button/Button';
import { useHelpModalStore } from '@/components/HelpModal/useHelpModalStore';
import { useModal } from '@/hooks/useModal';
import { useSubscription } from '@/hooks/useSubscription';

import { useSubscribeModalStore } from '../SubscribeModal/useSubscribeModalStore';

export enum SubscriptionBannerPage {
  Insights = 'Insights',
  Calls = 'Calls',
}

interface SubscriptionBannerProps {
  className?: string;
  page?: SubscriptionBannerPage;
}

export const SubscriptionBanner: React.FC<SubscriptionBannerProps> = ({
  className,
}) => {
  const {
    subscriptionData,
    isPending: isSubscriptionDataLoading,
    showPaymentSuccess,
    setShowPaymentSuccess,
  } = useSubscription();

  const { openSubscribeModal } = useSubscribeModalStore();

  const { isOpen, close } = useModal(true);

  if (!isOpen || isSubscriptionDataLoading) {
    return <></>;
  }

  if (showPaymentSuccess) {
    return (
      <SuccessBanner
        className={className}
        onActionClick={() => {
          setShowPaymentSuccess(false);
          close();
        }}
      />
    );
  }

  if (!subscriptionData) {
    return <></>;
  }

  const { isCanceled } = subscriptionData;

  if (isCanceled) {
    return (
      <CanceledSubscriptionBanner
        className={className}
        onActionClick={openSubscribeModal}
      />
    );
  }

  return <></>;
};

interface CustomBannerProps {
  className?: string;
  onClose?: () => void;
  onActionClick?: () => void;
}

const SuccessBanner: React.FC<CustomBannerProps> = ({
  className,
  onActionClick,
}) => (
  <Banner
    variant="success"
    className={className}
    title="Congratulations!"
    subtitle="You can continue to enjoy full access to SilkChart"
    leftSlot={
      <Banner.IconContainer variant="success">
        <img src="/emoji/tada.png" height="18px" width="18px" />
      </Banner.IconContainer>
    }
    rightSlot={
      <Button onClick={onActionClick}>
        <Check size={16} /> Done
      </Button>
    }
  />
);

const CanceledSubscriptionBanner: React.FC<CustomBannerProps> = ({
  className,
  onActionClick,
}) => {
  const { openHelpModal } = useHelpModalStore();
  return (
    <Banner
      variant="danger"
      className={className}
      title="You do not have a subscription, so you can’t upload or record new calls"
      subtitle={
        <p>
          Please
          <Button variant="link" onClick={onActionClick}>
            Subscribe
          </Button>
          to continue analyzing and recording sales calls or
          <Button variant="link" onClick={openHelpModal}>
            Contact us
          </Button>
          if you have any questions.
        </p>
      }
      leftSlot={
        <Banner.IconContainer variant="danger">
          <AlertTriangle size={18} />
        </Banner.IconContainer>
      }
      rightSlot={<Button onClick={onActionClick}>Get full access</Button>}
    />
  );
};
