import React from 'react';

import cn from '@/utils/cn';

export interface SummaryBlockEmptyStateProps {
  title?: string;
  description: string;
}

export const SummaryBlockEmptyState: React.FC<SummaryBlockEmptyStateProps> = ({
  title,
  description,
}) => (
  <div>
    <div className={cn('flex flex-col gap-1', title && 'mt-4')}>
      <span className="font-inter font-bold text-sm text-dark">{title}</span>
      <span className="font-inter font-normal text-sm tracking-[-0.12px] text-main-gray-dark">
        {description}
      </span>
    </div>
  </div>
);
