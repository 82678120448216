import { SVGProps } from 'react';

export const IAStartIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_8933_58870)">
      <path
        d="M11.6468 5.33848C9.22242 4.83023 7.32746 2.93527 6.8193 0.51082C6.75687 0.213203 6.49437 0 6.1902 0C5.88602 0 5.62352 0.213203 5.56113 0.510859C5.05293 2.93527 3.15797 4.8302 0.733555 5.33836C0.435898 5.40074 0.222656 5.66328 0.222656 5.96742C0.222656 6.27156 0.435859 6.5341 0.733555 6.59648C3.15793 7.10473 5.05281 8.99965 5.56102 11.4241C5.6234 11.7218 5.8859 11.935 6.19008 11.935C6.49422 11.935 6.75676 11.7218 6.81914 11.4241C7.32738 8.99969 9.22238 7.10477 11.6468 6.59664C11.9445 6.53426 12.1577 6.27176 12.1577 5.96758C12.1577 5.6634 11.9445 5.40086 11.6468 5.33848ZM6.19012 9.34894C5.46105 7.88551 4.27211 6.69652 2.80871 5.96746C4.27215 5.2384 5.46113 4.04945 6.1902 2.58602C6.91926 4.04949 8.1082 5.23844 9.5716 5.96754C8.10816 6.6966 6.91918 7.88551 6.19012 9.34894Z"
        fill="#5C6A82"
      />
      <path
        d="M10.5838 15.9573C9.44156 15.7179 8.54875 14.8251 8.30926 13.6828C8.24688 13.3851 7.98437 13.1719 7.6802 13.1719C7.37605 13.1719 7.11352 13.385 7.05113 13.6827C6.81164 14.825 5.91879 15.7179 4.77652 15.9573C4.47887 16.0197 4.26562 16.2822 4.26562 16.5864C4.26562 16.8905 4.47883 17.153 4.77652 17.2154C5.91875 17.4549 6.8116 18.3477 7.05105 19.49C7.11344 19.7877 7.37594 20.0009 7.68012 20.0009C7.98426 20.0009 8.2468 19.7878 8.30918 19.49C8.54867 18.3477 9.44152 17.4549 10.5838 17.2155C10.8815 17.1531 11.0947 16.8905 11.0947 16.5864C11.0947 16.2823 10.8815 16.0198 10.5838 15.9573ZM7.68016 17.7354C7.37516 17.282 6.98453 16.8915 6.53121 16.5864C6.98461 16.2814 7.37516 15.8908 7.68016 15.4375C7.9852 15.8909 8.37578 16.2815 8.8291 16.5865C8.37578 16.8914 7.98516 17.282 7.68016 17.7354Z"
        fill="#5C6A82"
      />
      <path
        d="M19.2674 10.0072C17.7234 9.68352 16.5167 8.47672 16.193 6.93277C16.1307 6.63512 15.8682 6.42188 15.564 6.42188C15.2598 6.42188 14.9973 6.63504 14.9349 6.93273C14.6112 8.47672 13.4045 9.68348 11.8605 10.0071C11.5629 10.0695 11.3496 10.332 11.3496 10.6362C11.3496 10.9403 11.5628 11.2028 11.8605 11.2652C13.4045 11.5889 14.6112 12.7957 14.9348 14.3396C14.9972 14.6373 15.2597 14.8505 15.5639 14.8505C15.868 14.8505 16.1306 14.6374 16.193 14.3396C16.5167 12.7957 17.7234 11.5889 19.2674 11.2653C19.565 11.2029 19.7783 10.9404 19.7783 10.6363C19.7783 10.3321 19.5651 10.0696 19.2674 10.0072ZM15.564 12.4631C15.1165 11.7123 14.4879 11.0837 13.7371 10.6362C14.4879 10.1888 15.1165 9.56016 15.564 8.80934C16.0115 9.56016 16.6401 10.1888 17.3909 10.6363C16.6401 11.0837 16.0114 11.7124 15.564 12.4631Z"
        fill="#5C6A82"
      />
    </g>
    <defs>
      <clipPath id="clip0_8933_58870">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
