import React, { useEffect, useState } from 'react';

import { CalendarEventResponse } from '@/api/calendar/get-calendar-events';
import { useGetCalendarEvents } from '@/components/Calendar/hooks/useGetCalendarEvents';
import { AddSilkchartAssistant } from '@/components/Recording/AddSilkchartAssistant';
import { MeetingItem } from '@/components/Recording/MeetingItem';
import { NewMeetingRecordingItem } from '@/components/Recording/NewMeetingRecordingItem';
import { SyncingCalendarLoader } from '@/components/Recording/SyncingCalendarLoader';
import { useRecordingSettings } from '@/hooks/useRecordingSettings';

interface UpcomingEventsProps {
  onClose: () => void;
}

export const UpcomingEvents: React.FC<UpcomingEventsProps> = ({ onClose }) => {
  const [calendarEvents, setCalendarEvents] = useState<CalendarEventResponse[]>(
    [],
  );
  const [addCalendarEventView, setAddCalendarEventView] =
    useState<boolean>(false);

  const { eventsData, isLoading: calendarEventsLoading } =
    useGetCalendarEvents();
  const { recordingSettingsData, isLoading: recordingSettingsLoading } =
    useRecordingSettings();

  const isLoading = calendarEventsLoading || recordingSettingsLoading;

  useEffect(() => {
    if (!eventsData) return;

    setCalendarEvents(eventsData);
  }, [eventsData]);

  if (isLoading) {
    return <SyncingCalendarLoader />;
  }

  if (addCalendarEventView) {
    return <AddSilkchartAssistant onClose={onClose} />;
  }

  return (
    <div className="flex flex-col w-[640px] py-6">
      <span className="text-dark text-base font-bold pb-6 px-6">
        Choose a meeting to record
      </span>
      <div className="w-full bg-main-gray h-[1px]"></div>
      <div className="pt-6 w-full px-6">
        <>
          {calendarEvents.map((calendarEvent) => (
            <MeetingItem
              calendarEvent={calendarEvent}
              key={calendarEvent.id}
              onClose={onClose}
            />
          ))}
          <NewMeetingRecordingItem
            setAddCalendarEventView={setAddCalendarEventView}
          />
        </>
      </div>
    </div>
  );
};
