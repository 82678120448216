import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import logger from './utils/logger';

const API_ENDPOINTS: RegExp[] = [
  /http:\/\/localhost:8080\/api/, // Local: http://localhost:8080/api
  /https:\/\/api.qa.silkchart.com\/api/, // QA: https://api.qa.silkchart.com/api
  /https:\/\/api.silkchart.com\/api/, // Production: https://api.silkchart.com/api
];

/**
 * Initialize Sentry
 * This function will initialize Sentry for the application
 */
export function InitializeSentry() {
  const sampleRate = Number(process.env.REACT_APP_SENTRY_SAMPLE_RATE) || 0.1;
  const envName = process.env.REACT_APP_SENTRY_ENVIRONMENT || 'local';

  const sentryConfig: Sentry.BrowserOptions = {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: API_ENDPOINTS,
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    environment: envName,

    // Session Replay
    replaysSessionSampleRate: sampleRate,
    replaysOnErrorSampleRate: 1.0,

    //Tracing
    tracesSampleRate: sampleRate,
    tracePropagationTargets: API_ENDPOINTS,
  };

  logger.debug(sentryConfig, 'Sentry Configuration');

  Sentry.init(sentryConfig);
}
