import { useQuery } from '@tanstack/react-query';

import {
  CallQuestionResponse,
  getCallQuestions as getCallQuestionsAPI,
} from '@/api/call/get-call-questions';

export const useGetCallQuestions = (callId: string, isPublic: boolean) => {
  const { data: callQuestions, ...rest } = useQuery<CallQuestionResponse[]>({
    queryFn: getCallQuestionsAPI(callId, isPublic),
    queryKey: ['callQuestions', callId],
  });

  return { callQuestions, ...rest };
};
