import { Spinner } from 'flowbite-react';
import React from 'react';

import cn from '@/utils/cn';

export interface SyncingCalendarLoaderProps {
  isModalView?: boolean;
}

export const SyncingCalendarLoader: React.FC<SyncingCalendarLoaderProps> = ({
  isModalView = true,
}) => (
  <div
    className={cn(
      'flex flex-col items-center justify-center gap-4 py-6',
      isModalView && 'px-[197.5px]',
    )}
  >
    <Spinner className="fill-accent-blue h-16 w-16" />
    <div className="flex flex-col items-center justify-center gap-2">
      <span className="text-dark text-xl font-bold">
        Syncing your calendar...
      </span>
      <span className="text-main-gray-dark text-sm font-medium tracking-[-0.14px]">
        This will only take a few seconds, please wait
      </span>
    </div>
  </div>
);
