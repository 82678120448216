import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { Calendar } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';

import { eventTracker } from '@/amplitude/eventTracker';
import { DemoCallPlaybookPercentage } from '@/components/CallDetailsPage/DemoCall/DemoCallMockData';
import { MILLIS_PER_SEC } from '@/constants';
import { CallStatus } from '@/enums/callStatus.enum';
import { useCallDetailsSlice } from '@/pages/CallDetailsPage/slice';
import { selectIsCollapsed } from '@/pages/CallsTabPage/slice/selectors';
import { useHomeSlice } from '@/pages/HomePage/slice';
import { selectUpdatingCallId } from '@/pages/HomePage/slice/selectors';
import {
  formatDurationHourMinute,
  formatWeekdayDayMonth,
} from '@/utils/formatDate';
import { DisplayRecordingCallDuration } from '@/widgets/RecordWidget/components/DisplayRecordingCallDuration';
import { useBotRecordingStore } from '@/widgets/RecordWidget/useBotRecordingStore';
import { useCallRecordingStore } from '@/widgets/RecordWidget/useCallRecordingStore';

import { ChevronDownIcon } from '../Shared/icons/ChevronDownIcon';
import { UsersIcon } from '../Shared/icons/Users';
import { getScoreTextColor, ScoreCircle } from '../Shared/ScoreCircle';

const Separator = () => <img alt="" src="/dot.svg" width="4px" height="4px" />;

const SelectNoOfSpeakers = ({ call }) => {
  const dispatch = useDispatch();
  const { actions: homeActions } = useHomeSlice();
  const { actions: callDetailsActions } = useCallDetailsSlice();
  const updatingCallId = useSelector(selectUpdatingCallId);

  const disabled = call?.status !== 'done' || call.isDemoCall || updatingCallId;

  const options = [
    { value: 2, label: '2 speakers' },
    { value: 3, label: '3 speakers' },
    { value: 4, label: '4 speakers' },
    { value: 5, label: '5 speakers' },
    { value: 6, label: '6 speakers' },
  ];

  const handleChangeNoOfSpeakers = noOfSpeakers => {
    if (call.noOfSpeakers === noOfSpeakers) return;

    eventTracker.callDetails.numberOfSpeakersChange({
      callId: call.id,
      numberOfSpeakers: noOfSpeakers,
    });

    dispatch(
      homeActions.updateCall({
        id: call.id,
        body: {
          noOfSpeakers,
        },
      }),
    );
    dispatch(callDetailsActions.fetchCall({ id: call.id }));
  };

  const buttonClasses = open =>
    open
      ? 'group flex items-center gap-0.5 text-accent-dark-blue'
      : 'group flex items-center gap-0.5 hover:text-accent-dark-blue';
  const usersIconClasses = open =>
    open
      ? 'stroke-accent-dark-blue'
      : 'stroke-main-gray-dark group-hover:stroke-accent-dark-blue';
  const chevronDownIconClasses = open =>
    `${usersIconClasses(open)} ${open ? 'rotate-180' : ''}`;
  const optionClasses = option => {
    const base = 'flex whitespace-nowrap items-center gap-2 pr-4 h-5';
    const textColor =
      call.noOfSpeakers === option.value ? 'text-accent-blue' : 'text-dark';
    return `${base} ${textColor}`;
  };

  if (disabled) {
    return (
      <div className="inline-block text-left">
        <div className="flex items-center gap-0.5">
          <UsersIcon className="stroke-main-gray-dark" width={12} height={12} />
          <p>{call.noOfSpeakers} speakers</p>
        </div>
      </div>
    );
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <Menu.Button>
            <div className={buttonClasses(open)}>
              <UsersIcon
                className={usersIconClasses(open)}
                width={12}
                height={12}
              />
              <p>{call.noOfSpeakers} speakers</p>
              <ChevronDownIcon
                className={chevronDownIconClasses(open)}
                width={12}
                height={12}
              />
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            {/* overflow-hidden keeps border radius on item hover */}
            <Menu.Items className="absolute z-10 bg-white shadow-notification flex-col gap-1 items-end rounded-[10px] overflow-hidden">
              {options.map(option => (
                <Menu.Item key={option.value}>
                  <button
                    className="flex flex-col gap-2.5 items-start p-4 hover:bg-main-gray text-dark text-sm font-medium"
                    onClick={() => handleChangeNoOfSpeakers(option.value)}
                  >
                    <div className={optionClasses(option)}>
                      {option.value} speakers
                    </div>
                  </button>
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

const CompletedPlaybookItemsPercentage = ({ call }) => {
  let percent;

  if (call.isDemoCall) {
    percent = DemoCallPlaybookPercentage;
  } else {
    const activePlaybookStats = call.playbook.playbooksStats.find(
      el => el.playbookId === call.lastReanalyzedPlaybookId,
    );

    if (!activePlaybookStats) return null;

    percent =
      activePlaybookStats.salesPlaybookTotal > 0
        ? activePlaybookStats.salesPlaybookPercentTotal
        : activePlaybookStats.prospectPlaybookPercentTotal;
  }

  const scoreTextColor = getScoreTextColor(percent);

  return (
    <>
      <ScoreCircle percent={percent} size={12} className="mr-0.5" />
      <span className={scoreTextColor}>
        {percent}% of sales playbook executed
      </span>
    </>
  );
};

export const CallHeader = ({ call, openEditCallDetailsModal }) => {
  const isMenuCollapsed = useSelector(selectIsCollapsed);

  const isRecording = useCallRecordingStore(store => store.isRecording);
  const isBotRecording = useBotRecordingStore(store => store.isBotRecording);
  const botRecordingCallId = useBotRecordingStore(store => store.callId);

  const isCallUploading = useCallRecordingStore(store => store.isCallUploading);

  const shouldSpaceFromMenu = isMenuCollapsed && call?.status !== 'done';
  const isRecordingCall = call?.status === CallStatus.Recording;

  if (!call) return <></>;

  const activePlaybookStats = call.playbook?.playbooksStats?.find(
    el => el.playbookId === call.lastReanalyzedPlaybookId,
  );

  const isSalesCall =
    call.isSalesCall ||
    call.isSalesCall === null ||
    call.isSalesCall === undefined;

  const hasPlaybookData = isSalesCall && call.lastReanalyzedPlaybookId !== null;

  return (
    <div
      className={`flex gap-2 ${shouldSpaceFromMenu ? 'ml-call-list-collapsed' : ''}`}
    >
      <div className="flex flex-col gap-2">
        <div className="text-dark text-xl font-bold">{call.name}</div>
        <div className="flex items-center gap-2.5 text-main-gray-dark text-xs font-normal">
          {call.status === 'done' &&
            ((activePlaybookStats?.processingItems <= 0 && isSalesCall) ||
            call.isDemoCall ? (
              <>
                <div className="flex items-center gap-0.5">
                  <CompletedPlaybookItemsPercentage call={call} />
                </div>
                <Separator />
              </>
            ) : (
              hasPlaybookData && (
                <div className="flex flex-row items-center justify-center gap-1">
                  <ClipLoader color={'#2C6CF6'} size={12} />
                  <span className="text-accent-blue text-xs font-normal">
                    Updating playbook...
                  </span>
                </div>
              )
            ))}
          {call.date && openEditCallDetailsModal && (
            <>
              <div
                className="flex items-center gap-0.5 cursor-pointer hover:text-accent-dark-blue"
                onClick={() => {
                  openEditCallDetailsModal();
                  eventTracker.callDetails.editCallDateClick({
                    callId: call.id,
                  });
                }}
              >
                <Calendar size={12} />
                {formatWeekdayDayMonth(call.date)}
              </div>
              <Separator />
            </>
          )}
          {((call.duration && call.duration > 0) ||
            isRecordingCall ||
            isBotRecording) && (
            <div className="flex flex-row items-center gap-0.5">
              <img src="/clock.svg" width={12} height={12} alt="clock-icon" />
              <span>
                {isRecordingCall ||
                (isBotRecording && botRecordingCallId === call.id) ? (
                  <DisplayRecordingCallDuration
                    format="formatDurationHMS"
                    recordingType={isBotRecording ? 'bot' : 'user'}
                  />
                ) : (
                  formatDurationHourMinute(MILLIS_PER_SEC * call.duration)
                )}
              </span>
            </div>
          )}

          {call.status === CallStatus.BotJoining && (
            <span className="text-accent-yellow text-xs tracking-[-0.12px]">
              Joining meeting...
            </span>
          )}

          {call.noOfSpeakers && (
            <>
              <Separator />
              <div className="flex items-center gap-0.5">
                <SelectNoOfSpeakers call={call} />
              </div>
            </>
          )}

          {(isRecordingCall ||
            (isBotRecording && botRecordingCallId === call.id)) && (
            <>
              <Separator />
              {(isRecording ||
                (isBotRecording && botRecordingCallId === call.id)) && (
                <p className="text-accent-red">Recording...</p>
              )}
              {isCallUploading && (
                <p className="text-accent-yellow">Saving...</p>
              )}
            </>
          )}

          {call.status === 'done' &&
            !call.includeInAnalysis &&
            !call.isDemoCall && (
              <>
                <Separator />
                <div className="flex items-center gap-0.5 text-accent-red">
                  <img
                    src="/minus-circle.svg"
                    width={12}
                    height={12}
                    alt="minus-circle"
                  />
                  Excluded from insights analysis
                </div>
              </>
            )}
        </div>
      </div>
    </div>
  );
};
