// AssemblyAI uses letter. AWS uses number

export const mapSpeakerLetterToNumber = (letter) => {
  const speakerMapping = {
    A: 1,
    B: 2,
    C: 3,
    D: 4,
    E: 5,
    F: 6,
    G: 7,
    H: 8,
    SA: 1,
    SB: 2,
    SC: 3,
    SD: 4,
    SE: 5,
    SF: 6,
    SG: 7,
    SH: 8,
    0: 1,
    1: 2,
    2: 3,
    3: 4,
    4: 5,
    5: 6,
    6: 7,
    7: 8,
    8: 9,
  };
  return speakerMapping[letter];
};
