import { create } from 'zustand';

export interface QuickstartModalStore {
  isQuickstartModalOpen: boolean;
  openQuickstartModal: () => void;
  closeQuickstartModal: () => void;
  isQuickstartVisible: boolean;
  showQuickstart: () => void;
  hideQuickstart: () => void;
}

export const useQuickstartModalStore = create<QuickstartModalStore>((set) => ({
  isQuickstartModalOpen: false,
  openQuickstartModal: () => set(() => ({ isQuickstartModalOpen: true })),
  closeQuickstartModal: () => set(() => ({ isQuickstartModalOpen: false })),
  isQuickstartVisible: false,
  showQuickstart: () => set(() => ({ isQuickstartVisible: true })),
  hideQuickstart: () => set(() => ({ isQuickstartVisible: false })),
}));
