import React from 'react';
import { Check, ChevronDown } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select, { components } from 'react-select';

import { usePlaybookSettingsSlice } from '../../pages/PlaybookSettingsPage/slice';

const createOptions = availablePlaybooks =>
  availablePlaybooks.map(el => ({
    label: el.prettyName,
    value: el.id,
  }));

const ActivePlaybookSelectComponent = ({
  availablePlaybooks,
  selectedPlaybook,
}) => {
  const dispatch = useDispatch();
  const { actions } = usePlaybookSettingsSlice();

  // Warn: options may be either real playbook or actions like "+ Click me to create custom playbook"
  const options = createOptions(availablePlaybooks);
  const navigate = useNavigate();

  const handleChange = selectedOption => {
    if (selectedOption.value === 'CREATE_CUSTOM_PLAYBOOK') {
      navigate('/playbook/custom/new');
      return;
    }

    const newOption = availablePlaybooks.find(
      el => el.id === selectedOption.value,
    );

    dispatch(actions.fetchPlaybookById({ playbookId: newOption.id }));
  };

  return (
    <div className="w-full flex flex-col justify-center p-4 bg-accent-blue/10 rounded-[10px]">
      <span className="text-dark text-[10px] font-bold mb-0.5">
        Selected Sales Playbook:
      </span>
      <Select
        name="options"
        options={options}
        value={options.find(option => option.value === selectedPlaybook.id)}
        onChange={handleChange}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null,
          Option,
          SingleValue,
          DropdownIndicator,
        }}
        styles={{
          control: (provided, state) => ({
            display: 'flex',
            flexDirection: 'row',
            border: '1px solid var(--main-gray-main, #ECECEC)',
            borderRadius: 10,
            boxShadow: 'none',
            padding: '10px 10px 10px 12px',
            backgroundColor: state.menuIsOpen ? '#F6F6F6' : '#FFF',
            cursor: 'pointer',
          }),
          indicatorsContainer: styles => ({
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            marginLeft: 8,
          }),
          dropdownIndicator: (provided, state) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#1C1C1C',
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              fontWeight: 500,
              backgroundColor: '#fff',
              color: '#1C1C1C',
              fontSize: 14,
              padding: 8,
              ':active': {
                backgroundColor: '#fff',
              },
            };
          },
          menu: (styles, state) => ({
            ...styles,
            width: '100%',
            minWidth: '171px',
            boxShadow:
              '0px 2px 6px -2px rgba(24, 39, 75, 0.06), 0px 1px 6px -2px rgba(24, 39, 75, 0.10)',
            borderRadius: 10, // bottom left and bottom right radius 10
            padding: '8px 12px 8px 12px',
            gap: 4,
            marginTop: 2,
            right: 0,
            backgroundColor: '#FFF',
          }),
          menuList: styles => ({
            ...styles,
            padding: 0,
          }),
          singleValue: styles => ({
            ...styles,
            color: '#1C1C1C',
            fontSize: 14,
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            margin: 0,
          }),
          valueContainer: styles => ({
            ...styles,
            padding: 0,
            input: { height: 0 },
          }),
          transition: 'none',
        }}
      />
    </div>
  );
};

const Option = props => {
  const buildDataTestId = label => {
    if (label.includes('Create custom template')) {
      return 'playbook-option create-custom-template';
    } else {
      return `playbook-option ${label}`;
    }
  };
  return (
    <components.Option {...props}>
      <div
        data-testid={buildDataTestId(props.label)}
        className="flex items-center w-full h-full"
      >
        {props.isSelected === true ? (
          <div className="flex flex-row items-center">
            <Check
              className="mr-2"
              size={16}
              stroke={'#1C1C1C'}
              strokeWidth={2}
            />
            <div className="flex-1">
              <div className="text-sm font-medium text-[#1C1C1C]">
                {props.label}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex-1 ml-6">
            <div className="text-sm">{props.label}</div>
          </div>
        )}
      </div>
    </components.Option>
  );
};

const SingleValue = props => {
  return (
    <components.SingleValue {...props}>
      <div
        data-testid="playbook-dropdown"
        className="flex items-center w-full h-full"
      >
        <div className="flex-1 ml-1">
          <div className="text-sm text-[#1C1C1C] font-semibold">
            {props.data.label}
          </div>
        </div>
      </div>
    </components.SingleValue>
  );
};

const DropdownIndicator = props => {
  return (
    <div className="h-3 w-3">
      <ChevronDown
        size={12}
        stroke={'#1C1C1C'}
        strokeWidth={2}
        className={`transition ease-in-out ${props.selectProps.menuIsOpen ? 'rotate-180' : ''}`}
      />
    </div>
  );
};

export default ActivePlaybookSelectComponent;
