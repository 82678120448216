import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import fixWebmDuration from 'webm-duration-fix';
import { create } from 'zustand';

import { createNewCall } from '@/api/call/createNewCall';
import { RECORD_TIMESLICE } from '@/constants';
import { CallStatus } from '@/enums/callStatus.enum';
import { getChromeVersionMajor, isChrome } from '@/utils/browserDetection';
import { getCookie, setCookie } from '@/utils/cookie';

import { createMediaRecorder } from './helpers/createMediaRecorder';

const IS_VALID_BROWSER = isChrome() && getChromeVersionMajor() >= 80;

export interface RecordingCall {
  id: string;
  name: string;
  status: CallStatus.Recording | CallStatus.BotRecording;
  date: Date;
}

interface CallRecordingState {
  isRecordingRequested: boolean;
  isRecording: boolean;
  isCallUploading: boolean;
  recordingStartedAt: Date | null;
  recordingChannel: BroadcastChannel;
  elapsedSeconds: number;
  recordingCall: RecordingCall | null;

  isHeadphonesWarningAccepted: boolean;
  microphoneAccessError: boolean;
  unsupportedBrowserError: boolean;
  showStopRecordingModal: boolean;

  _mediaRecorder: MediaRecorder | null;
  _audioChunks: Blob[];
  _elapsedSecondsTimer: NodeJS.Timeout | null;
}

interface CallRecordingActions {
  requestRecordingStart: (recordingCall?: RecordingCall) => void;
  requestRecordingStop: () => void;
  stopRecording: () => void;
  saveCall: () => Promise<void>;

  acceptHeadphonesWarning: (params: { showAgain: boolean }) => void;
  closeStopRecordingModal: () => void;

  reset: () => void;
}

const getInitialState = (): CallRecordingState => ({
  isRecordingRequested: false,
  isRecording: false,
  isCallUploading: false,
  recordingCall: null,
  recordingStartedAt: null,
  recordingChannel: new BroadcastChannel('recording'),
  elapsedSeconds: 0,

  isHeadphonesWarningAccepted: !!getCookie('SKIP_WARN_HEADPHONE'),
  microphoneAccessError: false,
  unsupportedBrowserError: false,
  showStopRecordingModal: false,

  _mediaRecorder: null,
  _audioChunks: [],
  _elapsedSecondsTimer: null,
});

const getExtension = (mediaRecorder: MediaRecorder) =>
  //@ts-ignore
  mediaRecorder.mimeType.match(/audio\/(?<extension>\w+)/)?.groups?.extension;

export const useCallRecordingStore = create<
  CallRecordingState & CallRecordingActions
>((set, get) => ({
  ...getInitialState(),
  acceptHeadphonesWarning: ({ showAgain }: { showAgain: boolean }) => {
    if (!showAgain) {
      setCookie('SKIP_WARN_HEADPHONE', 'true', 6 * 30 * 24 * 60 * 60 * 1000);
    }
    set(() => ({ isHeadphonesWarningAccepted: true }));
  },

  requestRecordingStart: async (recordingCall?: RecordingCall) => {
    const { isRecording, recordingChannel, isHeadphonesWarningAccepted } =
      get();

    set(() => ({ isRecordingRequested: true }));

    if (isRecording) {
      // eslint-disable-next-line no-console
      console.error('Recording already is in progress');
      return;
    }

    if (!IS_VALID_BROWSER) {
      set(() => ({ unsupportedBrowserError: true }));
      return;
    }

    if (!isHeadphonesWarningAccepted) {
      // eslint-disable-next-line no-console
      console.error('Headphones warning should be accepted');
      return;
    }

    let _mediaRecorder: MediaRecorder;

    try {
      _mediaRecorder = await createMediaRecorder();
      set(() => ({ microphoneAccessError: false }));
    } catch {
      set(() => ({ microphoneAccessError: true }));
      return;
    }

    _mediaRecorder.start(RECORD_TIMESLICE);
    _mediaRecorder.ondataavailable = e => {
      const chunk = e.data;

      set(state => ({ _audioChunks: [...state._audioChunks, chunk] }));

      recordingChannel.postMessage({
        chunk,
        mimeType: _mediaRecorder.mimeType,
      });
    };

    set(() => ({
      _mediaRecorder,
      recordingStartedAt: new Date(),
      isRecording: true,
      recordingCall: {
        id: recordingCall?.id || uuidv4(),
        name:
          recordingCall?.name || `Call ${dayjs().format('MMM D [at] HH:mm')}`,
        status: recordingCall?.status || CallStatus.Recording,
        date: recordingCall?.date || new Date(),
      },
      _elapsedSecondsTimer: setInterval(() => {
        set(state => ({
          elapsedSeconds: state.recordingStartedAt
            ? (new Date().getTime() - state.recordingStartedAt.getTime()) / 1000
            : 0,
        }));
      }, 1000),
    }));
  },

  requestRecordingStop: () => {
    set(() => ({ showStopRecordingModal: true }));
  },

  closeStopRecordingModal: () => {
    set(() => ({ showStopRecordingModal: false }));
  },

  stopRecording: () => {
    const { _mediaRecorder } = get();

    if (!_mediaRecorder) {
      return;
    }

    _mediaRecorder.stop();
    _mediaRecorder.stream.getTracks().forEach(track => track.stop());

    set(() => ({
      isRecording: false,
    }));
  },

  saveCall: async () => {
    const { _mediaRecorder, _audioChunks, recordingStartedAt, recordingCall } =
      get();

    if (!_mediaRecorder || !recordingCall) {
      return;
    }

    const blob = await fixWebmDuration(
      new Blob(_audioChunks, { type: _mediaRecorder.mimeType }),
    );

    const filename = recordingCall.name;
    const file = new File([blob], filename);

    set(() => ({ isCallUploading: true }));
    try {
      return createNewCall({
        file,
        fileExtension: getExtension(_mediaRecorder),
        duration: recordingStartedAt
          ? (new Date().getTime() - recordingStartedAt?.getTime()) / 1000
          : 0,
        callId: recordingCall?.id,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Call uploading error:', error);
    }
    set(() => ({ isCallUploading: false }));
  },

  reset: () => {
    const { _elapsedSecondsTimer } = get();
    if (_elapsedSecondsTimer) {
      clearInterval(_elapsedSecondsTimer);
    }
    set(getInitialState());
  },
}));
