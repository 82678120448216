import React, { useState } from 'react';

import { CallNextStepResponse } from '@/api/call/get-call-next-steps';
import { useUpdateCallNextStep } from '@/components/CallDetailsPage/Summary/hooks/useUpdateCallNextStep';
import { Checkbox } from '@/components/Shared/Checkbox';
import { useNavigateToTranscript } from '@/hooks/useNavigateToTranscript';
import cn from '@/utils/cn';
import { formatTimestamp } from '@/utils/formatDate';

export interface CallNextStepItemProps {
  existingResponsibleSpeakers: Record<string, string>;
  nextStep: CallNextStepResponse;
  readOnly?: boolean;
  baseUrl?: string;
}

export const CallNextStepItem: React.FC<CallNextStepItemProps> = ({
  existingResponsibleSpeakers,
  nextStep,
  readOnly = false,
  baseUrl = 'calls',
}) => {
  const [isChecked, setIsChecked] = useState(nextStep.isDone);

  const { updateCallNextStep } = useUpdateCallNextStep(nextStep.callId);
  const { navigateToTranscript } = useNavigateToTranscript(baseUrl);

  const handleCheckboxChange = async () => {
    if (readOnly) {
      return;
    }

    const isDone = !isChecked;

    setIsChecked(isDone);
    await updateCallNextStep({
      callId: nextStep.callId,
      nextStepId: nextStep.id,
      isDone,
    });
  };

  const handleTimestampClick = () => {
    navigateToTranscript({
      callId: nextStep.callId,
      timestamp: Number(nextStep.timestamp),
    });
  };

  const renderTextWithDynamicColors = (nextStep: CallNextStepResponse) => {
    const names = Object.keys(existingResponsibleSpeakers);
    const regex = new RegExp(`\\b(${names.join('|')})\\b`, 'g');

    return nextStep.text.split(regex).map((part, index) => {
      if (names.includes(part)) {
        return (
          <span
            key={index}
            className="font-semibold"
            style={{ color: existingResponsibleSpeakers[part] }}
          >
            {part}
          </span>
        );
      }
      return part;
    });
  };

  return (
    <div className="w-full bg-white rounded-[10px] border border-main-gray py-2.5 px-4 flex flex-row items-start">
      {
        <div className="mr-2 mt-0.5">
          <Checkbox
            size={16}
            checked={isChecked}
            onChange={handleCheckboxChange}
            disabled={readOnly}
          />
        </div>
      }
      <div className="flex flex-row items-center justify-between w-full">
        <span
          className={cn(
            'text-dark text-sm tracking-[-0.14px] font-normal text-left',
            { 'line-through text-main-gray-dark': isChecked && !readOnly },
          )}
        >
          {renderTextWithDynamicColors(nextStep)}
        </span>
        {nextStep.timestamp && (
          <span
            className="text-main-gray-dark text-[10px] tracking-[-0.1px] ml-3 cursor-pointer font-normal hover:text-accent-blue"
            onClick={handleTimestampClick}
          >
            {formatTimestamp(nextStep.timestamp)}
          </span>
        )}
      </div>
    </div>
  );
};
