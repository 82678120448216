import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import { getMetricsInsights } from '@/api/insights/getMetricsInsights';
import { ChartLineLegend } from '@/components/InsightsAcrossCallsPage/chart/ChartLineLegend';
import { ChartRangeLegend } from '@/components/InsightsAcrossCallsPage/chart/ChartRangeLegend';
import { EmptyBackgroundChart } from '@/components/InsightsAcrossCallsPage/chart/EmptyBackgroundChart';
import {
  InsightsAcrossCallChartRawDataItem,
  InsightsAcrossCallsChart,
} from '@/components/InsightsAcrossCallsPage/chart/InsightsAcrossCallsChart';
import { TemplateLoadingValueBlocks } from '@/components/InsightsAcrossCallsPage/consts/TemplateLoadingValueBlocks';
import {
  FillerWord,
  FillerWordsTooltip,
} from '@/components/InsightsAcrossCallsPage/FillerWordsTooltip';
import {
  InsightsAcrossCallsStore,
  useInsightsAcrossCallsStore,
} from '@/components/InsightsAcrossCallsPage/hooks/useInsightsAcrossCallsStore';
import { InsightsStatistics } from '@/components/InsightsAcrossCallsPage/InsightsStatistics';
import {
  MetricChartValueItem,
  MetricEvaluationDataMapper,
  MetricItemData,
} from '@/components/InsightsAcrossCallsPage/mappers/MetricEvaluationDataMapper';
import { PersonalInsightsChartViewType } from '@/components/InsightsAcrossCallsPage/PersonalInsightsChartTooltip';
import {
  DetailedFillerWordsMetrics,
  PossibleMetrics,
} from '@/components/Shared/helpers/BasicMetricsEvaluationHelper';

export const MetricsEvaluation = () => {
  const {
    selectedDateOption,
    rawChartData,
    isChartLoading,
    isFirstLoading,
    setIsFirstLoading,
    setIsPageDataLoading,
    selectedTimeRangeCallsCount,
  }: InsightsAcrossCallsStore = useInsightsAcrossCallsStore();

  const [metricsRawData, setMetricsRawData] = useState<MetricItemData[]>([]);
  const [chartValueItems, setChartValueItems] = useState<
    MetricChartValueItem[]
  >([]);
  const [selectedChartItem, setSelectedChartItem] =
    useState<MetricChartValueItem>();
  const [fillerWords, setFillerWords] = useState<FillerWord[]>([]);
  const [chartData, setChartData] = useState<
    InsightsAcrossCallChartRawDataItem[]
  >([]);

  const metricsResult = useQuery<MetricItemData[]>({
    queryFn: getMetricsInsights(selectedDateOption?.value),
    queryKey: ['metricsInsights', selectedDateOption],
  });

  const metricsResultData: MetricItemData[] | undefined = metricsResult.data;

  useEffect(() => {
    setIsPageDataLoading(metricsResult.isLoading);
  }, [metricsResult.isLoading]);

  useEffect(() => {
    if (!metricsResultData) {
      return;
    }

    setMetricsRawData(metricsResultData);

    const newChartValueItems: MetricChartValueItem[] =
      MetricEvaluationDataMapper.map(metricsResultData);

    setChartValueItems(newChartValueItems);
    if (!selectedChartItem) {
      const selectedItem = newChartValueItems.find(el => el.selected);
      setSelectedChartItem(selectedItem);
    }
    setIsFirstLoading(false);
  }, [metricsResultData]);

  useEffect(() => {
    if (!rawChartData?.length || !selectedChartItem) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newChartData: any[] = rawChartData.map(el => ({
      ...el,
      value: Number(el[selectedChartItem.key]),
      items: el.items.map(call => ({
        value: Number(call[selectedChartItem.key]),
        ...call,
      })),
    }));

    setChartData(newChartData);
  }, [rawChartData, selectedChartItem]);

  useEffect(() => {
    if (selectedChartItem?.key === PossibleMetrics.FillerWordsCount) {
      const possibleFillerWordsMetrics = Object.values(
        DetailedFillerWordsMetrics,
      );
      const fillerWordsMetrics = metricsRawData.filter(el =>
        possibleFillerWordsMetrics.includes(el.key),
      );

      const fillerWordsData: FillerWord[] = fillerWordsMetrics
        .map(el => ({
          key: el.key,
          count: Number(el.value),
        }))
        .filter(el => el.count > 0) //Filter out the ones with 0 count
        .sort((a, b) => b.count - a.count); //Sort them descending by count

      setFillerWords(fillerWordsData);
    } else {
      setFillerWords([]);
    }
  }, [selectedChartItem]);

  const isDataLoading = isChartLoading || metricsResult.isLoading;

  return (
    <>
      <InsightsStatistics
        isFirstLoading={isFirstLoading}
        beforeDataLoadItems={TemplateLoadingValueBlocks.Metrics}
        isLoading={metricsResult.isLoading}
        chartValueItems={chartValueItems}
        setSelectedChartItem={setSelectedChartItem}
      />
      <div className="w-full border-[1px] border-[#ECECEC] rounded-bl-[20px] rounded-br-[20px] p-6">
        <div className="flex flex-col mb-8">
          <div
            className="flex flex-row items-center"
            style={{
              justifyContent:
                selectedChartItem?.key === PossibleMetrics.FillerWordsCount
                  ? 'space-between'
                  : 'start',
            }}
          >
            <div className="flex flex-row">
              <ChartLineLegend
                isLoading={isDataLoading}
                explanation={selectedChartItem?.chartLegend || ''}
              />
              <div className="ml-6">
                <ChartRangeLegend
                  isLoading={isDataLoading}
                  explanation={'Recommended range'}
                />
              </div>
            </div>
            {selectedChartItem?.key === PossibleMetrics.FillerWordsCount && (
              <div>
                <FillerWordsTooltip fillerWords={fillerWords} />
              </div>
            )}
          </div>
        </div>
        {isDataLoading || !selectedChartItem || !selectedTimeRangeCallsCount ? (
          <EmptyBackgroundChart isLoading={isDataLoading} />
        ) : (
          <InsightsAcrossCallsChart
            chartData={chartData}
            type={PersonalInsightsChartViewType.Metrics}
            selectedMetric={selectedChartItem?.key}
          />
        )}
      </div>
    </>
  );
};
