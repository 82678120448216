import { useQuery } from '@tanstack/react-query';

import {
  getCallShareLink as getCallShareLinkAPI,
  GetShareLinkResponse,
} from '@/api/call/get-call-share-link';

export const useGetCallShareLink = (callId: string) => {
  const { data: callShareLink, ...rest } = useQuery<
    GetShareLinkResponse | undefined
  >({
    queryFn: getCallShareLinkAPI(callId),
    queryKey: ['callShareLink', callId],
    enabled: !!callId,
  });

  return { callShareLink, ...rest };
};
