import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CheckEmailForm } from '../../components/AuthPage/CheckEmailForm';
import { SignUpForm } from '../../components/SignUpPage/SignUpForm';
import { selectEmailNotUsed } from './slice/selectors';

const SignUpPage = () => {
  const navigate = useNavigate();
  const emailNotUsed = useSelector(selectEmailNotUsed);

  useEffect(() => {
    if (emailNotUsed === false) {
      navigate('/auth/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailNotUsed]);

  return (
    <div className="flex flex-1 bg-[#FFF]">
      <div className="flex-1 flex justify-center items-center">
        <div className="md:w-auto w-full px-5">
          <div className="flex flex-col mb-6">
            <h1 className="text-dark font-semibold text-[28px]">
              Sign up for SilkChart
            </h1>
            <p className="text-main-gray-dark font-medium text-base">
              Try SilkChart for free. No credit card required.
            </p>
          </div>
          {emailNotUsed === true ? (
            <SignUpForm />
          ) : (
            <CheckEmailForm formType={'signUp'} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
