export enum UploadCallWorkerMessageTypes {
  UPLOAD_IN_PROGRESS = 'UPLOAD_IN_PROGRESS',
  UPLOAD_COMPLETE = 'UPLOAD_COMPLETE',
  ERROR = 'ERROR',
}

export interface UploadCallWorkerOutputBaseMessage {
  type: UploadCallWorkerMessageTypes;
}

export interface UploadCallWorkerInProgressMessage
  extends UploadCallWorkerOutputBaseMessage {
  type: UploadCallWorkerMessageTypes.UPLOAD_IN_PROGRESS;
  uploadProgress: number;
}

export interface UploadCallWorkerCompleteMessage
  extends UploadCallWorkerOutputBaseMessage {
  type: UploadCallWorkerMessageTypes.UPLOAD_COMPLETE;
  createdCallId: string;
}

export interface UploadCallWorkerErrorMessage
  extends UploadCallWorkerOutputBaseMessage {
  type: UploadCallWorkerMessageTypes.ERROR;
  error: unknown;
}

export type UploadCallWorkerMessage =
  | UploadCallWorkerInProgressMessage
  | UploadCallWorkerCompleteMessage
  | UploadCallWorkerErrorMessage;
