import React from 'react';
import { TrendingUp } from 'react-feather';

import { DemoMetrics } from '@/components/CallDetailsPage/DemoCall/DemoCallMockData';
import { FeedbackCard } from '@/components/CallDetailsPage/Feedback/FeedbackCard';
import { SummaryBlockEmptyState } from '@/components/CallDetailsPage/Summary/SummaryBlockEmptyState';
import { PossibleMetrics } from '@/components/Shared/helpers/BasicMetricsEvaluationHelper';
import { Call, CallMetric } from '@/interfaces/call.interface';

import { CollapseCallSection } from '../../CollapseCallSection/CollapseCallSection';
import FillerWordsList from '../../FillerWordsList';
import PerformanceFeedback from './PerformanceFeedback';

export type FeedbackMetricItem = Pick<CallMetric, 'value' | 'type'>;

interface FeedbackProps {
  call: Call;
}

// Call details component for call feedback (numeric / deterministic data cards)
export const Feedback: React.FC<FeedbackProps> = ({ call }) => {
  const callMetrics: FeedbackMetricItem[] = call.isDemoCall
    ? DemoMetrics
    : call.metrics;

  const isTranscriptEmpty = !callMetrics?.length;

  const mainSpeakerWordsPerMinute = callMetrics?.find(
    analytic => analytic.type === PossibleMetrics.MainSpeakerWordsPerMinute,
  )?.value;
  const mainSpeakerTalkPercentage = callMetrics?.find(
    analytic => analytic.type === PossibleMetrics.MainSpeakerTalkPercentage,
  )?.value;
  const longestProspectMonologueDuration = callMetrics?.find(
    analytic =>
      analytic.type === PossibleMetrics.LongestMonologueDurationSeconds,
  )?.value;
  const questionsAsked = callMetrics?.find(
    analytic => analytic.type === PossibleMetrics.QuestionsAsked,
  )?.value;
  const fillerWordsTotal = callMetrics
    ?.filter(analytics => analytics?.type.startsWith('fillerWords'))
    .map(analytic => analytic.value)
    .reduce((a, b) => Number(a) + Number(b), 0)
    .toString();

  const fillerWordsDetails = callMetrics
    ?.filter(analytics => analytics?.type.startsWith('fillerWords'))
    .map(analytic => {
      const keyName = analytic.type
        .split('fillerWords')
        .join('')
        .split('Count')[0];
      return {
        key: keyName,
        value: analytic.value,
      };
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .reduce((acc: any, curr) => {
      acc[curr.key] = curr.value;
      return acc;
    }, {});

  return (
    <div className="flex flex-col gap-4 mt-4">
      <CollapseCallSection
        title="Performance Metrics"
        hideDefaultChevron
        triggerDisabled
        openByDefault
        icon={<TrendingUp width={20} height={20} color="#5C6A82" />}
      >
        {isTranscriptEmpty ? (
          <SummaryBlockEmptyState
            title="Feedback is unavailable"
            description="Please check your recording and try to upload it again."
          />
        ) : (
          <div className="flex flex-col gap-1 mt-1">
            <FeedbackCard
              attribute={PossibleMetrics.MainSpeakerWordsPerMinute}
              value={mainSpeakerWordsPerMinute}
            />
            <FeedbackCard
              attribute={PossibleMetrics.MainSpeakerTalkPercentage}
              value={mainSpeakerTalkPercentage}
            />
            <FeedbackCard
              attribute={PossibleMetrics.LongestMonologueDurationSeconds}
              value={longestProspectMonologueDuration}
            />
            <FeedbackCard
              attribute={PossibleMetrics.QuestionsAsked}
              value={questionsAsked}
            />
            <FeedbackCard
              attribute={PossibleMetrics.FillerWordsCount}
              value={fillerWordsTotal}
              infoHover={true}
              hoverComponent={
                <FillerWordsList fillerWords={fillerWordsDetails} />
              }
            />
          </div>
        )}
      </CollapseCallSection>
      <PerformanceFeedback call={call} />
    </div>
  );
};
