import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { ChevronDown, ChevronUp, Search } from 'react-feather';

import { DemoTranscriptSentences } from '@/components/CallDetailsPage/DemoCall/DemoCallMockData';
import { formatTimestamp } from '@/utils/formatDate';
import { mapSpeakerLetterToNumber } from '@/utils/speakerMapping';

import { Call } from '../../../interfaces/call.interface';
import cn from '../../../utils/cn';
import { Button } from '../../Button/Button';
import { mapSpeakerNumberToColor } from '../../HomePage/mapSpeakerNumberToColor';
import { Input } from '../../Input/Input';
import { SelectedTranscriptionBlock } from '../Assets/CallTranscript';

const speakerNameMap: Record<string, string> = {
  0: 'Anna Hoffman',
  1: 'John Smith',
};

interface DemoSearchResult {
  sentenceIndex: number;
  matchIndex: number;
  length: number;
  isSelected?: boolean;
}

interface DemoTextPart {
  text: string;
  isMatch: boolean;
  isSelected?: boolean;
}

export const DemoCallTranscript = ({
  call,
  selectedBlock,
}: {
  call: Call;
  selectedBlock?: SelectedTranscriptionBlock;
}) => {
  const transcriptText = DemoTranscriptSentences.map(sentence => {
    const nextSentence = DemoTranscriptSentences.find(
      s => s.start > sentence.start,
    );
    const end = nextSentence?.start;

    let isSelected = false;

    if (selectedBlock) {
      if (end) {
        if (
          selectedBlock.timestamp >= sentence.start &&
          selectedBlock.timestamp < end
        ) {
          isSelected = true;
        }
      } else {
        if (selectedBlock.timestamp >= sentence.start) {
          isSelected = true;
        }
      }
    }

    return {
      active: false,
      content: sentence.text.split(' ').map(word => ({
        content: word,
        done: false,
        selected: false,
      })),
      done: false,
      selected: isSelected,
      start: sentence.start,
      textContent: `${sentence.speaker}: ${sentence.text}`,
    };
  });

  const [searchTerms, setSearchTerms] = useState('');
  const [searchResults, setSearchResults] = useState<DemoSearchResult[]>([]);
  const [selectedSearchResultIndex, setSelectedSearchResultIndex] = useState(0);
  const listRef: MutableRefObject<any> = useRef(null);

  useEffect(() => {
    if (!searchTerms) {
      setSearchResults([]);
      setSelectedSearchResultIndex(0);
      return;
    }

    const regex = new RegExp(searchTerms, 'gi');
    const results: DemoSearchResult[] = [];

    // Find matches in each sentence
    transcriptText.forEach((text, sentenceIndex) => {
      let match;
      while ((match = regex.exec(text.textContent.split(':')[1])) !== null) {
        results.push({
          sentenceIndex,
          matchIndex: match.index,
          length: match[0].length,
        });
      }
    });

    setSearchResults(results);
    // Scroll to the selected search result
    if (results.length > 0) {
      const selectedResult = results[selectedSearchResultIndex];
      const element = listRef.current?.querySelector(
        `[data-sentence-index="${selectedResult.sentenceIndex}"]`,
      );
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [searchTerms]);

  const splitTextIntoPartsWithMatches = (
    text: string,
    sentenceResults: DemoSearchResult[],
  ): DemoTextPart[] => {
    if (sentenceResults.length === 0) {
      return [{ text, isMatch: false }];
    }

    sentenceResults.sort((a, b) => a.matchIndex - b.matchIndex);

    const parts = [];
    let lastIndex = 0;

    sentenceResults.forEach(result => {
      if (result.matchIndex > lastIndex) {
        parts.push({
          text: text.substring(lastIndex, result.matchIndex),
          isMatch: false,
        });
      }
      parts.push({
        text: text.substring(
          result.matchIndex,
          result.matchIndex + result.length,
        ),
        isMatch: true,
        isSelected: result.isSelected,
      });
      lastIndex = result.matchIndex + result.length;
    });

    if (lastIndex < text.length) {
      parts.push({
        text: text.substring(lastIndex),
        isMatch: false,
      });
    }

    return parts;
  };

  useEffect(() => {
    if (searchResults.length === 0) return;

    const selectedResult = searchResults[selectedSearchResultIndex];
    const element = listRef.current?.querySelector(
      `[data-sentence-index="${selectedResult.sentenceIndex}"]`,
    );
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [selectedSearchResultIndex]);

  useEffect(() => {
    if (!selectedBlock) return;

    const timer = setTimeout(() => {
      listRef.current?.querySelector('#selected')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }, 250);

    return () => {
      clearTimeout(timer);
    };
  }, [selectedBlock]);

  return (
    <div className="flex flex-col">
      <div className="flex border-b p-[16px] gap-[8px] items-center sticky top-[-48px] bg-white rounded-tr-2xl rounded-tl-2xl">
        <Input
          placeholder="Search transcript"
          leftSlot={<Search size={20} />}
          value={searchTerms}
          onChange={e => {
            setSearchTerms(e.target.value);
          }}
          onKeyDown={
            searchTerms
              ? e => {
                  if (e.key === 'Enter') {
                    setSelectedSearchResultIndex(prev =>
                      searchResults.length > 0
                        ? (prev + 1) % searchResults.length
                        : 0,
                    );
                  }
                }
              : undefined
          }
          className="flex-1 transition-all"
        />

        {searchTerms && (
          <>
            <div className="flex flex-row gap-3 flex-shrink-0 items-center w-42 justify-center">
              {searchResults.length > 0 ? (
                <>
                  <span className="text-sm">
                    {selectedSearchResultIndex + 1} of {searchResults.length}
                  </span>
                  <div
                    className="inline-flex rounded-md shadow-sm"
                    role="group"
                  >
                    <Button
                      variant="secondary"
                      className="!rounded-tr-none !rounded-br-none !p-2"
                      onClick={() =>
                        setSelectedSearchResultIndex(prev => {
                          if (prev === 0) return searchResults.length - 1;
                          return prev - 1;
                        })
                      }
                    >
                      <ChevronUp size={16} />
                    </Button>
                    <Button
                      className="!rounded-tl-none !rounded-bl-none !p-2"
                      variant="secondary"
                      onClick={() =>
                        setSelectedSearchResultIndex(
                          prev => (prev + 1) % searchResults.length,
                        )
                      }
                    >
                      <ChevronDown size={16} />
                    </Button>
                  </div>
                </>
              ) : (
                <span className="text-[#2C6CF6] text-sm">
                  {searchResults.length} results found
                </span>
              )}
            </div>
            <div>
              <Button onClick={() => setSearchTerms('')} variant="secondary">
                Cancel
              </Button>
            </div>
          </>
        )}
      </div>

      <div className="p-6">
        <div
          ref={listRef}
          id="transcript-content"
          className="max-h-[40rem] overflow-y-auto"
        >
          {transcriptText.map((text: any, sentenceIndex: number) => {
            const textSpeaker = text?.textContent.split(':')[0];
            const textContent = text?.textContent.split(':')[1];
            const speakerName = speakerNameMap[textSpeaker];

            // Get the selected search result
            const selectedResult = searchResults[selectedSearchResultIndex];

            // Get matches for this sentence
            const sentenceResults = searchResults
              .filter(result => result.sentenceIndex === sentenceIndex)
              .map(result => ({
                ...result,
                isSelected:
                  selectedResult &&
                  selectedResult.sentenceIndex === result.sentenceIndex &&
                  selectedResult.matchIndex === result.matchIndex,
              }));

            // Split the text into parts with matches highlighted
            const parts = splitTextIntoPartsWithMatches(
              textContent,
              sentenceResults,
            );

            return (
              <div
                key={sentenceIndex}
                data-sentence-index={sentenceIndex}
                className="mb-4"
                id={text.selected ? 'selected' : ''}
              >
                <div>
                  <div className="flex items-center gap-1 mb-1">
                    <div
                      className="rounded-full w-2 h-2"
                      style={{
                        backgroundColor:
                          textSpeaker === call.mainSpeaker
                            ? '#1774F8'
                            : mapSpeakerNumberToColor(
                                mapSpeakerLetterToNumber(textSpeaker),
                              ),
                      }}
                    ></div>
                    <p className="text-sm font-semibold">
                      {speakerName
                        ? speakerName
                        : `Speaker ${mapSpeakerLetterToNumber(textSpeaker)}`}

                      {call.mainSpeaker === textSpeaker && ' (Seller)'}
                    </p>
                    <span className="ml-1.5 text-[#717D96] text-sm">
                      {formatTimestamp(text.start)}
                    </span>
                  </div>
                  <p
                    className={cn({
                      'bg-[#1774F80F] rounded-[10px] py-1 px-2': text.selected,
                    })}
                  >
                    {parts.map((part, index) =>
                      part.isMatch ? (
                        <em
                          key={index}
                          className={cn('not-italic', {
                            'bg-[#F89B11]/60 font-bold': part.isSelected,
                            'bg-[#F89B11]/20': !part.isSelected,
                          })}
                        >
                          {part.text}
                        </em>
                      ) : (
                        <span key={index}>{part.text}</span>
                      ),
                    )}
                  </p>
                </div>
              </div>
            );
          })}
          <p
            className={
              "text-[#5c6a82] text-sm font-normal font-['Inter'] mx-auto text-center"
            }
          >
            The transcript above is just a dummy data example 😉
          </p>
        </div>
      </div>
    </div>
  );
};
