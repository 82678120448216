import React from 'react';
import { Meh, Trash } from 'react-feather';

import { Button } from '@/components/Button/Button';
import { ItsSalesCallButton } from '@/components/CallDetailsPage/ItsSalesCallButton';

interface NotSalesCallFailedStateParams {
  openDeleteCallModal: () => void;
  callId: string;
}

export const NotSalesCallFailedState: React.FC<
  NotSalesCallFailedStateParams
> = ({ openDeleteCallModal, callId }) => (
  <div className="flex justify-center flex-col items-center gap-4">
    <div className="w-[72px] h-[72px] rounded-full bg-accent-yellow/10 flex justify-center items-center">
      <Meh size={40} className="text-accent-yellow" />
    </div>
    <div className="flex flex-col items-center gap-2">
      <p className="text-xl font-bold text-dark">
        Sounds like this is not a sales call
      </p>
      <p className="text-sm font-medium text-main-gray-dark">
        We currently only provide feedback for sales calls
      </p>
    </div>
    <div className="flex flex-row items-center">
      <ItsSalesCallButton callId={callId} />
      <Button
        variant="danger"
        onClick={openDeleteCallModal}
        className="w-fit ml-1"
      >
        <Trash size={16} />
        Delete Call
      </Button>
    </div>
  </div>
);
