import { Switch } from '@headlessui/react';
import React, { useState } from 'react';

import { CalendarEventResponse } from '@/api/calendar/get-calendar-events';
import { useToggleCalendarEventRecording } from '@/components/Recording/hooks/useToggleCalendarEventRecording';

export interface ToggleMeetRecordingProps {
  calendarEvent: CalendarEventResponse;
}

export const ToggleMeetRecording: React.FC<ToggleMeetRecordingProps> = ({
  calendarEvent,
}) => {
  const { toggleCalendarEventRecording } = useToggleCalendarEventRecording();
  const [localIsRecordingEnabled, setLocalIsRecordingEnabled] = useState(
    calendarEvent.isRecordingEnabled,
  );

  const onChange = async () => {
    setLocalIsRecordingEnabled(!localIsRecordingEnabled);
    await toggleCalendarEventRecording(calendarEvent.id);
  };

  return (
    <Switch.Group>
      <div className="flex items-center justify-between">
        <Switch
          checked={localIsRecordingEnabled}
          onChange={onChange}
          className={`${
            localIsRecordingEnabled ? 'bg-[#2C6CF6]' : 'bg-[#5C6A82]'
          } relative inline-flex h-4 w-7 items-center rounded-full transition-colors focus:outline-none focus:ring-0 `}
        >
          <span
            className={`${
              localIsRecordingEnabled
                ? 'translate-x-[14px]'
                : 'translate-x-[2px]'
            } inline-block h-3 w-3 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
};
