import React from 'react';

import {
  Tooltip,
  TooltipContent,
  TooltipProps,
  TooltipTrigger,
} from '../Tooltip/Tooltip';

export interface WithHoverTooltipProps extends TooltipProps {
  children: React.ReactNode;
  tooltip: React.ReactNode;
  tooltipContainerClassName?: string;
}

export const WithHoverTooltip: React.FC<WithHoverTooltipProps> = ({
  children,
  tooltip,
  tooltipContainerClassName,
  ...tooltipProps
}) => {
  // don't render the hover tooltip if undefined is provided
  if (!tooltip) {
    return children;
  }
  return (
    <Tooltip {...tooltipProps}>
      <TooltipTrigger>{children}</TooltipTrigger>
      <TooltipContent className={tooltipContainerClassName}>
        {tooltip}
      </TooltipContent>
    </Tooltip>
  );
};
