import { InfoTooltip } from '@/components/HoverTooltip/InfoTooltip';
import React from 'react';

interface PlaybookItemTitleParams {
  item: any;
}

export const PlaybookItemTitle: React.FC<PlaybookItemTitleParams> = ({
  item,
}: PlaybookItemTitleParams) => {
  const [before, after] = item.highlight
    ? item.description.split(item.highlight, 2)
    : [item.description, null];

  return (
    <div className="text-sm font-normal inline-block align-middle">
      <span className="inline">
        {before}
        <span className="font-bold">{item.highlight}</span>
        {after}
      </span>
      {item.tooltip && (
        <span
          className="pointer-events-auto ml-1 align-middle"
          style={{ verticalAlign: 'middle' }}
        >
          <InfoTooltip tooltip={item.tooltip} />
        </span>
      )}
    </div>
  );
};
