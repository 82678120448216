import { Check } from 'react-feather';

import cn from '@/utils/cn';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  size?: number;
  label?: string;
  labelClasses?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  size,
  label,
  labelClasses,
  id,
  ...inputProps
}) => {
  return (
    <label
      htmlFor={id}
      className={
        labelClasses ||
        'flex items-center gap-1 text-main-gray-dark enabled:hover:text-black select-none'
      }
    >
      <div className={`relative w-[${size || 20}px] h-[${size || 20}px]`}>
        <input
          id={id}
          type="checkbox"
          className={cn(
            `
          appearance-none bg-white border-2 border-main-gray-dark
          peer
          absolute
          w-[${size || 20}px] h-[${size || 20}px] rounded-sm
          checked:bg-none checked:bg-accent-blue checked:border-0
          enabled:hover:bg-accent-dark-blue-12-percent
          enabled:hover:checked:bg-accent-dark-blue
          disabled:border-main-gray disabled:checked:bg-main-gray-dark disabled:cursor-not-allowed
          focus:ring-0 focus:ring-transparent
          `,
          )}
          {...inputProps}
        />
        <Check
          size={size || 20}
          className={
            'text-white absolute hidden peer-checked:block pointer-events-none'
          }
        ></Check>
      </div>
      {label}
    </label>
  );
};
