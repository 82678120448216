import { API } from '@/api/base';
import { Response } from '@/api/response';

const backendURL = process.env.REACT_APP_BACKEND_URL as string;

const endpoints = {
  getCallQuestions: (callId: string, isPublic: boolean) =>
    backendURL +
    `/api/calls/${callId}/${isPublic ? 'public/' : ''}top-questions`,
};

export interface CallQuestionResponse {
  id: string;
  answer: string;
  quote: string;
  summary: string;
  callId: string;
  category: string;
  prospectTimestamp: string;
  timestamp: string;
  createdAt: string;
  updatedAt: string;
}

export const getCallQuestions =
  (callId: string, isPublic: boolean) =>
  async (): Promise<CallQuestionResponse[]> => {
    try {
      const response = await API(endpoints.getCallQuestions(callId, isPublic), {
        method: 'GET',
      });

      const result: Response<CallQuestionResponse[]> = await response.json();

      return result.data;
    } catch (err) {
      console.error('fetch call questions', err);
      return [];
    }
  };
