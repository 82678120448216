import { Switch } from '@headlessui/react';

import cn from '@/utils/cn';

export interface ToggleProps {
  checked: boolean;
  onChange?: () => void;
  className?: string;
  checkedClassName?: string;
  uncheckedClassName?: string;
  variant?: 'default' | 'small';
}

export const Toggle: React.FC<ToggleProps> = ({
  checked,
  onChange,
  className,
  checkedClassName,
  uncheckedClassName,
  variant = 'default',
}: ToggleProps) => (
  <Switch.Group>
    <div className={cn('flex items-center justify-between', className)}>
      <Switch
        checked={checked}
        onChange={onChange}
        className={cn(
          'relative inline-flex h-4 w-7 items-center rounded-full transition-colors focus:outline-none focus:ring-0',
          {
            [checkedClassName ?? 'bg-accent-blue']: checked,
            [uncheckedClassName ?? 'bg-main-gray']: !checked,
            'h-3 w-5': variant === 'small',
          },
        )}
      >
        <span
          className={cn(
            'inline-block h-3 w-3 transform rounded-full bg-white transition-transform',
            {
              'translate-x-3.5': checked,
              'translate-x-0.5': !checked,
            },
            {
              'h-2 w-2': variant === 'small',
              'translate-x-2.5': variant === 'small' && checked,
            },
          )}
        />
      </Switch>
    </div>
  </Switch.Group>
);
