import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { eventTracker } from '@/amplitude/eventTracker';
import { CallsList } from '@/components/CallsList/CallsList';

import CallDetailsPage from '../CallDetailsPage';
import { selectSortedCalls } from '../HomePage/slice/selectors';

const CallsTabPage = () => {
  let { id } = useParams();
  const sortedCalls = useSelector(selectSortedCalls);
  const navigate = useNavigate();

  useEffect(() => {
    if (!id && sortedCalls.length > 0) {
      const filteredCalls = sortedCalls.filter(
        (call) => call.status === 'done' || call.status === 'botRecording',
      );
      let activeCall = sortedCalls[0];

      if (filteredCalls.length > 0) {
        activeCall = filteredCalls[0];
      }

      navigate(`/calls/${activeCall.id}`);
    }
  }, [id, sortedCalls]);

  useEffect(() => {
    eventTracker.calls.pageOpen();
  }, []);

  return (
    <div className="flex h-[calc(100vh-69px)]">
      <CallsList />
      <CallDetailsPage />
    </div>
  );
};

export default CallsTabPage;
