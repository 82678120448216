import React from 'react';
import { Calendar } from 'react-feather';

import { MILLIS_PER_SEC } from '../../../constants';
import { Call } from '../../../interfaces/call.interface';
import {
  formatDurationHourMinute,
  formatWeekdayDayMonth,
} from '../../../utils/formatDate';

interface HeaderProps {
  call: Call;
  readOnly?: boolean;
}
import { UsersIcon } from '../../../components/Shared/icons/Users';
import cn from '../../../utils/cn';

const Separator = () => <img alt="" src="/dot.svg" width="4px" height="4px" />;

function Header({ call, readOnly = false }: HeaderProps) {
  return (
    <div className={'flex gap-2'}>
      <div className="flex flex-col gap-2">
        <div className="text-dark text-xl font-bold">{call.name}</div>
        <div className="flex items-center gap-2.5 text-main-gray-dark text-xs font-normal">
          {call.date && (
            <>
              <div
                className={cn('flex items-center gap-0.5', {
                  'hover:text-accent-dark-blue cursor-pointer': !readOnly,
                })}
              >
                <Calendar size={12} />
                {formatWeekdayDayMonth(call.date)}
              </div>
              <Separator />
            </>
          )}
          {call.duration && call.duration > 0 && (
            <div className="flex flex-row items-center gap-0.5">
              <img src="/clock.svg" width={12} height={12} alt="clock-icon" />
              {formatDurationHourMinute(MILLIS_PER_SEC * call.duration)}
            </div>
          )}

          {call.noOfSpeakers && (
            <>
              <Separator />
              <div className="flex items-center gap-0.5">
                <div className="inline-block text-left">
                  <div className="flex items-center gap-0.5">
                    <UsersIcon
                      className="stroke-main-gray-dark"
                      width={12}
                      height={12}
                    />
                    <p>{call.noOfSpeakers} speakers</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export { Header };
