import React, { useEffect, useRef, useState } from 'react';
import { ChevronDown } from 'react-feather';
import ReactPlayer from 'react-player';
import { useSearchParams } from 'react-router-dom';

import { MediaPlayer } from '@/components/CallDetailsPage/Assets/MediaPlayer';
import { Call } from '@/interfaces/call.interface';
import cn from '@/utils/cn';

import { DemoCallTranscript } from '../DemoCall/DemoCallTranscript';
import { CallTranscript, SelectedTranscriptionBlock } from './CallTranscript';

export interface CallAssetsProps {
  call: Call;
  isPublic?: boolean;
}

// Call details page for displaying transcript
export const CallAssets: React.FC<CallAssetsProps> = ({
  call,
  isPublic = false,
}) => {
  const [showTranscript, setShowTranscript] = useState(true);
  const [currentAudioTime, setCurrentAudioTime] = useState(0);
  const [selectedBlock, setSelectedBlock] =
    useState<SelectedTranscriptionBlock>();
  const prevSelectedBlockRef = useRef<SelectedTranscriptionBlock>();

  const [searchParams] = useSearchParams();

  const timestamp: string | null = searchParams.get('timestamp');
  const text: string | null = searchParams.get('text');

  useEffect(() => {
    if (!timestamp) {
      return;
    }
    prevSelectedBlockRef.current = selectedBlock;

    const newSelectedBlock = {
      timestamp: Number(timestamp),
      text,
    };

    if (
      prevSelectedBlockRef.current?.timestamp !== newSelectedBlock?.timestamp &&
      !showTranscript
    ) {
      setShowTranscript(true);
    }

    setSelectedBlock(newSelectedBlock);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, timestamp]);

  const toggleShowTranscript = () => {
    setShowTranscript(!showTranscript);
  };

  return (
    <div>
      {!call.isDemoCall ? (
        <MediaPlayer
          setCurrentAudioTime={setCurrentAudioTime}
          isPublic={isPublic}
        />
      ) : (
        <div
          className={cn(
            'w-full border-[#CBD2E0] rounded-t-2xl overflow-hidden h-[350px] bg-black',
          )}
        >
          <ReactPlayer
            width="100%"
            height="100%"
            className="react-player"
            controls={true}
            url="https://v4assets.s3.us-west-1.amazonaws.com/SilkChartDemoPlaceholderVideo.mp4"
            config={{
              file: {
                attributes: {
                  poster: '/video-bg.png',
                },
              },
            }}
          />
        </div>
      )}

      <div className="bg-white border-1 rounded-b-2xl border">
        <div
          className={cn(
            'flex flex-row items-center justify-center w-full bg-light-gray py-2 cursor-pointer',
            !showTranscript && 'rounded-b-2xl',
          )}
          onClick={toggleShowTranscript}
        >
          <ChevronDown
            size={14}
            stroke={'#1C1C1C'}
            strokeWidth={2}
            className={`transition ease-in-out ${showTranscript ? 'rotate-180' : ''}`}
          />
          <span className="text-black text-xs font-semibold ml-1">
            {showTranscript ? 'Hide transcript' : 'Show transcript'}
          </span>
        </div>
        {showTranscript && (
          <>
            {!call.isDemoCall ? (
              <CallTranscript
                call={call}
                selectedBlock={selectedBlock}
                currentAudioTime={currentAudioTime}
                isPublic={isPublic}
              />
            ) : (
              <DemoCallTranscript
                call={call}
                selectedBlock={selectedBlock}
              ></DemoCallTranscript>
            )}
          </>
        )}
      </div>
    </div>
  );
};
