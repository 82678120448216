import React from 'react';
import { FileText } from 'react-feather';
import { ClipLoader } from 'react-spinners';

import { CallSummaryItem } from '@/components/CallDetailsPage/Summary/CallSummaryItem';
import { useGetCallSummary } from '@/components/CallDetailsPage/Summary/hooks/useGetCallSummary';
import { SummaryBlockEmptyState } from '@/components/CallDetailsPage/Summary/SummaryBlockEmptyState';
import { CollapseCallSection } from '@/components/CollapseCallSection/CollapseCallSection';

export interface CallSummaryProps {
  callId: string;
  isCallPublic?: boolean;
  className?: string;
}

export const CallSummary: React.FC<CallSummaryProps> = ({
  callId,
  isCallPublic = false,
  className = '',
}) => {
  const { callSummary, isLoading } = useGetCallSummary(callId, isCallPublic);

  return (
    <div className={`flex flex-col mt-4 ${className}`}>
      <CollapseCallSection
        chevronStyles="text-main-gray-dark"
        titleStyles="gap-1.5"
        itemsContainerStyles="pt-3"
        openByDefault={true}
        title="Call summary"
        icon={<FileText size={20} className="text-main-gray-dark" />}
      >
        {isLoading && (
          <div className="flex justify-center">
            <ClipLoader color="rgb(37 99 235)" />
          </div>
        )}
        {callSummary && callSummary.length > 0 ? (
          <div
            key="prospects-top-questions"
            className="h-full flex flex-col gap-1"
          >
            {callSummary.map(summary => (
              <CallSummaryItem
                key={summary.id}
                summary={summary}
                baseUrl={isCallPublic ? 'share-call' : 'calls'}
              />
            ))}
          </div>
        ) : (
          callSummary &&
          callSummary.length === 0 && (
            <SummaryBlockEmptyState description="We couldn’t summarize this call – please check audio source" />
          )
        )}
      </CollapseCallSection>
    </div>
  );
};
