import { useQuery } from '@tanstack/react-query';

import {
  CallQualitativeFeedbackItemResponse,
  getCallQualitativeFeedback as getCallQualitativeFeedbackAPI,
} from '@/api/call/getCallQualitativeFeedback';

export const useGetCallQualitativeFeedback = (callId: string) => {
  const { data: callQualitativeFeedback, ...rest } = useQuery<
    CallQualitativeFeedbackItemResponse[]
  >({
    queryFn: getCallQualitativeFeedbackAPI(callId),
    queryKey: ['callQualitativeFeedback', callId],
  });

  return { callQualitativeFeedback, ...rest };
};
