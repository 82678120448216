import { API } from '@/api/base';
import { toast } from '@/components/Toast/toaster';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const endpoints = {
  quickstartDismiss: backendURL + '/api/users/quickstart-dismiss',
};

export const quickstartDismiss = async () => {
  const response = await API(endpoints.quickstartDismiss, {
    method: 'POST',
  });

  if (!response.ok) {
    const data = await response.json();

    toast.error({
      title: 'Failed to dismiss the quickstart',
      subtitle: data.message || 'Unknown error occurred',
    });
  }
};
