import { InsightsAcrossCallsTimeRangeOptionsValue } from '@/components/InsightsAcrossCallsPage/InsightsAcrossCallsTimeRangeDropdown';

const backendURL = process.env.REACT_APP_BACKEND_URL;

export type InsightsAcrossCallsType = [
  string,
  {
    percentage: number;
    topQuestions: {
      answer: string;
      callId: string;
      callName: string;
      category: string;
      createdAt: string;
      deletedAt: string;
      id: string;
      prospectTimestamp: string;
      quote: string;
      summary: string;
      timestamp: string;
      updatedAt: string;
    }[];
  },
];

export const getInsightsCallsQuestionsInfo =
  (timeRange?: InsightsAcrossCallsTimeRangeOptionsValue) =>
  async (): Promise<InsightsAcrossCallsType[] | undefined> => {
    if (!timeRange) {
      return;
    }

    const query = `timeRange=${timeRange}`;

    const response = await fetch(
      `${backendURL}/api/insights/top-questions?${query}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      },
    );

    const result = await response.json();

    return Object.entries(result.data) as unknown as InsightsAcrossCallsType[];
  };
