import React, { useState } from 'react';
import { X } from 'react-feather';

import { FeedbackLimitType } from '@/components/CallDetailsPage/FeedbackLimitModal/useFeedbackLimitModalStore';
import { FreemiumRestrictionLimitMessage } from '@/components/Shared/Freemium/FreemiumRestrictionLimitMessage';
import { useSubscribeModalStore } from '@/components/subscription/SubscribeModal/useSubscribeModalStore';
import { UploadSteps } from '@/components/UploadCallModal/UploadSteps';
import { usePreventLeave } from '@/hooks/usePreventLeave';
import { useSubscription } from '@/hooks/useSubscription';
import cn from '@/utils/cn';

import { Modal } from '../Shared/Modal';

export enum CallUploadError {
  InvalidFileType = 'InvalidFileType',
  InvalidFileSize = 'InvalidFileSize',
  LongDuration = 'LongDuration',
  ShortDuration = 'ShortDuration',
  UnknownError = 'UnknownError',
  CallsLimit = 'CallsLimit',
  // TODO: add error for audio with silence
}

export enum CallUploadStep {
  FileSelect = 'FILE_SELECT',
  Loading = 'LOADING',
  Error = 'ERROR',
}

interface UploadCallModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface UploadState {
  progress: number;
  isPending: boolean;
  createdCallId: string | null;
  error: unknown | null;
}

export const UploadCallModal: React.FC<UploadCallModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [uploadError, setUploadError] = useState<CallUploadError | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const [uploadState, setUploadState] = useState<UploadState>({
    progress: 0,
    isPending: false,
    createdCallId: null,
    error: null,
  });

  // Show "leave site" dialog
  usePreventLeave(uploadState.isPending);

  const {
    canCreateNewCalls,
    availableUploadCallsAmount,
    callsUploadLimit,
    isFree,
    isPending: subscriptionLoading,
    openStripeCustomerPortal,
  } = useSubscription();

  const { openSubscribeModal } = useSubscribeModalStore();

  if (subscriptionLoading || !canCreateNewCalls) {
    return <></>;
  }

  const canCloseModal = !uploadState.isPending;

  const closeModal = () => {
    if (!canCloseModal) {
      return;
    }

    onClose();
    reset();
  };

  const reset = () => {
    setUploadError(null);
  };

  const showSubscriptionLimitation =
    availableUploadCallsAmount !== null &&
    availableUploadCallsAmount !== undefined &&
    callsUploadLimit;

  const noUploadCallsLeft = availableUploadCallsAmount === 0;

  const handleUpgradeClick = () => {
    closeModal();
    if (isFree) {
      openSubscribeModal();
      return;
    } else {
      openStripeCustomerPortal();
    }
  };

  return (
    <Modal
      show={isOpen}
      onClose={closeModal}
      containerClassName={showSubscriptionLimitation ? 'pb-0 px-0' : ''}
    >
      <div
        data-testid="upload-call-modal"
        className={cn('w-[800px]', showSubscriptionLimitation && 'pb-6 px-6')}
      >
        {canCloseModal && (
          <X
            size={24}
            color="#5C6A82"
            className="absolute right-3 top-3 cursor-pointer"
            onClick={closeModal}
          />
        )}
        <div>
          <p className="block text-base text-dark font-bold mb-1">
            {uploadState.isPending
              ? `Uploading Call - ${uploadState.progress}%`
              : 'Upload Call'}
          </p>
          <p className="text-xs text-main-gray-dark">
            Upload call recordings to analyze performance
          </p>
        </div>
        <div
          className={cn(
            'h-[320px] mt-2 border border-dashed border-rounded rounded-lg border-gray-900/25 transition-colors',
            isDragging && 'bg-[#2C6CF614] border-accent-blue',
          )}
        >
          <UploadSteps
            closeModal={closeModal}
            setIsDragging={setIsDragging}
            reset={reset}
            setUploadState={setUploadState}
            uploadState={uploadState}
            uploadError={uploadError}
            setUploadError={setUploadError}
            noUploadCallsLeft={noUploadCallsLeft}
          />
        </div>
      </div>
      {showSubscriptionLimitation && (
        <FreemiumRestrictionLimitMessage
          handleUpgradeClick={handleUpgradeClick}
          itemsLimit={callsUploadLimit}
          availableLimit={availableUploadCallsAmount}
          itemsUsed={callsUploadLimit - availableUploadCallsAmount}
          limitType={FeedbackLimitType.UPLOADS}
        />
      )}
    </Modal>
  );
};
