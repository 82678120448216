import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import { getSubscription } from '@/api/subscription/getSubscription';
import { selectUser } from '@/pages/HomePage/slice/selectors';
import { deleteCookie, getCookie, ONE_MONTH, setCookie } from '@/utils/cookie';

export const SHOW_PAYMENT_SUCCESS_COOKIE = 'SHOW_PAYMENT_SUCCESS';
export const HIDE_DEFAULT_TRIAL_BANNER_COOKIE =
  'HIDE_DEFAULT_TRIAL_BANNER_COOKIE';

export const useSubscription = () => {
  const { data: subscriptionData, ...rest } = useQuery({
    queryFn: getSubscription,
    queryKey: ['subscription'],
    refetchOnWindowFocus: true,
  });

  const user = useSelector(selectUser);

  const subscriptionHoursLeft = dayjs(subscriptionData?.currentPeriodEnd).diff(
    new Date(),
    'hours',
  );

  const subscriptionEndsToday = subscriptionHoursLeft <= 24;
  const subscriptionDaysLeft = Math.ceil(subscriptionHoursLeft / 24);

  const canCreateNewCalls =
    !subscriptionData?.isExpired && !subscriptionData?.isCanceled;

  const getStripeCustomerPortalLink = () => {
    if (!user.email) {
      return null;
    }

    const encodedEmail = encodeURIComponent(user.email);
    return `${process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL}?prefilled_email=${encodedEmail}`;
  };

  const openStripeCustomerPortal = () => {
    const customerPortalLink = getStripeCustomerPortalLink();
    if (!customerPortalLink) {
      return;
    }

    window.open(customerPortalLink, '_blank');
  };

  return {
    subscriptionData,
    subscriptionEndsToday,

    currentPeriodEnd: subscriptionData?.currentPeriodEnd,
    currentPeriodStart: subscriptionData?.currentPeriodStart,

    callsUploadLimit: subscriptionData?.callsUploadLimit,
    callsRecordingLimit: subscriptionData?.callsRecordingLimit,
    callsPlaybookAnalysisLimit: subscriptionData?.callsPlaybookAnalysisLimit,
    callsSalesCoachAnalysisLimit:
      subscriptionData?.callsSalesCoachAnalysisLimit,

    availableUploadCallsAmount: subscriptionData?.availableUploadCallsAmount,
    availableRecordingCallsAmount:
      subscriptionData?.availableRecordingCallsAmount,
    availablePlaybookAnalysisCallsAmount:
      subscriptionData?.availablePlaybookAnalysisCallsAmount,
    availableSalesCoachAnalysisCallsAmount:
      subscriptionData?.availableSalesCoachAnalysisCallsAmount,

    isFree: subscriptionData?.isFree,

    canCreateNewCalls,
    subscriptionDaysLeft,
    showPaymentSuccess: getCookie(SHOW_PAYMENT_SUCCESS_COOKIE) === 'true',
    setShowPaymentSuccess: (value: boolean) => {
      if (value) {
        setCookie(SHOW_PAYMENT_SUCCESS_COOKIE, 'true', ONE_MONTH);
      } else {
        deleteCookie(SHOW_PAYMENT_SUCCESS_COOKIE);
      }
    },
    showDefaultTrialBanner:
      getCookie(HIDE_DEFAULT_TRIAL_BANNER_COOKIE) !== 'true',
    setShowDefaultTrialBanner: (value: boolean) => {
      if (value) {
        deleteCookie(HIDE_DEFAULT_TRIAL_BANNER_COOKIE);
      } else {
        setCookie(HIDE_DEFAULT_TRIAL_BANNER_COOKIE, 'true', ONE_MONTH);
      }
    },
    openStripeCustomerPortal,
    ...rest,
  };
};
