import { useQuery } from '@tanstack/react-query';

import {
  CalendarEventResponse,
  getCalendarEvents,
} from '@/api/calendar/get-calendar-events';

export const useGetCalendarEvents = (days?: number) => {
  const { data: eventsData, ...rest } = useQuery<CalendarEventResponse[]>({
    queryFn: getCalendarEvents({ days }),
    queryKey: ['calendarEvents', days],
  });

  return { eventsData, ...rest };
};
