import { API } from '@/api/base';
import { Response } from '@/api/response';
import logger from '@/utils/logger';

const backendURL = process.env.REACT_APP_BACKEND_URL as string;

const endpoints = {
  getCallAudio: (callId: string, isPublic: boolean) =>
    `${backendURL}/api/calls/${callId}/${isPublic ? 'public/' : ''}audio`,
};

export const getCallAudio =
  (callId: string, isPublic: boolean) =>
  async (): Promise<string | undefined> => {
    try {
      const response = await API(endpoints.getCallAudio(callId, isPublic), {
        method: 'GET',
      });

      const result: Response<string> = await response.json();

      return result.data;
    } catch (err) {
      logger.error('Error fetching call audio', err);
      return undefined;
    }
  };
