import { create } from 'zustand';

interface HelpModalStore {
  initialSelectedOption?: string;
  isHelpModalOpen: boolean;
  openHelpModal: () => void;
  openHelpModalWithOptionSelected: (initialSelectedOption?: string) => void;
  closeHelpModal: () => void;
}

export const useHelpModalStore = create<HelpModalStore>(set => ({
  isHelpModalOpen: false,
  openHelpModal: () => set(() => ({ isHelpModalOpen: true })),
  openHelpModalWithOptionSelected: initialSelectedOption =>
    set(() => ({ isHelpModalOpen: true, initialSelectedOption })),
  closeHelpModal: () => set(() => ({ isHelpModalOpen: false })),
}));
