import React from 'react';
import { Check, ChevronUp, X } from 'react-feather';
import { ClipLoader } from 'react-spinners';

import { Button } from '@/components/Button/Button';
import { KeyDriverTag } from '@/components/CallDetailsPage/Playbook/KeyDriverTag';
import { PlaybookItemTitle } from '@/components/CallDetailsPage/Playbook/PlaybookItemTitle';
import { CollapsibleItem } from '@/components/CollapsibleItem/CollapsibleItem';
import { WithHoverTooltip } from '@/components/HoverTooltip/WithHoverTooltip';
import { OnboardingTooltip } from '@/components/Onboarding/OnboardingTooltip/OnboardingTooltip';
import { KEYDRIVER_ITEMS } from '@/constants';
import { useNavigateToTranscript } from '@/hooks/useNavigateToTranscript';
import cn from '@/utils/cn';
import { formatTimestamp } from '@/utils/formatDate';

import { SeeHowFeedbackCard } from '../SeeHow/SeeHowFeedbackCard';
import { QuestionMarkIcon } from './icons/QuestionMark.icon';

export enum PlaybookItemAnswer {
  False = 'false',
  True = 'true',
  Unsure = 'unsure',
}

interface PlaybookCardProps {
  answer: PlaybookItemAnswer | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
  callId: string;
  isTooltipCard?: boolean;
}

export const PlaybookCard: React.FC<PlaybookCardProps> = ({
  item,
  answer,
  callId,
  isTooltipCard = false,
}: PlaybookCardProps) => {
  const seeHowResult = item.seeHowResult;
  const { navigateToTranscript } = useNavigateToTranscript();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTimestampClick = (e: any) => {
    e.stopPropagation();
    navigateToTranscript({
      callId,
      timestamp: item.seeHowResult?.userPhraseTimestamp,
      text: item.seeHowResult?.userPhraseSuggestion,
    });
  };

  return (
    <CollapsibleItem
      wrapperStyles="p-3 rounded-[10px]"
      data-testid={item.slug}
      hideDefaultChevron
      triggerDisabled={item.result === 'true'}
      openByDefault={isTooltipCard}
      title={({ isOpen }) => (
        <div className="flex w-full justify-between items-center">
          <div className="flex items-center gap-2">
            {answer ? (
              <PlaybookCardIcon answer={answer} />
            ) : (
              <div className="w-7 h-7">
                <ClipLoader size={28} color="rgb(37 99 235)" />
              </div>
            )}
            <div className="flex justify-start items-center gap-1">
              <PlaybookItemTitle item={item} />
              {KEYDRIVER_ITEMS.includes(item.name) && <KeyDriverTag />}
            </div>
          </div>
          {seeHowResult && (
            <div className="w-[72px] flex justify-end">
              {item.result === 'true' ? (
                <span
                  className="text-main-gray-dark text-[10px] tracking-[-0.1px] ml-3 cursor-pointer font-normal hover:text-accent-blue pointer-events-auto"
                  onClick={handleTimestampClick}
                >
                  {formatTimestamp(item.seeHowResult?.userPhraseTimestamp)}
                </span>
              ) : (
                <div className="flex items-center gap-0">
                  <Button
                    variant="link"
                    className={cn(
                      'text-xs font-semibold pointer-events-auto relative',
                    )}
                  >
                    {isOpen ? (
                      <ChevronUp size={14} strokeWidth={2.5} />
                    ) : (
                      'See how'
                    )}
                  </Button>
                  {isTooltipCard && (
                    <OnboardingTooltip
                      placement="right-start"
                      tooltipId="see-how-playbook"
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    >
      {seeHowResult && (
        <div>
          <div className="w-full border-t my-4" />
          <div className="text-sm mb-2">
            {item.seeHowResult?.generalFeedback &&
              item.seeHowResult?.generalFeedback?.trim() !== 'N/A' &&
              item.seeHowResult?.generalFeedback}
          </div>
          <div className="flex flex-col gap-2">
            <SeeHowFeedbackCard
              callId={callId}
              variant="userQuote"
              quote={item.seeHowResult?.userPhrase}
              timestamp={item.seeHowResult?.userPhraseTimestamp}
            />
            <SeeHowFeedbackCard
              callId={callId}
              variant="suggestion"
              quote={item.seeHowResult?.userPhraseSuggestion}
            />
          </div>
        </div>
      )}
    </CollapsibleItem>
  );
};

interface PlaybookCardIconProps {
  answer: PlaybookItemAnswer;
}

const PlaybookCardIcon: React.FC<PlaybookCardIconProps> = ({ answer }) => (
  <WithHoverTooltip
    tooltip={
      answer.toLowerCase() === PlaybookItemAnswer.Unsure ? 'Unsure' : undefined
    }
  >
    <div
      className={cn(
        'flex items-start box-content w-4 h-4 p-1 rounded-lg border border-solid',
        answer.toLowerCase() === PlaybookItemAnswer.True &&
          'bg-playbook-green-1 border-playbook-green-2 text-accent-green',
        answer.toLowerCase() === PlaybookItemAnswer.Unsure &&
          'bg-playbook-yellow-1 border-playbook-yellow-2 text-accent-yellow',
        answer.toLowerCase() === PlaybookItemAnswer.False &&
          'bg-playbook-red-1 border-playbook-red-2 text-accent-red',
      )}
    >
      {answer.toLowerCase() === PlaybookItemAnswer.True && <Check size={16} />}
      {answer.toLowerCase() === PlaybookItemAnswer.Unsure && (
        <QuestionMarkIcon height={16} width={16} />
      )}
      {answer.toLowerCase() === PlaybookItemAnswer.False && <X size={16} />}
    </div>
  </WithHoverTooltip>
);
