const COLORS = [
  '#F57F17',
  '#AD1457',
  '#512DA8',
  '#2E7D32',
  '#37474F',
  '#D84315',
];

/**
 * Given a speaker number (index), map it to a color from the COLORS array
 * @param speakerNumber The index of the speaker
 * @returns HEX Code of the color to be used in the UI
 */
export function mapSpeakerNumberToColor(speakerNumber: number) {
  const colorIndex = (speakerNumber - 1) % COLORS.length; // Map the speaker number to a valid index in the COLORS array
  return COLORS[colorIndex];
}

/**
 * Hashes a string to a number
 * @param str The string to hash
 * @returns A number hash for the string
 */
function hashStringToNumber(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash); // A simple hashing function
  }
  return Math.abs(hash); // Ensure it's a positive number
}

/**
 * Given a speaker name, this function will return a color HEX Code to be used in the UI
 * @param speakerName Name of the speaker to map to a color
 * @returns HEX Code of the color to be used in the UI
 */
export function mapSpeakerNameToColor(speakerName: string) {
  const hash = hashStringToNumber(speakerName);
  const colorIndex = hash % COLORS.length; // Map the hash to a valid index in the COLORS array
  return COLORS[colorIndex];
}
