import React from 'react';

import { BlockOption } from '@/components/BlockOption/BlockOption';
import { RadioButton } from '@/components/Button/RadioButton';

export enum RecordingOption {
  JOIN = 'join',
  DONT_JOIN = 'dont-join',
}

export interface RecordingOptionProps {
  leftSlot?: React.ReactNode;
  selected: boolean;
  title: string;
  description: string | React.ReactNode;
  onClick: () => void;
  recommended?: boolean;
}

export const RecordingOptionItem: React.FC<RecordingOptionProps> = ({
  selected,
  title,
  description,
  recommended,
  onClick,
}) => {
  return (
    <div onClick={onClick}>
      <BlockOption
        title={title}
        description={description}
        selected={selected}
        leftSlot={<RadioButton selected={selected} />}
        rightSlot={
          recommended && (
            <span className="font-inter text-xs font-semibold leading-normal uppercase text-accent-dark-blue bg-accent-dark-blue-12-percent px-2 py-1 rounded-[8px]">
              RECOMMENDED
            </span>
          )
        }
      />
    </div>
  );
};
