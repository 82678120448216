import { create } from 'zustand';

export enum FeedbackLimitType {
  SALES_COACH = 'SALES_COACH',
  PLAYBOOK = 'PLAYBOOK',
  UPLOADS = 'UPLOADS',
}

interface FeedbackLimitModalStore {
  isFeedbackLimitModalOpen: boolean;
  type: FeedbackLimitType | null;
  openFeedbackLimitModal: (type?: FeedbackLimitType) => void;
  closeFeedbackLimitModal: () => void;
}

export const useFeedbackLimitModalStore = create<FeedbackLimitModalStore>(
  set => ({
    isFeedbackLimitModalOpen: false,
    type: null,
    openFeedbackLimitModal: (type?: FeedbackLimitType) =>
      set(() => ({ isFeedbackLimitModalOpen: true, type })),
    closeFeedbackLimitModal: () =>
      set(() => ({ isFeedbackLimitModalOpen: false })),
  }),
);
