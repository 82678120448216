import { useQuery } from '@tanstack/react-query';

import { getGoogleAuthStatus } from '@/api/auth/google/get-google-auth-status';

export const useGoogleAuthStatus = () => {
  const { data: googleAuthStatusData, ...rest } = useQuery({
    queryFn: getGoogleAuthStatus,
    queryKey: ['googleAuthStatus'],
  });

  return {
    googleAuthStatusData,
    ...rest,
  };
};
