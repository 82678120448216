import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toggleCalendarEventRecording as toggleCalendarEventRecordingAPI } from '@/api/calendar/toggle-calendar-event-recording';

export const useToggleCalendarEventRecording = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: toggleCalendarEventRecording, ...rest } = useMutation({
    mutationFn: toggleCalendarEventRecordingAPI,
    onSuccess: () => {
      queryClient.refetchQueries({
        predicate: (query) => {
          return query.queryKey[0] === 'calendarEvents';
        },
      });
    },
  });

  return { toggleCalendarEventRecording, ...rest };
};
