import { Spinner } from 'flowbite-react';
import React, { ReactNode, useState } from 'react';
import Collapsible from 'react-collapsible';
import { ChevronUp } from 'react-feather';

import { useModal } from '@/hooks/useModal';
import cn from '@/utils/cn';

interface CollapseCallSectionProps {
  title: React.ReactNode | ((params: { isOpen: boolean }) => React.ReactNode);
  children?: React.ReactNode;
  bottomElement?: React.ReactNode;
  openByDefault?: boolean;
  hideDefaultChevron?: boolean;
  triggerDisabled?: boolean;
  animation?: boolean;
  icon?: ReactNode;
  itemsContainerStyles?: string;
  titleStyles?: string;
  chevronStyles?: string;
  className?: string;
}

export const CollapseCallSection: React.FC<CollapseCallSectionProps> = ({
  title,
  children,
  bottomElement,
  itemsContainerStyles,
  chevronStyles,
  titleStyles,
  openByDefault,
  hideDefaultChevron,
  triggerDisabled,
  animation = false,
  className,
  icon,
}) => {
  const { isOpen, open, close } = useModal(openByDefault);
  const [animatedText] = useState('');
  const [isTranscribing] = useState(false);
  const [showLoader] = useState(false);

  return (
    <div
      className={cn(
        'bg-[#F6F8FA] rounded-[16px] border border-main-gray pt-5',
        className,
        !bottomElement && 'px-5 pb-5',
      )}
    >
      <Collapsible
        open={isOpen}
        triggerDisabled={triggerDisabled}
        onOpening={open}
        onClosing={close}
        transitionTime={100}
        trigger={
          <div
            className={cn(
              'flex gap-1 items-center text-sm font-semibold rounded-[20px] w-full',
              triggerDisabled && !isOpen && 'pointer-events-none',
              bottomElement && 'px-5',
            )}
          >
            {typeof title === 'function' ? (
              <span className="text-base">
                {icon && <>{icon}</>}
                {title({ isOpen })}
              </span>
            ) : (
              <span
                className={cn('text-base flex items-center gap-2', titleStyles)}
              >
                {icon && <>{icon}</>}
                {title}
              </span>
            )}
            {!hideDefaultChevron && (
              <button className="ml-auto text-accent-blue focus:outline-accent-blue">
                <ChevronUp
                  size={20}
                  className={cn(
                    'transition-transform',
                    chevronStyles,
                    !isOpen && 'rotate-180',
                  )}
                />
              </button>
            )}
          </div>
        }
      >
        {children && (
          <div
            className={cn(
              'pt-1.5',
              bottomElement && 'px-5',
              itemsContainerStyles,
            )}
          >
            {showLoader ? (
              <div className="flex items-center gap-2">
                <Spinner className="fill-accent-blue h-4 w-4 mb-1" />
                <span className="text-sm text-main-gray-dark">
                  Analyzing...
                </span>
              </div>
            ) : animation && isTranscribing ? (
              <p className="text-black text-sm">{animatedText}</p>
            ) : typeof children === 'string' ? (
              <p className="text-black text-sm">{children}</p>
            ) : (
              children
            )}
          </div>
        )}
        {bottomElement && <div>{bottomElement}</div>}
      </Collapsible>
    </div>
  );
};
