import { API } from '@/api/base';
import { Response } from '@/api/response';

import { Call } from '../../interfaces/call.interface';

const backendURL = process.env.REACT_APP_BACKEND_URL as string;

const endpoints = {
  getCallPublicData: (shareId: string) =>
    backendURL + `/api/calls/${shareId}/public`,
};

export const getCallPublicData =
  (shareId: string) => async (): Promise<Call | undefined> => {
    try {
      const response = await API(endpoints.getCallPublicData(shareId), {
        method: 'GET',
      });

      const result: Response<Call> = await response.json();

      return result.data;
    } catch (err) {
      console.error('fetch call questions', err);
      return undefined;
    }
  };
