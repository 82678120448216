import { amplitudeService } from './amplitude.service';

export const eventTracker = {
  auth: {
    loginComplete() {
      amplitudeService.trackEvent('loginComplete');
    },
    signUpComplete() {
      amplitudeService.trackEvent('signUpComplete');
    },
    passwordResetClick(params: { email: string }) {
      amplitudeService.trackEvent('passwordResetClick', params);
    },
    passwordResetEmailSent(params: { email: string }) {
      amplitudeService.trackEvent('passwordResetEmailSent', params);
    },
    passwordResetCompleted(params: { email: string }) {
      amplitudeService.trackEvent('passwordResetCompleted', params);
    },
    navbarLogoClick() {
      amplitudeService.trackEvent('navbarLogoClick');
    },
    signOut() {
      amplitudeService.trackEvent('signOut');
    },
  },
  insights: {
    pageOpen() {
      amplitudeService.trackEvent('insightsPageOpen');
    },
    sellerEvaluationTabClick() {
      amplitudeService.trackEvent('insightsSellerEvaluationTabOpen');
    },
    prospectEvaluationTabClick() {
      amplitudeService.trackEvent('insightsProspectEvaluationTabOpen');
    },
    metricsTabClick() {
      amplitudeService.trackEvent('insightsMetricsTabOpen');
    },
    topQuestionsTabClick() {
      amplitudeService.trackEvent('insightsTopQuestionsTabOpen');
    },
    timePeriodChange(params: { timePeriod: string }) {
      amplitudeService.trackEvent('insightsTimePeriodChanged', params);
    },
  },
  calls: {
    pageOpen() {
      amplitudeService.trackEvent('callsPageOpen');
    },
    playbookTabClick(params: { callId: string }) {
      amplitudeService.trackEvent('callsPagePlaybookTabClick', params);
    },
    feedbackTabClick(params: { callId: string }) {
      amplitudeService.trackEvent('callsPageFeedbackTabClick', params);
    },
    questionsTabClick(params: { callId: string }) {
      amplitudeService.trackEvent('callsPageQuestionsTabClick', params);
    },
    transcriptTabClick(params: { callId: string }) {
      amplitudeService.trackEvent('callsPageTranscriptTabClick', params);
    },
    search(params: { userInput: string }) {
      amplitudeService.trackEvent('callsPageSearchInput', params);
    },
    callButtonClick(params: { callId: string }) {
      amplitudeService.trackEvent('callButtonClick', params);
    },
  },
  callDetails: {
    numberOfSpeakersChange(params: {
      callId: string;
      numberOfSpeakers: number;
    }) {
      amplitudeService.trackEvent('callDetailsNumberOfSpeakersChange', params);
    },
    editCallDetailsClick(params: { callId: string }) {
      amplitudeService.trackEvent('callDetailsEditClick', params);
    },
    saveCallDetails(params: { callId: string; name: string; date: Date }) {
      amplitudeService.trackEvent('callDetailsSaveClick', params);
    },
    editCallDateClick(params: { callId: string }) {
      amplitudeService.trackEvent('callDetailsEditDateClick', params);
    },
    changeSellerClick(params: { callId: string }) {
      amplitudeService.trackEvent('callDetailsChangeSellerClick', params);
    },
    changeSellerApply(params: { callId: string; speaker: string }) {
      amplitudeService.trackEvent('callDetailsChangeSellerApply', params);
    },
    changeSellerName(params: {
      callId: string;
      name: string;
      sellerIdentifier: string;
    }) {
      amplitudeService.trackEvent('callDetailsChangeSellerName', params);
    },
    excludeFromAnalysisClick(params: { callId: string }) {
      amplitudeService.trackEvent(
        'callDetailsExcludeFromAnalysisClick',
        params,
      );
    },
    includeInAnalysisClick(params: { callId: string }) {
      amplitudeService.trackEvent('callDetailsIncludeInAnalysisClick', params);
    },
    deleteCallClick(params: { callId: string }) {
      amplitudeService.trackEvent('callDetailsDeleteCallClick', params);
    },
    deleteCallApply(params: { callId: string }) {
      amplitudeService.trackEvent('callDetailsDeleteCallApply', params);
    },
  },
  record: {
    startClick() {
      amplitudeService.trackEvent('recordStartClick');
    },
    stopClick() {
      amplitudeService.trackEvent('recordStopClick');
    },
  },
  upload: {
    uploadButtonClick() {
      amplitudeService.trackEvent('uploadButtonClick');
    },
    uploadLinkClick() {
      amplitudeService.trackEvent('uploadLinkClick');
    },
    uploadCompleted(params: { callId: string }) {
      amplitudeService.trackEvent('uploadCompleted', params);
    },
  },
  playbookPage: {
    pageOpen() {
      amplitudeService.trackEvent('playbookPageOpen');
    },
    updateClick() {
      amplitudeService.trackEvent('playbookPageUpdateClick');
    },
  },
} as const;
