import React from 'react';
import { Edit2, List } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import { DemoPlaybookItemsResults } from '@/components/CallDetailsPage/DemoCall/DemoCallMockData';
import {
  FeedbackLimitType,
  useFeedbackLimitModalStore,
} from '@/components/CallDetailsPage/FeedbackLimitModal/useFeedbackLimitModalStore';
import { useForceCallReanalyze } from '@/components/CallDetailsPage/hooks/useForceCallReanalyze';
import { ItsSalesCallButton } from '@/components/CallDetailsPage/ItsSalesCallButton';
import {
  PlaybookCard,
  PlaybookItemAnswer,
} from '@/components/CallDetailsPage/Playbook/PlaybookCard';
import { SummaryBlockEmptyState } from '@/components/CallDetailsPage/Summary/SummaryBlockEmptyState';
import { FreemiumRestrictionLimitMessage } from '@/components/Shared/Freemium/FreemiumRestrictionLimitMessage';
import { useSubscribeModalStore } from '@/components/subscription/SubscribeModal/useSubscribeModalStore';
import { useSubscription } from '@/hooks/useSubscription';
import { Call, PlaybookItem } from '@/interfaces/call.interface';
import logger from '@/utils/logger';

import { Button } from '../Button/Button';
import { CollapseCallSection } from '../CollapseCallSection/CollapseCallSection';
import { OnboardingTooltip } from '../Onboarding/OnboardingTooltip/OnboardingTooltip';
import { ReanalyzeCallBanner } from './ReanalyzeCallBanner';

interface PlaybookProps {
  call: Call;
}

const Playbook = ({ call }: PlaybookProps) => {
  const { forceCallAnalyze, isPending } = useForceCallReanalyze();
  const {
    availablePlaybookAnalysisCallsAmount,
    callsPlaybookAnalysisLimit,
    isFree,
    openStripeCustomerPortal,
  } = useSubscription();
  const { openSubscribeModal } = useSubscribeModalStore();
  const { openFeedbackLimitModal } = useFeedbackLimitModalStore();

  const playbookItems: PlaybookItem[] = call.isDemoCall
    ? DemoPlaybookItemsResults
    : call.playbook?.playbookItems;

  const disabledPlaybookItemIds =
    call.playbook?.playbookItemsDisabledByUser?.map(el => el.playbookItemId) ||
    [];

  const activePlaybookItems = (playbookItems || []).filter(
    (item: PlaybookItem) =>
      call.isDemoCall || !disabledPlaybookItemIds.includes(item.id),
  );

  const sellerEvaluationItems = activePlaybookItems.filter(
    (item: PlaybookItem) => item.category === 'Seller evaluation',
  );

  const activePlaybookStats = call.playbook.playbooksStats.find(
    el => el.playbookId === call.lastReanalyzedPlaybookId,
  );

  const callHasActualPlaybookResults =
    call.lastReanalyzedIndex === call.organization.lastReanalyzedIndex ||
    call.lastReanalyzedPlaybookId === call.organization.activePlaybookId;

  const isSalesCall =
    call.isSalesCall ||
    call.isSalesCall === null ||
    call.isSalesCall === undefined;

  const hasPlaybookData =
    (isSalesCall && call.lastReanalyzedPlaybookId !== null) || call.isDemoCall;

  let leftLessThanHalfLimit;
  if (
    callsPlaybookAnalysisLimit &&
    availablePlaybookAnalysisCallsAmount !== null &&
    availablePlaybookAnalysisCallsAmount !== undefined &&
    availablePlaybookAnalysisCallsAmount >= 0
  ) {
    leftLessThanHalfLimit =
      availablePlaybookAnalysisCallsAmount <= callsPlaybookAnalysisLimit / 2;
  }

  const showSubscriptionLimitation =
    availablePlaybookAnalysisCallsAmount !== null &&
    availablePlaybookAnalysisCallsAmount !== undefined &&
    callsPlaybookAnalysisLimit &&
    leftLessThanHalfLimit &&
    !hasPlaybookData;

  const handleUpgradeClick = () => {
    if (isFree) {
      openSubscribeModal();
      return;
    } else {
      openStripeCustomerPortal();
    }
  };

  const handlePlaybookAnalysisClick = async () => {
    if (
      availablePlaybookAnalysisCallsAmount !== null &&
      availablePlaybookAnalysisCallsAmount !== undefined &&
      availablePlaybookAnalysisCallsAmount <= 0
    ) {
      openFeedbackLimitModal(FeedbackLimitType.PLAYBOOK);
      return;
    }

    await forceCallAnalyze({
      callId: call.id,
      analyzePlaybook: true,
      analyzeSalesCoachFeedback: false,
    });

    window.location.reload();
  };

  const navigate = useNavigate();

  logger.debug({ call }, 'Playbook Tab');

  return (
    <div>
      {(!callHasActualPlaybookResults ||
        (call.organization.lastReanalyzedIndex === 1 &&
          activePlaybookStats?.processingItems !== undefined &&
          activePlaybookStats?.processingItems > 0 &&
          isSalesCall)) && <ReanalyzeCallBanner call={call} />}
      <div className="flex flex-col h-full gap-4 pb-5">
        <div className="h-full mt-5">
          <CollapseCallSection
            titleStyles="w-full"
            title={
              <div className="flex flex-row justify-between items-center w-full">
                <span>
                  {call.isDemoCall
                    ? 'Standard Sales Playbook'
                    : call.playbook.name}
                </span>
                <div className="flex items-center">
                  <Button
                    variant="primary-inverted"
                    className="!py-1 !px-3 flex flex-row gap-1 !rounded-[10px] text-xs font-semibold h-fit items-center"
                    onClick={() => {
                      navigate('/playbook');
                    }}
                  >
                    <Edit2 className="w-3 h-3" />
                    Edit Playbook
                  </Button>
                  {call.isDemoCall && (
                    <OnboardingTooltip
                      placement="right-start"
                      tooltipId="edit-playbook"
                    />
                  )}
                </div>
              </div>
            }
            hideDefaultChevron
            itemsContainerStyles={!isSalesCall ? 'pt-0' : 'pt-1.5'}
            openByDefault
            triggerDisabled
            icon={
              <List width={20} height={20} className="text-main-gray-dark" />
            }
            bottomElement={
              showSubscriptionLimitation && (
                <FreemiumRestrictionLimitMessage
                  className="rounded-b-[16px] mt-4"
                  availableLimit={availablePlaybookAnalysisCallsAmount}
                  itemsLimit={callsPlaybookAnalysisLimit}
                  itemsUsed={
                    callsPlaybookAnalysisLimit -
                    availablePlaybookAnalysisCallsAmount
                  }
                  handleUpgradeClick={handleUpgradeClick}
                  limitType={FeedbackLimitType.PLAYBOOK}
                />
              )
            }
          >
            {!isSalesCall && (
              <div>
                <SummaryBlockEmptyState
                  title="Sounds like this is not a sales call"
                  description="We currently only provide feedback for sales calls"
                />
                {call.isValid && (
                  <ItsSalesCallButton
                    callId={call.id}
                    containerStyles="mt-3"
                    limitType={FeedbackLimitType.PLAYBOOK}
                  />
                )}
              </div>
            )}
            {isSalesCall && hasPlaybookData && (
              <div className="flex flex-col gap-1.5 mt-3">
                {sellerEvaluationItems.map((item, index) => (
                  <PlaybookCard
                    item={item}
                    answer={item.result as PlaybookItemAnswer}
                    key={item.name}
                    callId={call.id}
                    isTooltipCard={call.isDemoCall && index === 0}
                  />
                ))}
              </div>
            )}
            {isSalesCall && !hasPlaybookData && (
              <div className="flex flex-col gap-4 justify-center items-start mt-2.5">
                <span className="text-main-gray-dark text-xs">
                  Select ‘Get Playbook Analysis’ below to see how you applied
                  this Sales Playbook.
                </span>
                <Button
                  variant="primary"
                  isLoading={isPending}
                  onClick={handlePlaybookAnalysisClick}
                >
                  Get Playbook Analysis
                </Button>
              </div>
            )}
          </CollapseCallSection>
        </div>
      </div>
    </div>
  );
};

export default Playbook;
