import React from 'react';
import { Link2, X } from 'react-feather';

import { Button } from '../../Button/Button';
import { Input } from '../../Input/Input';
import Modal from '../../Shared/Modal';
import { useGetCallShareLink } from './useGetCallShareLink';
import { useShareCallModalStore } from './useShareCallModalStore';

/**
 * ShareCallModal component.
 */
function ShareCallModal() {
  const { isShareCallModalOpen, closeShareCallModal, call } =
    useShareCallModalStore();

  const { callShareLink, isLoading } = useGetCallShareLink(call?.id || '');
  const shareLink = `${window.location.origin}/share-call/${callShareLink?.id}`;

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [isCopied, setIsCopied] = React.useState(false);

  React.useEffect(() => {
    if (isShareCallModalOpen && inputRef.current && call) {
      inputRef.current.setSelectionRange(0, 0); // Place cursor at the beginning
    }
  }, [isShareCallModalOpen, call]);

  if (!call) {
    return;
  }

  return (
    <Modal
      show={isShareCallModalOpen && !isLoading}
      onClose={() => {
        closeShareCallModal();
      }}
      containerClassName="w-full max-w-[640px] m-0 p-[24px] flex flex-col gap-[24px]"
    >
      <div className="flex justify-between self-stretch items-start">
        <div className="flex flex-col gap-[4px]">
          <Modal.Title>Share &quot;{call.name}&quot;</Modal.Title>
          <Modal.Subtitle>
            This link will only share the recording and summary (not your
            personalized metrics and feedback).
          </Modal.Subtitle>
        </div>

        <X
          size={20}
          className="text-main-gray-dark cursor-pointer"
          onClick={closeShareCallModal}
        />
      </div>

      <div className="flex flex-row gap-1 items-center">
        <Input
          placeholder="Assets share link"
          leftSlot={<Link2 size={20} className="text-dark" />}
          readOnly
          value={shareLink}
          className="flex-1 transition-all"
          ref={inputRef}
        />

        <Button
          variant="primary"
          className="relative overflow-hidden w-[8ch]"
          onClick={() => {
            navigator.clipboard.writeText(shareLink);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 4000);
          }}
        >
          <span
            className={`absolute inset-0 flex items-center justify-center transition-opacity duration-200 ${isCopied ? 'opacity-0' : 'opacity-100'}`}
          >
            Copy
          </span>
          <span
            className={`absolute inset-0 flex items-center justify-center transition-opacity duration-200 ${isCopied ? 'opacity-100' : 'opacity-0'}`}
          >
            Copied
          </span>
        </Button>
      </div>
    </Modal>
  );
}

export { ShareCallModal };
