import { CallNextStepResponse } from '@/api/call/get-call-next-steps';

export const getExistingResponsibleSpeakersWithColors = (
  nextSteps: CallNextStepResponse[],
): Record<string, string> => {
  const colors = ['#2C6CF6', '#F57F17', '#0A7C00', '#E74029'];
  const existingResponsibleSpeakers: Record<string, string> = {};

  nextSteps.forEach(nextStep => {
    if (!nextStep.responsibleSpeaker) {
      return;
    }

    if (!existingResponsibleSpeakers[nextStep.responsibleSpeaker]) {
      existingResponsibleSpeakers[nextStep.responsibleSpeaker] =
        colors[Object.keys(existingResponsibleSpeakers).length % colors.length];
    }
  });

  return existingResponsibleSpeakers;
};
