import 'react-circular-progressbar/dist/styles.css';

import { Transition } from '@headlessui/react';
import React, { Fragment, useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { useSelector } from 'react-redux';

import { Button } from '@/components/Button/Button';
import { useQuickstartModalStore } from '@/components/Quickstart/hooks/useQuickstartModalStore';
import {
  CompletedItems,
  useQuickstartStore,
} from '@/components/Quickstart/hooks/useQuickstartStore';
import { QuickstartMenu } from '@/components/Quickstart/QuickstartMenu';
import { useGoogleAuthStatus } from '@/hooks/useGoogleAuthStatus';
import { useRecordingSettings } from '@/hooks/useRecordingSettings';
import { selectCalls } from '@/pages/HomePage/slice/selectors';
import { FOREVER, getCookie, setCookie } from '@/utils/cookie';

export const DISABLE_SHOW_QUICKSTART_FINISHED_COOKIE =
  'DISABLE_SHOW_QUICKSTART_FINISHED_COOKIE';

export const QUICKSTART_TOTAL_ITEMS = 3;

export const QuickstartButton = () => {
  const { setItemsCompleted, totalCompleted } = useQuickstartStore();

  const calls = useSelector(selectCalls);
  const { googleAuthStatusData } = useGoogleAuthStatus();
  const { recordingSettingsData } = useRecordingSettings();
  const { isQuickstartModalOpen, openQuickstartModal, closeQuickstartModal } =
    useQuickstartModalStore();

  const isCalendarConnected = googleAuthStatusData?.isCalendarConnected;
  const isMeetingRecordingOptionSaved =
    recordingSettingsData?.isMeetingRecordingOptionSaved;
  const hasCalls =
    calls.filter((el: any) => el.isDemoCall === false).length > 0;

  useEffect(() => {
    if (
      isCalendarConnected === undefined &&
      isMeetingRecordingOptionSaved === undefined &&
      hasCalls === undefined
    )
      return;

    const itemsCompleted: CompletedItems = {
      0: isCalendarConnected === true,
      1: isMeetingRecordingOptionSaved === true,
      2: hasCalls === true,
    };

    setItemsCompleted(itemsCompleted);

    const automaticallyOpenQuickstartModal =
      getCookie(DISABLE_SHOW_QUICKSTART_FINISHED_COOKIE) === null;

    if (automaticallyOpenQuickstartModal) {
      const itemsCompletedNumber = Object.values(itemsCompleted).filter(
        (el) => el === true,
      ).length;

      if (itemsCompletedNumber === QUICKSTART_TOTAL_ITEMS) {
        setCookie(DISABLE_SHOW_QUICKSTART_FINISHED_COOKIE, 'true', FOREVER);
        openQuickstartModal();
      }
    }
  }, [isCalendarConnected, isMeetingRecordingOptionSaved, hasCalls]);

  return (
    <div className="fixed z-[100] bottom-0 right-0 p-4">
      <div className="relative inline-block">
        <Button
          variant="primary"
          onClick={() =>
            isQuickstartModalOpen
              ? closeQuickstartModal()
              : openQuickstartModal()
          }
          className="rounded-[50px] p-0 h-fit py-2.5 px-4"
          style={{
            boxShadow:
              '0px 1px 16px -2px rgba(44, 108, 246, 0.32), 0px 2px 16px -2px rgba(44, 108, 246, 0.20)',
          }}
          contentWrapperClassName="flex"
        >
          <div className="flex items-center justify-center gap-1.5">
            <div className="w-8 h-8">
              <CircularProgressbar
                styles={{
                  trail: {
                    stroke: '#FFFFFF3D',
                  },
                  path: {
                    stroke: '#FFF',
                  },
                  text: {
                    marginTop: '6px',
                    fill: '#FFF',
                    fontSize: '34',
                    fontWeight: '600',
                    transform: 'translateY(2px)',
                  },
                }}
                value={(totalCompleted / QUICKSTART_TOTAL_ITEMS) * 100}
                text={`${totalCompleted}/${QUICKSTART_TOTAL_ITEMS}`}
                strokeWidth={10}
              />
            </div>
            <span>Quick Start</span>
          </div>
        </Button>
        <Transition
          show={isQuickstartModalOpen}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div className="bottom-full mb-2 absolute right-0 w-[420px] z-[1000000]">
            <QuickstartMenu
              hasCalls={hasCalls || false}
              isCalendarConnected={isCalendarConnected || false}
              isMeetingRecordingOptionSaved={
                isMeetingRecordingOptionSaved || false
              }
            />
          </div>
        </Transition>
      </div>
    </div>
  );
};
