import { call, put, takeLatest } from 'redux-saga/effects';

import { TeamTableColumnsFilterHelper } from '../helpers/teamTableColumnsFilterHelper';
import { leadershipTeamActions as actions } from '../slice';

const backendURL = process.env.REACT_APP_BACKEND_URL;

/**
 * Merge team table column options with user settings
 * @param options - team table column options
 * @param userSettings - user settings
 * @returns - merged options
 */
function mergeTeamTableColumnOptionsWithUserSettings(options, userSettings) {
  const addEnabledProperty = item => {
    return Object.hasOwn(userSettings, item.key)
      ? { ...item, show: userSettings[item.key] }
      : { ...item, show: true };
  };
  return options.map(addEnabledProperty);
}

/**
 * Fetch team performance
 * @param action - action.payload
 * @yields fetch
 */
function* doFetchTeamPerformance(action) {
  try {
    let query = '';
    if (action.payload) {
      const { sortBy, sortDirection, timeFilter, page, size } =
        action?.payload || {};

      if (sortBy && sortDirection) {
        query += `sortBy=${sortBy}&sortDirection=${sortDirection}`;
      }

      if (page !== undefined && size !== undefined) {
        const paginationQuery = `page=${page}&size=${size}`;
        query += query?.length ? `&${paginationQuery}` : paginationQuery;
      }

      if (timeFilter) {
        const filterQuery = `timeFilter=${timeFilter}`;
        query += query?.length ? `&${filterQuery}` : filterQuery;
      }
    }

    const response = yield fetch(
      backendURL + `/api/organization/performance/team?${query}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      },
    );

    const body = yield response.json();

    yield put(actions.fetchTeamPerformanceSuccess(body));
  } catch (err) {
    log('Fetch organization team performance Error: ', err);
    throw err;
  }
}

/**
 * Fetch team columns options
 * @param action - action.payload
 * @yields fetch
 */
function* doFetchTeamColumnsOptions(action) {
  try {
    const response = yield call(
      fetch,
      `${backendURL}/api/organization/team/columns`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      },
    );

    const body = yield response.json();

    const options = mergeTeamTableColumnOptionsWithUserSettings(
      body.options,
      body.userSettings,
    );
    const filteredOptions = TeamTableColumnsFilterHelper.filterOptions(options);

    yield put(actions.fetchTeamColumnsOptionsSuccess(filteredOptions));
  } catch (err) {
    yield put(
      actions.fetchTeamColumnsOptionsFailed(
        'Fetch Team Columns Options Failed',
      ),
    );
  }
}

/**
 * Update show column option
 * @param action - action.payload
 * @yields fetch
 */
function* doUpdateShowColumnOption(action) {
  try {
    const item = action.payload;

    yield put(actions.toggleShowColumnOption(item));

    yield call(fetch, `${backendURL}/api/organization/team/columns`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `jwt ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify({
        desiredColumns: [item],
      }),
    });
  } catch (err) {
    yield put(
      actions.updateTeamColumnsOptionsFailed(
        'Update Team Columns Options Failed',
      ),
    );
  }
}

/**
 * Leadership team saga
 * @yields takeLatest
 */
export function* leadershipTeamSaga() {
  yield takeLatest(actions.fetchTeamPerformance.type, doFetchTeamPerformance);
  yield takeLatest(
    actions.fetchTeamColumnsOptions.type,
    doFetchTeamColumnsOptions,
  );
  yield takeLatest(
    actions.updateShowColumnOption.type,
    doUpdateShowColumnOption,
  );
}
