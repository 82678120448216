import { API } from '@/api/base';
import { Response } from '@/api/response';

const backendURL = process.env.REACT_APP_BACKEND_URL as string;

const endpoints = {
  updateCallNextStep: (callId: string, nextStepId: string) =>
    backendURL + `/api/calls/${callId}/next-steps/${nextStepId}`,
};

export interface CallNextStepResponse {
  id: string;
  callId: string;
  text: string;
  timestamp: string | null;
  responsibleSpeaker: string | null;
  isDone: boolean;
}

export interface UpdateCallNextStepRequest {
  callId: string;
  nextStepId: string;
  isDone: boolean;
}

export const updateCallNextStep = async ({
  nextStepId,
  callId,
  isDone,
}: UpdateCallNextStepRequest): Promise<CallNextStepResponse> => {
  const response = await API(endpoints.updateCallNextStep(callId, nextStepId), {
    method: 'PATCH',
    body: JSON.stringify({
      isDone,
    }),
  });

  const result: Response<CallNextStepResponse> = await response.json();

  return result.data;
};
