import { useMutation, useQueryClient } from '@tanstack/react-query';

import { joinRecorderToEvent as joinRecorderToEventAPI } from '@/api/calendar/join-recorder-to-event';

export const useJoinRecorderToEvent = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: joinRecorderToEvent, ...rest } = useMutation({
    mutationFn: joinRecorderToEventAPI,
    onSuccess: () => {
      queryClient.refetchQueries({
        predicate: (query) => {
          return query.queryKey[0] === 'calendarEvents';
        },
      });
    },
  });

  return { joinRecorderToEvent, ...rest };
};
