import cn from '@/utils/cn';

export interface ProgressBarProps {
  sections: number;
  sectionsCompleted: number;
  firstDashClasses?: string;
  lastDashClasses?: string;
  commonDashClasses?: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  sections,
  sectionsCompleted,
  commonDashClasses,
  firstDashClasses,
  lastDashClasses,
}) => {
  return (
    <div className="flex flex-row items-center gap-0.5 h-full w-full">
      {Array.from({ length: sections }).map((_, i) => {
        const isFilled = i < sectionsCompleted;
        const isFirst = i === 0;
        const isLast = i === sections - 1;

        return (
          <div
            key={i}
            className={cn(
              'flex  h-full',
              isFilled ? 'bg-accent-blue' : 'bg-[#CFDDFD]',
              commonDashClasses,
              isFirst && cn('rounded-l-[8px]', firstDashClasses),
              isLast && cn('rounded-r-[8px]', lastDashClasses),
            )}
            style={{
              width: `calc(100% / ${sections})`,
            }}
          ></div>
        );
      })}
    </div>
  );
};
