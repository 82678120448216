import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import { CalendarEventResponse } from '@/api/calendar/get-calendar-events';
import { Button } from '@/components/Button/Button';
import { useGetCalendarEvents } from '@/components/Calendar/hooks/useGetCalendarEvents';
import { EmptyUpcomingCalls } from '@/components/Calendar/UpcomingCalls/EmptyUpcomingCalls';
import { useUpcomingCallsModalStore } from '@/components/Calendar/UpcomingCalls/hooks/useUpcomingCallsModalStore';
import { MeetingItem } from '@/components/Recording/MeetingItem';
import { SyncingCalendarLoader } from '@/components/Recording/SyncingCalendarLoader';
import { useRecordingSettings } from '@/hooks/useRecordingSettings';
import cn from '@/utils/cn';

type GroupedCalendarEvents = {
  [key in string]: CalendarEventResponse[];
};

export const UpcomingCallsList: React.FC = () => {
  const MAX_DAYS = 7;

  const [showMore, setShowMore] = useState<boolean>(true);
  const [previousEventsCount, setPreviousEventsCount] = useState<number>(0);
  const [increaseUntilNewEventsShown, setIncreaseUntilNewEventsShown] =
    useState<boolean>(false);
  const [daysToShow, setDaysToShow] = useState<number>(1);
  const [shownGroupedEvents, setShownGroupedEvents] =
    useState<GroupedCalendarEvents>({});

  const { closeUpcomingCallsModal } = useUpcomingCallsModalStore();
  const { eventsData: allEvents, isLoading: calendarEventsLoading } =
    useGetCalendarEvents(MAX_DAYS);
  const { recordingSettingsData, isLoading: recordingSettingsLoading } =
    useRecordingSettings();

  const isLoading = calendarEventsLoading || recordingSettingsLoading;

  const daysLimitReached = daysToShow === MAX_DAYS;
  const alLEventsShown = previousEventsCount === allEvents?.length;

  useEffect(() => {
    if (!allEvents) return;

    const eventsToShow = allEvents.filter((event) => {
      return dayjs(event.start).isBefore(
        dayjs(dayjs().endOf('day')).add(daysToShow - 1, 'days'),
      );
    });

    if (eventsToShow.length > previousEventsCount) {
      setIncreaseUntilNewEventsShown(false);
    }

    if (
      eventsToShow.length === previousEventsCount &&
      !daysLimitReached &&
      increaseUntilNewEventsShown
    ) {
      setDaysToShow(daysToShow + 1);
      return;
    }

    setPreviousEventsCount(eventsToShow.length);
    setShowMore(eventsToShow.length < allEvents.length);

    const shownGroupedEvents = eventsToShow.reduce((acc, event) => {
      const date = dayjs(event.start).format('MMM DD');

      if (!acc[date]) {
        acc[date] = [];
      }

      acc[date].push(event);

      return acc;
    }, {} as GroupedCalendarEvents);

    setShownGroupedEvents(shownGroupedEvents);

    if (
      allEvents.length > 0 &&
      eventsToShow.length === 0 &&
      previousEventsCount === 0
    ) {
      setDaysToShow(daysToShow + 1);
    }
  }, [allEvents, daysToShow]);

  const handleShowMoreClick = () => {
    setIncreaseUntilNewEventsShown(true);
    setDaysToShow(daysToShow + 1);
  };

  const formattedDate = (date: string) => {
    const isToday =
      dayjs(date).format('MMM DD') === dayjs(new Date()).format('MMM DD');

    return `${isToday ? 'TODAY, ' : ''}${dayjs(date).format('MMM DD')}`;
  };

  if ((isLoading && daysToShow === 1) || recordingSettingsLoading) {
    return <SyncingCalendarLoader isModalView={false} />;
  }

  return (
    <div className="flex flex-col items-center w-full overflow-y-auto max-h-[75vh]">
      <div
        className={cn(
          'w-full px-5 mt-5',
          (daysLimitReached || alLEventsShown) && 'pb-5',
        )}
      >
        {allEvents?.length === 0 && <EmptyUpcomingCalls />}
        {Object.entries(shownGroupedEvents).map(
          ([date, calendarEvents], index) => (
            <div key={index} className={`${index > 0 && 'mt-4'}`}>
              <span className="text-main-gray-dark text-xs font-semibold leading-[18px] tracking-[-0.12px] uppercase font-inter text-right">
                {formattedDate(date)}
              </span>
              {calendarEvents.map((calendarEvent) => (
                <MeetingItem
                  showSilkchartAssistantPopup={false}
                  calendarEvent={calendarEvent}
                  key={`${calendarEvent.id}-${calendarEvent.isRecordingEnabled}`}
                  className="mt-1.5 mb-0"
                  onClose={closeUpcomingCallsModal}
                />
              ))}
            </div>
          ),
        )}
      </div>
      {showMore && (
        <Button
          onClick={handleShowMoreClick}
          isLoading={isLoading}
          className="mt-4 mb-5"
          variant="secondary"
        >
          Show more
        </Button>
      )}
    </div>
  );
};
