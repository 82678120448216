import React, { useState } from 'react';

import { Button } from '@/components/Button/Button';
import { useHelpModalStore } from '@/components/HelpModal/useHelpModalStore';
import { Toggle } from '@/components/Toggle/Toggle';
import { useSubscription } from '@/hooks/useSubscription';

import { CheckIcon } from '../../Shared/icons/CheckIcon';
import Modal from '../../Shared/Modal';
import { useSubscribeModalStore } from './useSubscribeModalStore';

/**
 * Enterprise Plan Card
 */
function EnterprisePlanCard() {
  return (
    <div className="flex flex-col h-full py-8 px-7 gap-8">
      <div className="flex flex-col gap-5">
        <h3 className="opacity-70 text-dark text-lg font-semibold">
          Enterprise
        </h3>

        <div className="flex flex-col gap-0.5 h-[60px] justify-center">
          <span className="text-xl font-medium text-dark">Let&apos;s talk</span>
          <div className="text-main-gray-dark text-xs font-medium">
            for scaling teams
          </div>
        </div>

        <a
          href="https://calendly.com/silkchart/silkchart-demo"
          target="_blank"
          rel="noreferrer"
        >
          <Button
            className="w-full px-3 py-[14px] h-auto"
            onClick={() => {}}
            variant="secondary-inverted"
          >
            Book a Demo
          </Button>
        </a>
      </div>

      <div className="flex flex-col gap-6 text-dark">
        <ul className="opacity-80 text-sm list-disc list-inside flex gap-4 flex-col">
          <li>
            <span className="font-bold">Recording:</span> Unlimited calls &
            viewers
          </li>
          <li>
            <span className="font-bold">Feedback:</span> Unlimited calls
          </li>
        </ul>

        <div className="h-[0px] opacity-20 border-t-2 border-[#5c6a82]"></div>

        <div className="flex flex-col gap-4 text-sm font-medium">
          <span className="opacity-80">Key Features:</span>
          <ul className="flex gap-4 flex-col">
            <li className="flex flex-row gap-[6px] items-center">
              <CheckIcon className="w-4 h-4 text-[#0eb500]" />
              <span className="opacity-80">
                <span className="font-bold">CRM Integration</span>
              </span>
            </li>
            <li className="flex flex-row gap-[6px] items-center">
              <CheckIcon className="w-4 h-4 text-[#0eb500]" />
              <span className="opacity-80">
                <span className="font-bold">Customizable sales playbooks</span>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

/**
 * Free Plan Card
 */
function FreePlanCard() {
  return (
    <div className="flex flex-col h-full py-8 px-7 gap-8">
      <div className="flex flex-col gap-5">
        <h3 className="opacity-70 text-dark text-lg font-semibold">
          Start - Free
        </h3>

        <span className="text-[40px] font-medium">$0</span>

        <Button className="w-full px-3 py-[14px] h-auto" disabled>
          Current plan
        </Button>
      </div>

      <div className="flex flex-col gap-6 text-dark">
        <ul className="opacity-80 text-sm list-disc list-inside flex gap-4 flex-col">
          <li>
            <span className="font-bold">Recording:</span> Unlimited calls &
            viewers
          </li>
          <li>
            <span className="font-bold">Feedback:</span> 10 calls per month
          </li>
        </ul>

        <div className="h-[0px] opacity-20 border-t-2 border-[#5c6a82]"></div>

        <div className="flex flex-col gap-4 text-sm font-medium">
          <span className="opacity-80">Key Features:</span>
          <ul className="flex gap-4 flex-col">
            <li className="flex flex-row gap-[6px] items-center">
              <CheckIcon className="w-4 h-4 text-[#0eb500]" />
              <span className="opacity-80">
                <span className="font-bold">Playbook Adoption Analysis:</span>{' '}
                10/month
              </span>
            </li>
            <li className="flex flex-row gap-[6px] items-center">
              <CheckIcon className="w-4 h-4 text-[#0eb500]" />
              <span className="opacity-80">
                <span className="font-bold">AI Sales Coach Feedback:</span>{' '}
                10/month
              </span>
            </li>
            <li className="flex flex-row gap-[6px] items-center">
              <CheckIcon className="w-4 h-4 text-[#0eb500]" />
              <span className="opacity-80">
                <span className="font-bold">Call Uploads:</span> 10/month
              </span>
            </li>
            <li className="flex flex-row gap-[6px] items-center">
              <CheckIcon className="w-4 h-4 text-[#0eb500]" />
              <span className="opacity-80">
                <span className="font-bold">Unlimited AI Summaries</span> <br />
                and notes on key topics
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

/**
 * Pro Plan Card
 */
function ProPlanCard() {
  const [billingMode, setBillingMode] = useState<'anually' | 'monthly'>(
    'anually',
  );

  const planPrice = billingMode === 'anually' ? 19 : 29;

  const { openStripeCustomerPortal } = useSubscription();

  return (
    <div className="flex flex-col h-full gap-8 py-8 px-7 bg-light-blue rounded-lg">
      <div className="flex flex-col gap-5">
        <div className="flex flex-row justify-between">
          <h3 className="opacity-70 text-dark text-lg font-semibold">Pro</h3>

          <div className="text-dark flex items-center text-sm gap-1 justify-between">
            <Toggle
              checked={billingMode === 'anually'}
              onChange={() =>
                setBillingMode(
                  billingMode === 'anually' ? 'monthly' : 'anually',
                )
              }
              uncheckedClassName="bg-main-gray-dark"
              variant="small"
            />
            <span className="font-medium text-xs text-dark opacity-70">
              Billed annually
            </span>
          </div>
        </div>

        <div className="flex gap-2 items-center">
          <span className="text-[40px] font-medium">${planPrice}</span>
          <div className="flex flex-col gap-0.5">
            <span className="text-xs text-dark">per user/mo</span>
            <span className="text-xs text-main-gray-dark">
              billed {billingMode}
            </span>
          </div>

          {billingMode === 'anually' && (
            <div className="h-[25px] p-1 bg-[#2c6cf6]/10 rounded justify-center items-center gap-2.5 inline-flex">
              <div className="text-[#2c6cf6] text-sm font-bold font-['Inter']">
                -50%
              </div>
            </div>
          )}
        </div>

        <Button
          className="w-full px-3 py-[14px] h-auto"
          onClick={openStripeCustomerPortal}
        >
          Upgrade
        </Button>
      </div>

      <div className="flex flex-col gap-6 text-dark">
        <ul className="opacity-80 text-sm list-disc list-inside flex gap-4 flex-col">
          <li>
            <span className="font-bold">Recording:</span> Unlimited calls &
            viewers
          </li>
          <li>
            <span className="font-bold">Feedback:</span> Unlimited calls
          </li>
        </ul>

        <div className="h-[0px] opacity-20 border-t-2 border-[#0046df]"></div>

        <div className="flex flex-col gap-4 text-sm font-medium">
          <span>Everything in starter plus:</span>
          <ul className="flex gap-4 flex-col">
            <li className="flex flex-row gap-[6px] items-center">
              <CheckIcon className="w-4 h-4 text-[#0eb500]" />
              <span className="opacity-80">
                <span className="font-bold">Playbook Adoption Analysis:</span>{' '}
                Unlimited
              </span>
            </li>
            <li className="flex flex-row gap-[6px] items-center">
              <CheckIcon className="w-4 h-4 text-[#0eb500]" />
              <span className="opacity-80">
                <span className="font-bold">AI Sales Coach Feedback:</span>{' '}
                Unlimited
              </span>
            </li>
            <li className="flex flex-row gap-[6px] items-center">
              <CheckIcon className="w-4 h-4 text-[#0eb500]" />
              <span className="opacity-80">
                <span className="font-bold">Call Uploads:</span> Unlimited
              </span>
            </li>
            <li className="flex flex-row gap-[6px] items-center">
              <CheckIcon className="w-4 h-4 text-[#0eb500]" />
              <span className="opacity-80">
                <span className="font-bold">Sales Leader Account</span> <br />-
                team level performance insights
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export const SubscribeModal: React.FC = () => {
  const { isSubscribeModalOpen, closeSubscribeModal } =
    useSubscribeModalStore();

  const { openHelpModalWithOptionSelected } = useHelpModalStore();

  return (
    <Modal
      show={isSubscribeModalOpen}
      onClose={closeSubscribeModal}
      showCloseButton
      containerClassName="w-full max-w-[1116px] m-0 p-[24px] flex flex-col gap-[24px]"
    >
      <div className="flex max-w-[960px] gap-1 flex-col items-start">
        <h1 className="text-dark text-base font-extrabold">Subscription</h1>
        <h2 className="text-main-gray-dark text-sm font-normal">
          You are currently using the Free plan with unlimited recording and
          limited feedback.
        </h2>
      </div>
      <div className="w-full grid grid-cols-3 bg-light-gray rounded-lg overflow-hidden">
        <FreePlanCard />
        <ProPlanCard />
        <EnterprisePlanCard />
      </div>
      <div className="text-center">
        <span className="text-dark text-sm font-normal">Need more info? </span>
        <span
          role="button"
          className="text-accent-blue text-sm font-medium"
          onClick={() => {
            openHelpModalWithOptionSelected('subscription');
            closeSubscribeModal();
          }}
        >
          Contact us
        </span>
      </div>
    </Modal>
  );
};
