import { useQuery } from '@tanstack/react-query';

import { getCallTranscript as getCallTranscriptAPI } from '@/api/call/get-call-transcript';

import { CallTranscript } from '../../../../api/call/call-transcript.interface';

export const useGetCallTranscript = (callId: string, isPublic: boolean) => {
  const { data: callTranscript, ...rest } = useQuery<
    CallTranscript | undefined
  >({
    queryFn: getCallTranscriptAPI(callId, isPublic),
    queryKey: ['callTranscript', callId],
  });

  return { callTranscript, ...rest };
};
