import { useQuery } from '@tanstack/react-query';

import { getCallAudio as getCallAudioAPI } from '../../../../api/call/get-call-audio';

export const useGetCallAudio = (callId: string, isPublic: boolean) => {
  const { data: callAudio, ...rest } = useQuery<string | undefined>({
    queryFn: getCallAudioAPI(callId, isPublic),
    queryKey: ['callAudio', callId],
  });

  return { callAudio, ...rest };
};
