import { PlayIcon } from '@heroicons/react/24/outline';
import { PauseIcon } from '@heroicons/react/24/solid';
import { Button } from 'flowbite-react';
import React, { useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';

import cn from '@/utils/cn';
import {
  formatTimeWithHoursWithoutDescription,
  formatTimeWithoutHoursWithoutDescription,
} from '@/utils/formatDate';

import { useGetCallAudio } from '../Summary/hooks/useGetCallAudio';

interface OnProgressEvent {
  playedSeconds: number;
}

export interface MediaPlayerProps {
  setCurrentAudioTime: (event: number) => void;
  isPublic?: boolean;
}

export const MediaPlayer: React.FC<MediaPlayerProps> = ({
  setCurrentAudioTime,
  isPublic = false,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentAudioTime, setCurrentAudioTimeInternal] = useState(0);
  const [duration, setDuration] = useState(0);
  const playerRef = useRef<ReactPlayer>(null);
  const { id } = useParams();
  const { callAudio } = useGetCallAudio(id || '', isPublic);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleProgress = (state: OnProgressEvent) => {
    setCurrentAudioTimeInternal(state.playedSeconds);
    setCurrentAudioTime(state.playedSeconds);
  };

  const handleDuration = (duration: number) => {
    setDuration(duration);
  };

  const handleSeek = (event: React.MouseEvent<HTMLDivElement>) => {
    const { clientX } = event;
    const progressBar = event.currentTarget;
    const rect = progressBar.getBoundingClientRect();
    const clickPosition = clientX - rect.left;
    const clickPercentage = clickPosition / rect.width;
    const newTime = clickPercentage * duration;

    if (playerRef.current) {
      playerRef.current.seekTo(newTime);
      setCurrentAudioTimeInternal(newTime);
    }
  };

  const isVideo = useMemo(() => callAudio?.includes('.mp4'), [callAudio]);

  if (isVideo) {
    return (
      <div
        className={cn(
          'w-full border-[#CBD2E0] rounded-t-2xl overflow-hidden h-[350px] bg-black',
        )}
        style={{ borderBottom: 0 }}
      >
        <ReactPlayer
          width="100%"
          height="100%"
          className="react-player"
          ref={playerRef}
          url={callAudio}
          playing={isPlaying}
          onProgress={handleProgress}
          onDuration={handleDuration}
          controls
          config={{
            file: {
              attributes: {
                poster: '/video-bg.png',
              },
            },
          }}
        />
      </div>
    );
  } else {
    return (
      <div className="w-full flex items-center bg-white p-5 border border-b-0 rounded-t-2xl">
        <Button
          onClick={handlePlayPause}
          className="flex items-center justify-center w-10 h-10 rounded-full bg-blue-600 text-white mr-3"
        >
          {isPlaying ? (
            <PauseIcon height={24} width={28} />
          ) : (
            <PlayIcon height={24} width={30} className="ml-0.5" />
          )}
        </Button>
        <span className="text-sm text-[#5C6A82] min-w-[65px]">
          {formatTimeWithHoursWithoutDescription(currentAudioTime)}
        </span>
        <div
          className="relative w-full h-2 bg-[#ECECEC] rounded-full mr-2 overflow-hidden cursor-pointer"
          onClick={handleSeek}
        >
          <div
            className="absolute top-0 left-0 h-full bg-blue-600 rounded-full"
            style={{ width: `${(currentAudioTime / duration) * 100}%` }}
          ></div>
        </div>
        <span className="text-sm text-[#5C6A82]">
          {formatTimeWithoutHoursWithoutDescription(duration)}
        </span>
        <ReactPlayer
          ref={playerRef}
          url={callAudio}
          playing={isPlaying}
          controls={false}
          width="0"
          height="0"
          onProgress={handleProgress}
          onDuration={handleDuration}
        />
      </div>
    );
  }
};
