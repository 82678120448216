import { create } from 'zustand';

interface CallDetailsStore {
  tabIndex?: number;
  setTabIndex: (index: number) => void;
}

export const useCallDetailsStore = create<CallDetailsStore>(set => ({
  tabIndex: 0,
  setTabIndex: index => set(() => ({ tabIndex: index })),
}));
