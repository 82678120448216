import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateCallNextStep as updateCallNextStepAPI } from '@/api/call/update-call-next-step';

export const useUpdateCallNextStep = (callId: string) => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateCallNextStep, ...rest } = useMutation({
    mutationFn: updateCallNextStepAPI,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['callNextSteps', callId] });
    },
  });

  return { updateCallNextStep, ...rest };
};
