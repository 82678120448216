import { useQuery } from '@tanstack/react-query';

import { getRecordingSettings } from '@/api/calendar/get-recording-settings';

export const useRecordingSettings = () => {
  const { data: recordingSettingsData, ...rest } = useQuery({
    queryFn: getRecordingSettings,
    queryKey: ['recordingSettings'],
  });

  return {
    recordingSettingsData,
    ...rest,
  };
};
