import { create } from 'zustand';

export interface UpcomingCallsModalStore {
  isUpcomingCallsModalOpen: boolean;
  openUpcomingCallsModal: () => void;
  closeUpcomingCallsModal: () => void;
}

export const useUpcomingCallsModalStore = create<UpcomingCallsModalStore>(
  (set) => ({
    isUpcomingCallsModalOpen: false,
    openUpcomingCallsModal: () =>
      set(() => ({ isUpcomingCallsModalOpen: true })),
    closeUpcomingCallsModal: () =>
      set(() => ({ isUpcomingCallsModalOpen: false })),
  }),
);
