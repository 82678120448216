import React from 'react';

import cn from '@/utils/cn';

export interface RadioButtonProps {
  selected: boolean;
  size?: string;
  className?: string;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  selected,
  className,
  size = '20px',
}) => {
  return (
    <input
      type="radio"
      checked={selected}
      className={cn(
        `mr-2 h-[${size}] w-[${size}] outline-red-500 focus:invisible`,
        !selected && 'border-2 border-main-gray-2',
        className,
      )}
      style={{
        backgroundSize: 'cover',
      }}
    />
  );
};
