import { API } from '../base';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const endpoints = {
  forceCallAnalyze: `${backendURL}/api/calls/:callId/force-analyze`,
};

export type ForceCallAnalyzeParams = {
  callId: string;
  analyzeSalesCoachFeedback: boolean;
  analyzePlaybook: boolean;
};

export const forceCallAnalyze = async (params: ForceCallAnalyzeParams) =>
  await API(endpoints.forceCallAnalyze.replace(':callId', params.callId), {
    method: 'POST',
    body: JSON.stringify({
      analyzeSalesCoachFeedback: params.analyzeSalesCoachFeedback,
      analyzePlaybook: params.analyzePlaybook,
    }),
  });
