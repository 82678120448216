import './CustomPlaybookSettingsPage.css';

import React, { useEffect, useRef } from 'react';
import { ArrowLeft } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { Button } from '@/components/Button/Button';
import { toast } from '@/components/Toast/toaster';
import { useModal } from '@/hooks/useModal';
import cn from '@/utils/cn';

import { Item } from './Item';
import { ItemsPool } from './ItemsPool';
import { RequestCustomItemsModal } from './RequestCustomItemsModal';
import { useCustomPlaybookStore } from './useCustomPlaybookStore';
import {
  initSortableLists,
  isSellerEvaluation,
  matchesCategory,
  Sortable,
} from './utils';

interface CustomPlaybookSettingsPageProps {
  edit: boolean;
}

const appendSortableCDNScript = () => {
  const script = document.createElement('script');
  script.src = 'https://cdn.jsdelivr.net/npm/sortablejs@latest/Sortable.min.js';
  script.async = true;
  document.body.appendChild(script);
  return script;
};

export const CustomPlaybookSettingsPage: React.FC<
  CustomPlaybookSettingsPageProps
> = () => {
  const {
    isOpen: showRequestCustomItemsModal,
    open: openRequestCustomItemsModal,
    close: closeRequestCustomItemsModal,
  } = useModal();

  const navigate = useNavigate();

  const {
    saving,
    setSaving,
    fetchInitialState,
    setIsSortableScriptLoaded,
    updateCustomPlaybook,
    setActivePlaybook,
  } = useCustomPlaybookStore(
    useShallow(state => ({
      saving: state.saving,
      setSaving: state.setSaving,
      fetchInitialState: state.fetchInitialState,
      setIsSortableScriptLoaded: state.setIsSortableScriptLoaded,
      updateCustomPlaybook: state.updateCustomPlaybook,
      setActivePlaybook: state.setActivePlaybook,
    })),
  );
  const [isInitialStateFetched, isSortableScriptLoaded] =
    useCustomPlaybookStore(
      useShallow(state => [
        state.isInitialStateFetched,
        state.isSortableScriptLoaded,
      ]),
    );
  const categories = useCustomPlaybookStore(state => state.categories);
  const selected = useCustomPlaybookStore(state => state.selected);

  const listsRef = useRef<Map<string, HTMLUListElement>>(new Map());
  const sortablesRef = useRef<Map<string, Sortable>>(new Map());

  useEffect(() => {
    const script = appendSortableCDNScript();
    fetchInitialState();

    script.onload = () => setIsSortableScriptLoaded(true);
  }, []);

  useEffect(() => {
    if (isInitialStateFetched && isSortableScriptLoaded) {
      initSortableLists(categories, listsRef, sortablesRef);
    }
  }, [isInitialStateFetched, isSortableScriptLoaded]);

  const getSlugsFromSelectedLists = () =>
    categories
      .map(category => sortablesRef.current.get(category)!.toArray())
      .flat();

  const handleSave = async () => {
    try {
      setSaving(true);

      const slugs = getSlugsFromSelectedLists();
      const body = await updateCustomPlaybook(slugs);
      await setActivePlaybook(body.playbookId);
      toast.success({
        title: 'Template saved and applied!',
        subtitle: 'We’ve updated the Sales Playbook for your calls',
        renderRightSlot({ closeToast }) {
          return (
            <Button
              variant="secondary"
              onClick={() => {
                closeToast();
                navigate('/');
              }}
            >
              View Calls
            </Button>
          );
        },
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      toast.error();
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <RequestCustomItemsModal
        show={showRequestCustomItemsModal}
        onClose={closeRequestCustomItemsModal}
        showCloseButton={true}
      />
      <div className="flex flex-col gap-2">
        <div className="flex gap-[52px] pl-[124px] relative">
          <button
            type="button"
            className="text-main-gray-dark absolute left-[48px] top-[34px]"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowLeft size={24} />
          </button>
          <div
            data-testid="custom-playbook"
            className="flex flex-col items-start gap-6 grow pt-8 pb-8 border-box w-[704px] h-[calc(100vh-70px)] overflow-y-scroll overflow-x-hidden hide-scrollbar"
          >
            <div className="flex flex-col items-start justify-center gap-2.5">
              <p className="text-dark text-[22px] font-bold">
                Custom Sales Playbook Template
              </p>
              <p className="text-main-gray-dark text-sm font-normal">
                Create your own template based on items from all other templates
                or request new
              </p>
            </div>
            {categories.map(category => {
              const items = selected.filter(item =>
                matchesCategory(item, category),
              );
              return (
                <div
                  key={category}
                  className="flex flex-col items-start gap-2 self-stretch"
                >
                  <div className="flex items-center gap-1.5 self-stretch h-4">
                    <p className="text-main-gray-dark text-xs font-semibold uppercase">
                      {category}
                    </p>
                    {isSellerEvaluation(category) && (
                      <ItemsCounter category={category} />
                    )}
                  </div>
                  {items.length === 0 && (
                    <p className="text-main-gray-dark text-xs">
                      Add items from list on the right
                    </p>
                  )}
                  <ul
                    ref={node => {
                      if (node) {
                        listsRef.current.set(category, node);
                      } else {
                        listsRef.current.delete(category);
                      }
                    }}
                    className="flex flex-col items-start gap-1.5 self-stretch w-full"
                  >
                    {items.map(item => (
                      <li
                        key={item.id}
                        data-id={item.slug}
                        className="silkchart-li rounded-lg-1.5 self-stretch select-none cursor-grab"
                      >
                        <Item
                          item={item}
                          inSelectedList={true}
                          isChosen={true}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
            <div>
              <Button
                disabled={selected.length === 0}
                isLoading={saving}
                variant="primary"
                onClick={handleSave}
              >
                Save & Apply Template
              </Button>
            </div>
          </div>
          <ItemsPool
            openRequestCustomItemsModal={openRequestCustomItemsModal}
          />
        </div>
      </div>
    </>
  );
};

const ItemsCounter: React.FC<{ category: string }> = ({ category }) => {
  const selected = useCustomPlaybookStore(state => state.selected);
  const limit = useCustomPlaybookStore(state => state.sellerEvaluationLimit);
  const count = selected.filter(item => matchesCategory(item, category)).length;
  return (
    <div
      className={cn(
        'px-1.5 py-1 rounded-lg bg-accent-blue/10 text-accent-blue text-xs font-medium',
        count === limit && 'bg-accent-red/10 text-accent-red',
      )}
    >
      {count}/{limit}
      {count === limit && (
        <span> - You&apos;ve reached the maximum number of playbook items</span>
      )}
    </div>
  );
};
