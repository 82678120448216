import React from 'react';

import cn from '@/utils/cn';

export interface BlockOptionProps {
  title: string;
  selected?: boolean;
  description?: string | React.ReactNode;
  className?: string;
  leftSlotWrapperClassName?: string;
  rightSlotWrapperClassName?: string;
  leftSlot?: React.ReactNode;
  rightSlot?: React.ReactNode;
}

export const BlockOption: React.FC<BlockOptionProps> = ({
  selected,
  title,
  description,
  leftSlot,
  rightSlot,
  className,
  leftSlotWrapperClassName,
  rightSlotWrapperClassName,
}) => {
  return (
    <div
      className={cn(
        'px-4 py-[14px] rounded-lg border',
        selected
          ? 'border-accent-dark-blue/16 bg-accent-blue/10'
          : 'border-main-gray',
        className,
      )}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {leftSlot && (
            <div className={leftSlotWrapperClassName}>{leftSlot}</div>
          )}
          <div className="flex flex-col">
            <span className="text-sm font-medium text-dark">{title}</span>
            <span className="text-xs font-medium text-main-gray-dark mt-0.5">
              {description}
            </span>
          </div>
        </div>
        {rightSlot && (
          <div className={rightSlotWrapperClassName}>{rightSlot}</div>
        )}
      </div>
    </div>
  );
};
