import { call, put, select, takeLatest } from 'redux-saga/effects';

import { liveFeedbackActions as actions } from '.';
import { selectCurrentMetric } from './selectors';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const S3Api = (method, url, fileType, body) => {
  const options = {
    method,
    headers: {
      'Content-Type': fileType,
    },
  };

  if (['POST', 'PUT'].includes(method)) options.body = body;

  return fetch(url, options);
};

/**
 * Upload live feedback chunk
 * @param action - action.payload.file
 * @yields fetch
 */
function* doUploadLiveFeedbackChunk(action) {
  const { file: fileContent } = action.payload;
  const {
    type: fileType,
    blob: blob,
    name: fileName,
    headerChunk: headerChunk,
  } = fileContent;

  try {
    const s3UrlResponse = yield fetch(
      backendURL + '/api/liveFeedback/uploadUrl',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({
          fileName,
          fileType,
        }),
      },
    );

    const s3UrlBody = yield s3UrlResponse.json();

    if (!headerChunk) {
      let offset = -1;
      let value = 0;
      const magicNumber = parseInt(
        '0x1F43B675'
          .match(/[a-fA-F0-9]{2}/g)
          .reverse()
          .join(''),
        16,
      );

      while (value !== magicNumber) {
        offset = offset + 1;
        try {
          const buffer = yield blob.slice(offset, offset + 4).arrayBuffer();
          const arr = new Int32Array(buffer);
          value = arr[0];
        } catch (error) {
          return;
        }
      }

      offset = offset + 4;
      const header = blob.slice(0, offset);
      yield put(actions.setHeaderChunk(header));
    }

    let resultBlob;
    if (!headerChunk) {
      resultBlob = new Blob([blob], { type: fileType });
    } else {
      resultBlob = new Blob([headerChunk, blob], { type: fileType });
    }

    yield call(S3Api, 'PUT', s3UrlBody.uploadUrl, fileType, resultBlob);

    const runTranscriptResponse = yield fetch(
      backendURL + `/api/liveFeedback/transcript?s3Key=${s3UrlBody.key}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      },
    );

    const runTranscriptBody = yield runTranscriptResponse.json();
    yield put(actions.saveLastChunkKey(runTranscriptBody.transcriptId));
  } catch (err) {
    log('Upload Live Feedback Chunk Error: ', err);
    throw err;
  }
}

/**
 * Fetch live feedback
 * @param action - action.payload.lastChunkKey
 * @yields fetch
 */
function* doFetchLiveFeedback(action) {
  try {
    const { lastChunkKey } = action.payload;

    const currentMetric = yield select(selectCurrentMetric);

    const response = yield fetch(
      backendURL +
        `/api/liveFeedback/checkTranscript?transcriptId=${lastChunkKey}&metricType=${currentMetric}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `jwt ${localStorage.getItem('authToken')}`,
        },
      },
    );

    const body = yield response.json();

    yield put(
      actions.setLiveFeedback({
        feedbackResult: body.feedbackResult,
        overrideStatusText: body.overrideStatusText,
      }),
    );
  } catch (err) {
    log('Fetch Live Feedback Error: ', err);
    throw err;
  }
}

/**
 * Live feedback saga
 * @yields takeLatest
 */
export function* liveFeedbackSaga() {
  yield takeLatest(actions.fetchLiveFeedback.type, doFetchLiveFeedback);
  yield takeLatest(
    actions.uploadLiveFeedbackChunk.type,
    doUploadLiveFeedbackChunk,
  );
}
