import React, { useEffect } from 'react';
import { X } from 'react-feather';

import { BlockOption } from '@/components/BlockOption/BlockOption';
import { Button } from '@/components/Button/Button';
import { useDeleteGoogleAuth } from '@/components/Calendar/RecordingSettings/hooks/useDeleteGoogleAuth';
import { useRecordingSettingsModalStore } from '@/components/Calendar/RecordingSettings/hooks/useRecordingSettingsModalStore';
import { useUpdateRecordingSettings } from '@/components/Calendar/RecordingSettings/hooks/useUpdateRecordingSettings';
import {
  RecordingOption,
  RecordingOptionItem,
} from '@/components/Calendar/RecordingSettings/RecordingOption';
import { useUpcomingCallsModalStore } from '@/components/Calendar/UpcomingCalls/hooks/useUpcomingCallsModalStore';
import { useGoogleLoginWrapper } from '@/components/Recording/hooks/useGoogleLoginWrapper';
import Modal from '@/components/Shared/Modal';
import { Toggle } from '@/components/Toggle/Toggle';
import { useGoogleAuthStatus } from '@/hooks/useGoogleAuthStatus';
import { useRecordingSettings } from '@/hooks/useRecordingSettings';

export const RecordingSettingsModal = () => {
  const [autoJoinAllMeetings, setAutoJoinAllMeetings] = React.useState(true);
  const [selectedRecordingOption, setSelectedRecordingOption] =
    React.useState<RecordingOption>(RecordingOption.JOIN);

  const { recordingSettingsData } = useRecordingSettings();
  const { updateRecordingSettings } = useUpdateRecordingSettings();
  const { deleteGoogleAuth, isPending } = useDeleteGoogleAuth();

  const { closeRecordingSettingsModal, isRecordingSettingsModalOpen } =
    useRecordingSettingsModalStore();
  const { openUpcomingCallsModal } = useUpcomingCallsModalStore();
  const { googleAuthStatusData } = useGoogleAuthStatus();
  const { googleLogin } = useGoogleLoginWrapper();

  useEffect(() => {
    if (!recordingSettingsData) return;

    setAutoJoinAllMeetings(recordingSettingsData.botAutoJoinAllMeetings);
    setSelectedRecordingOption(
      recordingSettingsData.botJoinMeetings
        ? RecordingOption.JOIN
        : RecordingOption.DONT_JOIN,
    );
  }, [recordingSettingsData]);

  const handleUsingCalendarClick = (e: any) => {
    e.stopPropagation();
    closeRecordingSettingsModal();
    openUpcomingCallsModal();
  };

  const handleDisconnectClick = async () => {
    await deleteGoogleAuth();
  };

  const changeRecordingOption = async (option: RecordingOption) => {
    setSelectedRecordingOption(option);
    await updateRecordingSettings({
      botJoinMeetings: option === RecordingOption.JOIN,
      botAutoJoinAllMeetings: autoJoinAllMeetings,
    });
  };

  const toggleAutoJoinAllMeetings = async () => {
    setAutoJoinAllMeetings(!autoJoinAllMeetings);
    await updateRecordingSettings({
      botJoinMeetings: selectedRecordingOption === RecordingOption.JOIN,
      botAutoJoinAllMeetings: !autoJoinAllMeetings,
    });
  };

  return (
    <Modal
      show={isRecordingSettingsModalOpen}
      onClose={closeRecordingSettingsModal}
    >
      <div className="min-w-[640px]">
        <div className="flex flex-row justify-between">
          <Modal.Title>Recording Settings</Modal.Title>
          <X
            size={20}
            className="text-main-gray-dark cursor-pointer"
            onClick={closeRecordingSettingsModal}
          />
        </div>
        <div className="my-6 border-t w-[150%] -ml-[25%] border-t-main-gray"></div>
        <div>
          <div>
            <span className="font-semibold text-sm leading-normal tracking-[-0.14px] font-inter">
              How do you want to record?
            </span>
            <div className="flex flex-col gap-1 mt-3">
              <RecordingOptionItem
                selected={selectedRecordingOption === RecordingOption.JOIN}
                title="Join meeting"
                description="For best sound quality, SilkChart joins meetings"
                recommended={true}
                onClick={() => changeRecordingOption(RecordingOption.JOIN)}
              />
              <RecordingOptionItem
                selected={selectedRecordingOption === RecordingOption.DONT_JOIN}
                title={'Don’t join meeting'}
                description={
                  'Record privately from browser, but headphones are not supported'
                }
                recommended={false}
                onClick={() => changeRecordingOption(RecordingOption.DONT_JOIN)}
              />
            </div>
          </div>
          <div
            className={
              selectedRecordingOption === RecordingOption.JOIN
                ? 'visible'
                : 'hidden'
            }
          >
            <div className="my-6 border-t w-full border-t-main-gray"></div>
            <div>
              <span className="font-semibold text-sm leading-normal tracking-[-0.14px] font-inter">
                Which meetings should be recorded?
              </span>
              <div onClick={toggleAutoJoinAllMeetings}>
                <BlockOption
                  className="mt-3"
                  title={'Auto-join all meetings'}
                  description={
                    <span>
                      <span>Turn on auto-join for specific meetings</span>{' '}
                      <span
                        className="text-accent-blue cursor-pointer"
                        onClick={handleUsingCalendarClick}
                      >
                        using the Calendar button
                      </span>
                    </span>
                  }
                  leftSlot={
                    <Toggle checked={autoJoinAllMeetings} className="mr-2" />
                  }
                />
              </div>
            </div>
          </div>
          <div className="my-6 border-t w-full border-t-main-gray"></div>
          <span className="font-semibold text-sm leading-normal tracking-[-0.14px] font-inter">
            Connected calendar
          </span>
          <BlockOption
            className="mt-3"
            leftSlot={
              <img
                alt={'Google Calendar'}
                className="mr-2"
                src="/calendar/google-calendar-icon.svg"
                width={24}
                height={24}
              />
            }
            rightSlot={
              googleAuthStatusData?.isCalendarConnected ? (
                <Button
                  variant="secondary"
                  onClick={handleDisconnectClick}
                  isLoading={isPending}
                >
                  Disconnect
                </Button>
              ) : (
                <Button variant="primary" onClick={googleLogin}>
                  Connect
                </Button>
              )
            }
            title={
              googleAuthStatusData?.isCalendarConnected &&
              googleAuthStatusData?.googleCalendarEmail
                ? googleAuthStatusData.googleCalendarEmail
                : 'Google Calendar'
            }
            description={
              !googleAuthStatusData?.isCalendarConnected &&
              'Automatically import your meetings and record them'
            }
          />
        </div>
      </div>
    </Modal>
  );
};
