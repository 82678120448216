import { create } from 'zustand';

import { RecordingCall } from '@/widgets/RecordWidget/useCallRecordingStore';

interface RecordingCalendarState {
  isCalendarConnectionSkipped: boolean | null;
  isEventAdded: boolean | null;
  isLoading: boolean;
  showCalendarConnectedScreen: boolean;
  browserRecordingCall: RecordingCall | null;
}

interface RecordingCalendarStoreActions {
  setIsCalendarConnectionSkipped: (
    isCalendarConnectionSkipped: boolean,
  ) => void;
  setIsEventAdded: (isEventAdded: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  setShowCalendarConnectedScreen: (
    showCalendarConnectedScreen: boolean,
  ) => void;
  setBrowserRecordingCall: (browserRecordingCall: RecordingCall | null) => void;
  reset: () => void;
}

export const getInitialState = (): RecordingCalendarState => ({
  browserRecordingCall: null,
  isCalendarConnectionSkipped: null,
  isEventAdded: null,
  isLoading: false,
  showCalendarConnectedScreen: false,
});

export const useRecordingCalendarStore = create<
  RecordingCalendarState & RecordingCalendarStoreActions
>((set) => ({
  ...getInitialState(),
  setIsCalendarConnectionSkipped: (isCalendarConnectionSkipped: boolean) =>
    set(() => ({ isCalendarConnectionSkipped })),
  setIsEventAdded: (isEventAdded: boolean) => set(() => ({ isEventAdded })),
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  setShowCalendarConnectedScreen: (showCalendarConnectedScreen: boolean) =>
    set(() => ({ showCalendarConnectedScreen })),
  setBrowserRecordingCall: (browserRecordingCall: RecordingCall | null) =>
    set(() => ({ browserRecordingCall })),

  reset: () => {
    set(() => ({
      ...getInitialState(),
    }));
  },
}));
