// Helper function for grouping quotes from transcript by speaker (e.g. change speaker dialog)
export const getSpeakerQuotes = transcript => {
  const speakers = {};
  const utterances = transcript.utterances;
  for (let i = 0; i < utterances.length; i++) {
    const utterance = utterances[i];
    const speaker = utterance.speaker;
    if (!speakers[speaker]) {
      speakers[speaker] = [];
    }
    speakers[speaker].push(utterance.text);
  }
  return speakers;
};
