import { create } from 'zustand';

export type CompletedItems = { [key: number]: boolean };

interface QuickstartState {
  itemsCompleted: CompletedItems;
  totalCompleted: number;
}

interface QuickstartStoreActions {
  setItemsCompleted: (itemsCompleted: CompletedItems) => void;
}

export const getInitialState = (): QuickstartState => ({
  itemsCompleted: {},
  totalCompleted: 0,
});

export const useQuickstartStore = create<
  QuickstartState & QuickstartStoreActions
>((set) => ({
  ...getInitialState(),
  setItemsCompleted: (itemsCompleted: CompletedItems) =>
    set({
      itemsCompleted,
      totalCompleted: Object.values(itemsCompleted).filter((el) => el === true)
        .length,
    }),
}));
