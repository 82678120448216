import React from 'react';
import { ChevronRight } from 'react-feather';

import { Button } from '@/components/Button/Button';
import { useNavigateToTranscript } from '@/hooks/useNavigateToTranscript';
import cn from '@/utils/cn';
import { formatTimestamp } from '@/utils/formatDate';

interface SeeHowFeedbackCardProps {
  callId: string;
  quote: string;
  timestamp?: string;
  variant: 'userQuote' | 'suggestion';
  readOnly?: boolean;
}

export const SeeHowFeedbackCard: React.FC<SeeHowFeedbackCardProps> = ({
  callId,
  quote,
  timestamp,
  variant,
  readOnly = false,
}) => {
  const { navigateToTranscript } = useNavigateToTranscript();

  if (!quote || quote.trim() === 'N/A') return null;

  return (
    <div className="basis-full bg-light-gray/50 rounded-xl p-3">
      <p
        className={cn(
          'text-xs font-semibold mb-2',
          variant === 'userQuote' ? 'text-accent-red' : 'text-accent-green',
        )}
      >
        {variant === 'userQuote' ? 'What you said' : 'You could have said'}
      </p>
      <p className="text-sm font-medium italic mb-2">‘{quote}’</p>
      {timestamp && (
        <Button
          variant="link"
          className="font-medium text-[10px] p-0 cursor-pointer"
          contentWrapperClassName="gap-0"
          onClick={() => {
            navigateToTranscript({
              callId,
              text: quote,
              timestamp: Number(timestamp),
            });
          }}
        >
          <span>AT {formatTimestamp(timestamp)}</span>
          <ChevronRight size={12} />
        </Button>
      )}
    </div>
  );
};
