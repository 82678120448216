import React from 'react';
import { Calendar } from 'react-feather';

import { Button } from '@/components/Button/Button';
import { RecordIcon } from '@/components/Shared/icons/RecordIcon';

export const EmptyUpcomingCalls: React.FC = () => {
  const handleRecordNewMeetingClick = () => {
    console.log('here');
  };

  return (
    <div className="flex flex-col">
      <div className="mb-4 flex items-center justify-center">
        <div className="h-16 w-16 rounded-full bg-accent-blue/10 flex items-center justify-center">
          <Calendar color={'#2C6CF6'} size={32} />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-1 mb-4">
        <span className="text-dark text-xl font-bold">
          No meetings in the next week
        </span>
        <span className="text-center text-main-gray-dark text-sm font-medium tracking-[-0.14px] w-[101%]">
          Schedule a meeting to get started with automatic recordings
        </span>
      </div>
    </div>
  );
};
