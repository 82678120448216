import { create } from 'zustand';

interface RecordingSettingsModalStore {
  isRecordingSettingsModalOpen: boolean;
  openRecordingSettingsModal: () => void;
  closeRecordingSettingsModal: () => void;
}

export const useRecordingSettingsModalStore =
  create<RecordingSettingsModalStore>((set) => ({
    isRecordingSettingsModalOpen: false,
    openRecordingSettingsModal: () =>
      set(() => ({ isRecordingSettingsModalOpen: true })),
    closeRecordingSettingsModal: () =>
      set(() => ({ isRecordingSettingsModalOpen: false })),
  }));
