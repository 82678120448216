import { Search } from 'react-feather';

import { eventTracker } from '@/amplitude/eventTracker';
import { Input } from '@/components/Input/Input';
import { useDebounce } from '@/hooks/useDebounce';
import cn from '@/utils/cn';

interface CallsListSearchProps {
  show: boolean;
  value: string;
  onChange: (s: string) => void;
}

export const CallsListSearch: React.FC<CallsListSearchProps> = ({
  show,
  value,
  onChange,
}: CallsListSearchProps) => {
  const trackSearch = useDebounce(eventTracker.calls.search, 500);

  return (
    <div className={cn(!show && 'invisible', 'relative mt-0 mx-8')}>
      <Input
        value={value}
        onChange={e => {
          onChange(e.target.value);
          trackSearch({ userInput: e.target.value });
        }}
        placeholder="Search Calls"
        leftSlot={<Search size={20} />}
      />
    </div>
  );
};
