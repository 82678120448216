import React, { useState } from 'react';
import { Info } from 'react-feather';

import { WithHoverTooltip } from '@/components/HoverTooltip/WithHoverTooltip';
import { MetricEvaluationDataMapper } from '@/components/InsightsAcrossCallsPage/mappers/MetricEvaluationDataMapper';
import { PossibleMetrics } from '@/components/Shared/helpers/BasicMetricsEvaluationHelper';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/Tooltip/Tooltip';
import { calculateMetricAttributeDisplay } from '@/utils/attributeDisplay';
import cn from '@/utils/cn';

interface FeedbackCardProps {
  attribute: PossibleMetrics;
  value?: string;
  infoHover?: boolean;
  hoverComponent?: React.ReactNode;
}

export const FeedbackCard: React.FC<FeedbackCardProps> = ({
  attribute,
  value,
  infoHover,
  hoverComponent,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const display = calculateMetricAttributeDisplay(attribute, value);
  const hoverDescription =
    MetricEvaluationDataMapper.customDescriptionText[attribute];

  const handleOnMouseEnter = () => {
    setIsHovered(true);
  };

  const handleOnMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={cn(
        'p-3 rounded-[10px] bg-white border border-[#ECECEC] flex gap-2 items-start relative',
      )}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <div className="flex gap-1">
        <p className="flex items-center gap-1 text-dark text-sm font-medium">
          {display?.displayAttributeName}:
        </p>
        <span
          className={cn(
            'text-sm font-medium',
            display?.color && `text-${display.color}`,
          )}
        >
          {display?.highlightText}
        </span>
        {hoverDescription && isHovered && (
          <WithHoverTooltip
            tooltip={
              <span className="inline-block w-auto max-w-[212px]">
                {hoverDescription}
              </span>
            }
          >
            <Info
              size={14}
              className="ml-0.5 text-main-gray-2 hover:text-main-gray-dark"
            />
          </WithHoverTooltip>
        )}
      </div>
      <div
        className={cn(
          'text-xs text-main-gray-dark ml-auto mt-0.5',
          infoHover && 'cursor-pointer underline hover:text-[#0654C2]',
        )}
        onMouseEnter={() => infoHover && setShowInfo(true)}
        onMouseLeave={() => infoHover && setShowInfo(false)}
        onClick={() => infoHover && setShowInfo(!showInfo)}
      >
        <p>{display?.displayAttributeDetails}</p>
      </div>

      {showInfo && hoverComponent && (
        <div className="mt-4 absolute z-[1000] right-3">
          <Tooltip open={true} placement="bottom-end">
            <TooltipTrigger asChild>
              <div className="absolute"></div>
            </TooltipTrigger>
            <TooltipContent className="rounded-[10px] py-3 px-2">
              {hoverComponent}
            </TooltipContent>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
