import React from 'react';

import { useQuickstartModalStore } from '@/components/Quickstart/hooks/useQuickstartModalStore';

import { useOnboardingTooltipCookieStore } from './onboardingTooltipCookieStore';

interface TooltipData {
  id: string;
  title: string;
  description: React.ReactNode;
}

export const onboardingTooltipsData = [
  {
    id: 'welcome',
    title: 'Welcome to SilkChart!',
    description: (
      <p>
        This is an example call to play around with. Record or <br />
        upload your own calls to see personalized insights.
      </p>
    ),
  },
  {
    id: 'playbook',
    title: 'Sales Playbook',
    description: (
      <p>
        SilkChart analyzes your sales calls to track how well you’re <br />
        executing against your sales strategy or playbook.
      </p>
    ),
  },
  {
    id: 'edit-playbook',
    title: 'Edit Sales Playbook',
    description: (
      <p>Choose from a top sales playbook framework or create your own</p>
    ),
  },
  {
    id: 'see-how-playbook',
    title: 'See How',
    description: (
      <p>
        Your AI Sales Coach will suggest phrasing you can use to <br />
        execute according to your playbook.
      </p>
    ),
  },
  {
    id: 'feedback',
    title: 'Coaching Feedback',
    description: (
      <p>
        It will also identify what you did well during the call, and <br />
        opportunities to improve.
      </p>
    ),
  },
  {
    id: 'insights',
    title: 'Insights',
    description: (
      <p>
        Below you can track progress in playbook adoption, speaking skills, and
        <br />
        objection handling
      </p>
    ),
  },
] as const satisfies TooltipData[];

export type OnboardingTooltipId = (typeof onboardingTooltipsData)[number]['id'];

const LAST_TOOLTIP_INDEX = onboardingTooltipsData.length - 1;

export const useOnboardingTooltip = () => {
  const {
    tooltipIndex: activeTooltipIndex,
    setTooltipIndex,
    startOnboarding,
    finishOnboarding,
  } = useOnboardingTooltipCookieStore();
  const { openQuickstartModal } = useQuickstartModalStore();

  const isOnboardingStarted = activeTooltipIndex >= 0;

  const isLastTooltip = activeTooltipIndex === LAST_TOOLTIP_INDEX;

  const activeTooltipData = onboardingTooltipsData[activeTooltipIndex];

  const next = () => {
    if (isLastTooltip) {
      finishOnboarding();
      openQuickstartModal();
      return;
    }

    setTooltipIndex(activeTooltipIndex + 1);
  };

  return {
    isOnboardingStarted,
    startOnboarding,
    activeTooltipIndex,
    next,
    activeTooltipData,
    isLastTooltip,
  };
};
