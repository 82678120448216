import { Transition } from '@headlessui/react';
import React, { Fragment, useRef } from 'react';
import { Calendar } from 'react-feather';

import { Button } from '@/components/Button/Button';
import { useUpcomingCallsModalStore } from '@/components/Calendar/UpcomingCalls/hooks/useUpcomingCallsModalStore';
import { UpcomingCalls } from '@/components/Calendar/UpcomingCalls/UpcomingCalls';
import { useClickOutside } from '@/hooks/useClickOutside';

export const CalendarButton: React.FC = () => {
  const {
    openUpcomingCallsModal,
    closeUpcomingCallsModal,
    isUpcomingCallsModalOpen,
  } = useUpcomingCallsModalStore();

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, closeUpcomingCallsModal);

  return (
    <div className="relative">
      <div className="relative z-[100] inline-block text-left">
        <div>
          <Button
            variant="secondary"
            className="relative p-2.5 w-9 h-9 rounded-[10px]"
            onClick={() =>
              isUpcomingCallsModalOpen
                ? closeUpcomingCallsModal()
                : openUpcomingCallsModal()
            }
          >
            <Calendar size={16} />
          </Button>
        </div>
        <Transition
          show={isUpcomingCallsModalOpen}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div ref={ref} className="absolute z-20 right-0 mt-1">
            <UpcomingCalls />
          </div>
        </Transition>
      </div>
    </div>
  );
};
