import dayjs from 'dayjs';
import React from 'react';
import { Clock } from 'react-feather';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useShallow } from 'zustand/react/shallow';

import { CalendarEventResponse } from '@/api/calendar/get-calendar-events';
import { SHOW_SILKCHART_ASSISTANT_SUCCESS_COOKIE } from '@/components/Recording/AddAssistantSuccess';
import { useJoinRecorderToEvent } from '@/components/Recording/hooks/useJoinRecorderToEvent';
import { useRecordingCalendarStore } from '@/components/Recording/hooks/useRecordingCalendarStore';
import { RecordButton } from '@/components/Recording/shared/RecordButton';
import { ToggleMeetRecording } from '@/components/Recording/ToggleMeetRecording';
import { CallStatus } from '@/enums/callStatus.enum';
import { useRecordingSettings } from '@/hooks/useRecordingSettings';
import { useHomeSlice } from '@/pages/HomePage/slice';
import cn from '@/utils/cn';
import { getCookie } from '@/utils/cookie';
import { useBotRecordingStore } from '@/widgets/RecordWidget/useBotRecordingStore';
import { useCallRecordingStore } from '@/widgets/RecordWidget/useCallRecordingStore';

export interface MeetingItemProps {
  calendarEvent: CalendarEventResponse;
  onClose?: () => void;
  className?: string;
  showSilkchartAssistantPopup?: boolean;
}

const Ellipse = () => (
  <div className="w-1 h-1 bg-main-gray-dark rounded-full opacity-[.24]"></div>
);

export const MeetingItem: React.FC<MeetingItemProps> = ({
  calendarEvent,
  onClose,
  className,
  showSilkchartAssistantPopup = true,
}) => {
  const dispatch = useDispatch();

  const { joinRecorderToEvent, isPending: isJoinRecorderToEventLoading } =
    useJoinRecorderToEvent();
  const { isRecording, requestRecordingStart } = useCallRecordingStore(
    useShallow((state) => ({
      isRecording: state.isRecording,
      requestRecordingStart: state.requestRecordingStart,
    })),
  );
  const { isGeneralBotFlowActive } = useBotRecordingStore(
    useShallow((state) => ({
      isGeneralBotFlowActive: state.isGeneralBotFlowActive,
    })),
  );
  const { setIsEventAdded, setBrowserRecordingCall } =
    useRecordingCalendarStore();
  const { recordingSettingsData } = useRecordingSettings();

  const { actions } = useHomeSlice();

  const isMeetingInProgress = dayjs(new Date()).isAfter(calendarEvent.start);

  const handleBrowserRecording = async () => {
    setBrowserRecordingCall({
      id: uuidv4(),
      name: calendarEvent.title,
      status: CallStatus.Recording,
      date: new Date(),
    });
    requestRecordingStart();
    dispatch(actions.fetchCalls(null));

    onClose && onClose();
  };

  const handleRecord = async () => {
    setIsEventAdded(false);
    await joinRecorderToEvent(calendarEvent.id);
    setIsEventAdded(true);

    dispatch(actions.fetchCalls(null));

    if (showSilkchartAssistantPopup) {
      const showSuccessView = getCookie(
        SHOW_SILKCHART_ASSISTANT_SUCCESS_COOKIE,
      );

      if (showSuccessView === 'false') {
        setIsEventAdded(false);
        onClose && onClose();
      }
    } else {
      onClose && onClose();
    }
  };

  const botJoinMeetings = recordingSettingsData?.botJoinMeetings;
  const isActiveRecording = isRecording || isGeneralBotFlowActive;

  if (botJoinMeetings === undefined) {
    return null;
  }

  return (
    <div
      className={cn(
        'flex flex-row justify-between items-center py-4 px-5 mb-1 border-[1px] rounded-[20px]',
        isMeetingInProgress
          ? 'border-accent-blue bg-accent-blue/10'
          : 'border-main-gray',
        className,
      )}
    >
      <div>
        <span className="text-dark tracking-[-0.14px] font-semibold text-sm">
          {calendarEvent.title}
        </span>
        <div className="flex flex-row items-center gap-2">
          {isMeetingInProgress && (
            <>
              <span className="text-accent-blue text-xs font-normal tracking-[-0.12px]">
                Now
              </span>
              <Ellipse />
            </>
          )}
          <div className="flex flex-row items-center">
            <Clock color={'#5C6A82'} size={12} />
            <span className="text-main-gray-dark text-xs font-normal tracking-[-0.12px] ml-1">
              {dayjs(calendarEvent.start).format('h:mma')}
            </span>
          </div>
        </div>
      </div>

      {botJoinMeetings ? (
        isMeetingInProgress ? (
          !calendarEvent.isRecordingEnabled && (
            <RecordButton
              onClick={handleRecord}
              variant="primary"
              isLoading={isJoinRecorderToEventLoading}
            />
          )
        ) : (
          <ToggleMeetRecording calendarEvent={calendarEvent} />
        )
      ) : (
        !isActiveRecording && (
          <RecordButton onClick={handleBrowserRecording} variant="primary" />
        )
      )}
    </div>
  );
};
