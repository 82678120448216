import { API } from '@/api/base';
import { Response } from '@/api/response';
import logger from '@/utils/logger';

import { CallTranscript } from './call-transcript.interface';

const backendURL = process.env.REACT_APP_BACKEND_URL as string;

const endpoints = {
  getCallTranscript: (callId: string, isPublic: boolean) =>
    `${backendURL}/api/calls/${callId}/${isPublic ? 'public/' : ''}transcript`,
};

export const getCallTranscript =
  (callId: string, isPublic: boolean) =>
  async (): Promise<CallTranscript | undefined> => {
    try {
      const response = await API(
        endpoints.getCallTranscript(callId, isPublic),
        {
          method: 'GET',
        },
      );

      const result: Response<CallTranscript> = await response.json();

      return result.data;
    } catch (err) {
      logger.error('Error fetching call transcript', err);
      return undefined;
    }
  };
