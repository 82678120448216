// most code is taken from this example:
// https://codesandbox.io/s/xenodochial-grass-js3bo9?file=/src/App.tsx

import type { Placement } from '@floating-ui/react';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import * as React from 'react';

import cn from '@/utils/cn';

interface UseTooltipOptions {
  initialOpen?: boolean;
  placement?: Placement;
  padding?: number;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const useTooltip = ({
  initialOpen = false,
  placement = 'top',
  padding = 4,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
}: UseTooltipOptions = {}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(padding),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'start',
        padding,
      }),
      shift({ padding }),
    ],
  });

  const context = data.context;

  const transitionStyles = useTransitionStyles(context, { duration: 100 });

  const hover = useHover(context, {
    move: false,
    enabled: !controlledOpen,
    handleClose: safePolygon({
      buffer: padding,
    }),
  });
  const focus = useFocus(context, {
    enabled: !controlledOpen,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      setOpen,
      transitionStyles,
      ...interactions,
      ...data,
    }),
    [open, setOpen, transitionStyles, interactions, data],
  );
};

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = React.createContext<ContextType>(null);

export const useTooltipContext = () => {
  const context = React.useContext(TooltipContext);

  if (context === null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }

  return context;
};

export interface TooltipProps extends UseTooltipOptions {
  children: React.ReactNode;
}

export const Tooltip: React.FC<TooltipProps> & {
  Trigger: typeof TooltipTrigger;
  Content: typeof TooltipContent;
} = ({ children, ...options }: TooltipProps) => {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options);
  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
};

export const TooltipTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & { asChild?: boolean }
>(({ children, asChild = false, ...props }, propRef) => {
  const context = useTooltipContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  const className = cn('outline-offset-1 outline-accent-blue', props.className);

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed',
        className,
      }),
    );
  }

  return (
    <button
      ref={ref}
      // The user can style the trigger based on the state
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
      className={className}
    >
      {children}
    </button>
  );
});

export const TooltipContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
  // eslint-disable-next-line react/prop-types
>(({ style, className, ...props }, propRef) => {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  if (!context.transitionStyles.isMounted) {
    return null;
  }

  return (
    <FloatingPortal>
      <div
        ref={ref}
        style={{
          ...context.floatingStyles,
          ...context.transitionStyles.styles,
          ...style,
        }}
        {...context.getFloatingProps(props)}
        className={cn(
          'z-[100] bg-white px-2.5 py-2 shadow-feedback-filled-words rounded-xl text-center text-dark text-sm',
          className,
        )}
      />
    </FloatingPortal>
  );
});

Tooltip.Trigger = TooltipTrigger;
Tooltip.Content = TooltipContent;
TooltipTrigger.displayName = 'TooltipTrigger';
TooltipContent.displayName = 'TooltipContent';
