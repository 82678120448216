import React from 'react';
import { Settings } from 'react-feather';

import { useRecordingSettingsModalStore } from '@/components/Calendar/RecordingSettings/hooks/useRecordingSettingsModalStore';
import { useUpcomingCallsModalStore } from '@/components/Calendar/UpcomingCalls/hooks/useUpcomingCallsModalStore';
import { UpcomingCallsList } from '@/components/Calendar/UpcomingCalls/UpcomingCallsList';
import { ConnectYourCalendar } from '@/components/Recording/ConnectYourCalendar';
import { useGoogleAuthStatus } from '@/hooks/useGoogleAuthStatus';

export const UpcomingCalls: React.FC = () => {
  const { closeUpcomingCallsModal } = useUpcomingCallsModalStore();
  const { openRecordingSettingsModal } = useRecordingSettingsModalStore();
  const { googleAuthStatusData } = useGoogleAuthStatus();

  const handleSettingsClick = () => {
    closeUpcomingCallsModal();
    openRecordingSettingsModal();
  };

  return (
    <div
      className="w-[487px] z-50 bg-white rounded-[10px] flex flex-col items-center"
      style={{
        boxShadow:
          '0px 1px 6px -2px rgba(24, 39, 75, 0.10), 0px 2px 6px -2px rgba(24, 39, 75, 0.06)',
      }}
    >
      <div className="w-full flex flex-row items-center justify-between pt-5 px-4">
        <span className="font-bold text-[16px] leading-normal font-inter text-dark">
          Upcoming meetings
        </span>
        <Settings
          size={16}
          className="cursor-pointer"
          onClick={handleSettingsClick}
        />
      </div>
      <div className="mt-6 border-t w-full border-t-main-gray"></div>
      {googleAuthStatusData?.isCalendarConnected ? (
        <UpcomingCallsList />
      ) : (
        <ConnectYourCalendar
          isModalView={false}
          showSkipButton={false}
          description={
            'This allows us to automatically record and analyze your meetings'
          }
        />
      )}
    </div>
  );
};
