import { useQuery } from '@tanstack/react-query';

import {
  CallNextStepResponse,
  getCallNextSteps as getCallNextStepsAPI,
} from '@/api/call/get-call-next-steps';

export const useGetCallNextSteps = (callId: string, isPublic: boolean) => {
  const { data: callNextSteps, ...rest } = useQuery<CallNextStepResponse[]>({
    queryFn: getCallNextStepsAPI(callId, isPublic),
    queryKey: ['callNextSteps', callId],
  });

  return { callNextSteps, ...rest };
};
