import React from 'react';
import { HelpCircle, Mic } from 'react-feather';
import { Link } from 'react-router-dom';

import { Button } from '@/components/Button/Button';
import Modal from '@/components/Shared/Modal';
import { CHROME_SUPPORT_MEDIA_DEVICES_URL } from '@/constants';

interface MicrophoneAccessErrorModalProps {
  show: boolean;
  onClose: () => void;
}

export const MicrophoneAccessErrorModal: React.FC<
  MicrophoneAccessErrorModalProps
> = ({ show, onClose }) => (
  <Modal show={show} onClose={onClose}>
    <div className="flex flex-col items-center w-[800px]">
      <Mic size={40} className="mb-4 text-accent-blue" />
      <Modal.Title className="mb-1">
        SilkChart needs microphone access to record calls
      </Modal.Title>
      <Modal.Subtitle className="mb-4">
        Please go to your browser&apos;s settings page to grant microphone
        access to SilkChart
      </Modal.Subtitle>

      <Button className="w-[100px] mb-4" onClick={onClose}>
        Done
      </Button>

      <Link
        to={CHROME_SUPPORT_MEDIA_DEVICES_URL}
        target="__blank"
        className="text-main-gray-dark flex items-center text-sm font-semibold"
      >
        <HelpCircle className="h-4 w-4 mr-1" />
        How do I do this?
      </Link>
    </div>
  </Modal>
);
