import { useQuery } from '@tanstack/react-query';

import { getCallPublicData as getCallPublicDataAPI } from '@/api/call/get-call-public-data';

import { Call } from '../../../interfaces/call.interface';

export const useGetCallPublicData = (callId: string) => {
  const { data: callData, ...rest } = useQuery<Call | undefined>({
    queryFn: getCallPublicDataAPI(callId),
    queryKey: ['publicCall', callId],
  });

  return { callData, ...rest };
};
