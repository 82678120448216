import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import { CallQualitativeFeedbackItemResponse } from '@/api/call/getCallQualitativeFeedback';
import { DemoQualitativeFeedbackItems } from '@/components/CallDetailsPage/DemoCall/DemoCallMockData';
import { useGetCallQualitativeFeedback } from '@/components/CallDetailsPage/Feedback/hooks/useGetCallQualitativeFeedback';
import { useUpdateCallShowQualitativeFeedback } from '@/components/CallDetailsPage/Feedback/hooks/useUpdateCallShowQualitativeFeedback';
import {
  FeedbackLimitType,
  useFeedbackLimitModalStore,
} from '@/components/CallDetailsPage/FeedbackLimitModal/useFeedbackLimitModalStore';
import { ItsSalesCallButton } from '@/components/CallDetailsPage/ItsSalesCallButton';
import { IAStartIcon } from '@/components/CallDetailsPage/Playbook/icons/IsStars';
import { SummaryBlockEmptyState } from '@/components/CallDetailsPage/Summary/SummaryBlockEmptyState';
import { useCallDetailsStore } from '@/components/CallDetailsPage/useCallDetailsStore';
import { CollapseCallSection } from '@/components/CollapseCallSection/CollapseCallSection';
import { CollapsibleItem } from '@/components/CollapsibleItem/CollapsibleItem';
import { OnboardingTooltip } from '@/components/Onboarding/OnboardingTooltip/OnboardingTooltip';
import { FreemiumRestrictionLimitMessage } from '@/components/Shared/Freemium/FreemiumRestrictionLimitMessage';
import { useSubscribeModalStore } from '@/components/subscription/SubscribeModal/useSubscribeModalStore';
import { PerformanceFeedbackItemType } from '@/enums/performanceFeedbackItemTypeEnum';
import { useSubscription } from '@/hooks/useSubscription';
import { Call } from '@/interfaces/call.interface';
import cn from '@/utils/cn';
import { mapSpeakerLetterToNumber } from '@/utils/speakerMapping';

import { Button } from '../../Button/Button';

interface PerformanceFeedbackProps {
  call: Call;
}

const checkSpeakerNameAndMap = (text: string): string => {
  if (!text) {
    return '';
  }
  const pattern = /Speaker\s[A-Za-z]/g;
  const matches = text.match(pattern);

  if (!matches) {
    return text;
  }

  let result = text;

  matches.forEach(match => {
    const speakerLetter = match.split(' ')[1];
    const SpeakerNumber = mapSpeakerLetterToNumber(speakerLetter);
    result = result.replace(match, `Speaker ${SpeakerNumber}`);
  });

  return result;
};

const toSentenceCase = (text: string): string => {
  if (!text) {
    return '';
  }
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

const handleTextFormat = (text: string): string => {
  if (!text) {
    return '';
  }
  let outputText = checkSpeakerNameAndMap(text);
  outputText = toSentenceCase(outputText);
  return outputText;
};

const PerformanceFeedback: React.FC<PerformanceFeedbackProps> = ({
  call,
}: PerformanceFeedbackProps) => {
  const { id: routeId } = useParams<{ id: string }>();
  const callId = call.id || (routeId as string);

  const { openFeedbackLimitModal } = useFeedbackLimitModalStore();
  const { setTabIndex } = useCallDetailsStore();
  const navigate = useNavigate();

  const [showAnimation, setShowAnimation] = useState(false);
  const [didWellItems, setDidWellItems] = useState<
    CallQualitativeFeedbackItemResponse[]
  >([]);
  const [improvementsItems, setImprovementsItems] = useState<
    CallQualitativeFeedbackItemResponse[]
  >([]);

  const { callQualitativeFeedback, isLoading } =
    useGetCallQualitativeFeedback(callId);
  const { updateCallShowQualitativeFeedback, isPending } =
    useUpdateCallShowQualitativeFeedback(callId);

  const qualitativeDataItems: CallQualitativeFeedbackItemResponse[] =
    call.isDemoCall
      ? DemoQualitativeFeedbackItems
      : callQualitativeFeedback || [];

  const animateFeedbackItems = (
    items: CallQualitativeFeedbackItemResponse[],
    setItems: React.Dispatch<
      React.SetStateAction<CallQualitativeFeedbackItemResponse[]>
    >,
    callback?: () => void,
  ) => {
    let currentIndex = 0;
    const feedbackTexts: CallQualitativeFeedbackItemResponse[] = [];

    if (items.length > 0) {
      feedbackTexts.push(items[0]);
      setItems([...feedbackTexts]);
      currentIndex = 1;
    }

    const interval = setInterval(
      () => {
        if (currentIndex < items.length) {
          feedbackTexts.push(items[currentIndex]);
          setItems([...feedbackTexts]);

          currentIndex += 1;
        } else {
          clearInterval(interval);
          if (callback) {
            callback();
          }
        }
      },
      call.isDemoCall ? 0 : 2200,
    );
  };

  const handleGetFeedbackClick = async () => {
    setShowAnimation(true);

    if (
      availableSalesCoachAnalysisCallsAmount !== null &&
      availableSalesCoachAnalysisCallsAmount !== undefined &&
      availableSalesCoachAnalysisCallsAmount <= 0
    ) {
      openFeedbackLimitModal(FeedbackLimitType.SALES_COACH);
      return;
    }

    await updateCallShowQualitativeFeedback();
  };

  useEffect(() => {
    if (!qualitativeDataItems?.length) {
      return;
    }

    const didWellItems: CallQualitativeFeedbackItemResponse[] = [];
    const needImprovementsItems: CallQualitativeFeedbackItemResponse[] = [];

    qualitativeDataItems.forEach((el: CallQualitativeFeedbackItemResponse) => {
      switch (el.type) {
        case PerformanceFeedbackItemType.Positive:
          didWellItems.push(el);
          break;
        case PerformanceFeedbackItemType.Negative:
          needImprovementsItems.push(el);
          break;
      }
    });

    if (showAnimation) {
      animateFeedbackItems(didWellItems, setDidWellItems, () => {
        animateFeedbackItems(
          needImprovementsItems,
          setImprovementsItems,
          () => {
            setShowAnimation(false);
          },
        );
      });
    } else {
      setDidWellItems(didWellItems);
      setImprovementsItems(needImprovementsItems);
    }
  }, [qualitativeDataItems]);

  const {
    availableSalesCoachAnalysisCallsAmount,
    callsSalesCoachAnalysisLimit,
    isFree,
    openStripeCustomerPortal,
  } = useSubscription();
  const { openSubscribeModal } = useSubscribeModalStore();

  const handleUpgradeClick = () => {
    if (isFree) {
      openSubscribeModal();
      return;
    } else {
      openStripeCustomerPortal();
    }
  };

  let leftLessThanHalfLimit;
  if (
    callsSalesCoachAnalysisLimit &&
    availableSalesCoachAnalysisCallsAmount !== null &&
    availableSalesCoachAnalysisCallsAmount !== undefined &&
    availableSalesCoachAnalysisCallsAmount >= 0
  ) {
    leftLessThanHalfLimit =
      availableSalesCoachAnalysisCallsAmount <=
      callsSalesCoachAnalysisLimit / 2;
  }

  const noFeedback =
    callQualitativeFeedback && callQualitativeFeedback.length === 0;

  const showSubscriptionLimitation =
    availableSalesCoachAnalysisCallsAmount !== null &&
    availableSalesCoachAnalysisCallsAmount !== undefined &&
    callsSalesCoachAnalysisLimit &&
    leftLessThanHalfLimit &&
    noFeedback;

  const isSalesCall =
    call.isSalesCall ||
    call.isSalesCall === null ||
    call.isSalesCall === undefined;

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <ClipLoader color="rgb(37 99 235)" />
      </div>
    );
  }

  return (
    <>
      <span id="ai-sales-coach-feedback-title" />
      <CollapseCallSection
        titleStyles="w-full"
        title={
          <div className="flex flex-row justify-between w-full items-center">
            <span>AI Sales Coach feedback</span>
            <OnboardingTooltip
              placement="right-start"
              tooltipId="feedback"
              beforeShowTooltip={() => {
                setTabIndex(2);

                //Scroll to #ai-sales-coach-feedback-title
                const element = document.getElementById(
                  'ai-sales-coach-feedback-title',
                );

                setTimeout(() => {
                  if (element) {
                    //Scroll 100px before the element
                    element.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    });
                  }
                }, 100);
              }}
              onActionButtonClick={() => navigate('/insights')}
            />
          </div>
        }
        hideDefaultChevron
        itemsContainerStyles={!isSalesCall ? 'pt-0' : 'pt-1.5'}
        triggerDisabled
        openByDefault
        icon={<IAStartIcon width={20} height={20} color="#5C6A82" />}
        bottomElement={
          showSubscriptionLimitation && (
            <FreemiumRestrictionLimitMessage
              className="rounded-b-[16px] mt-4"
              availableLimit={availableSalesCoachAnalysisCallsAmount}
              itemsLimit={callsSalesCoachAnalysisLimit}
              itemsUsed={
                callsSalesCoachAnalysisLimit -
                availableSalesCoachAnalysisCallsAmount
              }
              handleUpgradeClick={handleUpgradeClick}
              limitType={FeedbackLimitType.SALES_COACH}
            />
          )
        }
      >
        {isSalesCall ? (
          <div>
            <div>
              <div className={cn(isSalesCall ? 'mt-1.5' : 'mt-2.5')}>
                {noFeedback && !call.isDemoCall && (
                  <Button
                    isLoading={isPending}
                    onClick={() => handleGetFeedbackClick()}
                    className="py-2 px-4 bg-blue-500 text-white rounded-[10px] mb-4"
                  >
                    Get feedback
                  </Button>
                )}

                <div>
                  <div className="feedback-card p-3 bg-white rounded-md">
                    <h3 className="text-sm font-semibold">
                      👍 What you did well
                    </h3>
                    <ul className="mt-2 flex flex-col gap-1.5">
                      {noFeedback && didWellItems.length === 0 && (
                        <span className="text-xs text-[#5C6A82]">
                          {`Select 'Get feedback' above to see what went well.`}
                        </span>
                      )}
                      {didWellItems.map((item, index) => (
                        <li key={index}>
                          <CollapsibleItem
                            title={handleTextFormat(item?.title)}
                            openByDefault={showAnimation && !call.isDemoCall}
                            animation={showAnimation && !call.isDemoCall}
                          >
                            {checkSpeakerNameAndMap(item?.description)}
                          </CollapsibleItem>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="feedback-card p-3 bg-white rounded-md mt-4">
                    <h3 className="text-sm font-semibold">
                      ⚡️ Where you can improve
                    </h3>
                    <ul className="mt-2 flex flex-col gap-1.5">
                      {noFeedback && improvementsItems.length === 0 && (
                        <span className="text-xs text-[#5C6A82]">
                          {`Select 'Get feedback' above to see improvement suggestions.`}
                        </span>
                      )}
                      {improvementsItems.map((item, index) => (
                        <li key={index}>
                          <CollapsibleItem
                            title={handleTextFormat(item?.title)}
                            openByDefault={showAnimation && !call.isDemoCall}
                            animation={showAnimation && !call.isDemoCall}
                          >
                            {checkSpeakerNameAndMap(item?.description)}
                          </CollapsibleItem>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <SummaryBlockEmptyState
              title="Sounds like this is not a sales call"
              description="We currently only provide feedback for sales calls"
            />
            {call.isValid && (
              <ItsSalesCallButton
                callId={call.id}
                containerStyles="mt-3"
                limitType={FeedbackLimitType.SALES_COACH}
              />
            )}
          </>
        )}
      </CollapseCallSection>
    </>
  );
};

export default PerformanceFeedback;
