import { Spinner } from 'flowbite-react';
import React from 'react';

import {
  AddAssistantSuccess,
  SHOW_SILKCHART_ASSISTANT_SUCCESS_COOKIE,
} from '@/components/Recording/AddAssistantSuccess';
import { AddSilkchartAssistant } from '@/components/Recording/AddSilkchartAssistant';
import { CalendarSuccessfullyConnected } from '@/components/Recording/CalendarSuccessfullyConnected';
import { ConnectYourCalendar } from '@/components/Recording/ConnectYourCalendar';
import { useRecordingCalendarStore } from '@/components/Recording/hooks/useRecordingCalendarStore';
import { UpcomingEvents } from '@/components/Recording/UpcomingEvents';
import { useGoogleAuthStatus } from '@/hooks/useGoogleAuthStatus';
import { getCookie } from '@/utils/cookie';

interface CalendarFlowModalProps {
  onClose: () => void;
}

export const CalendarFlowWrapper: React.FC<CalendarFlowModalProps> = ({
  onClose,
}) => {
  const {
    isEventAdded,
    showCalendarConnectedScreen,
    isCalendarConnectionSkipped,
  } = useRecordingCalendarStore();

  const showSuccessView =
    getCookie(SHOW_SILKCHART_ASSISTANT_SUCCESS_COOKIE) || 'true';

  const { googleAuthStatusData } = useGoogleAuthStatus();

  const isLoading =
    googleAuthStatusData?.isCalendarConnected === null &&
    googleAuthStatusData?.areCredentialsValid === null;

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-w-[692px] min-h-[129px]">
        <Spinner className="fill-accent-blue h-16 w-16" />
      </div>
    );
  }

  if (
    !googleAuthStatusData?.isCalendarConnected &&
    !isCalendarConnectionSkipped
  ) {
    return (
      <ConnectYourCalendar
        description={
          'Adding a calendar allows us to automatically import your meetings to SilkChart and record them'
        }
      />
    );
  }

  if (isEventAdded && showSuccessView === 'true') {
    return <AddAssistantSuccess onClose={onClose} />;
  }

  if (
    googleAuthStatusData?.areCredentialsValid &&
    showCalendarConnectedScreen
  ) {
    return <CalendarSuccessfullyConnected />;
  }

  if (
    googleAuthStatusData?.areCredentialsValid &&
    !showCalendarConnectedScreen
  ) {
    return <UpcomingEvents onClose={onClose} />;
  }

  if (
    !googleAuthStatusData?.isCalendarConnected &&
    isCalendarConnectionSkipped
  ) {
    return <AddSilkchartAssistant onClose={onClose} />;
  }

  return null;
};
