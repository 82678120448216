import React, { useEffect } from 'react';
import { Calendar, X } from 'react-feather';

import { BlockOption } from '@/components/BlockOption/BlockOption';
import { Button } from '@/components/Button/Button';
import { RadioButton } from '@/components/Button/RadioButton';
import { useUpdateRecordingSettings } from '@/components/Calendar/RecordingSettings/hooks/useUpdateRecordingSettings';
import { RecordingOption } from '@/components/Calendar/RecordingSettings/RecordingOption';
import { ProgressBar } from '@/components/ProgressBar/ProgressBar';
import { useQuickstartModalStore } from '@/components/Quickstart/hooks/useQuickstartModalStore';
import { useQuickstartStore } from '@/components/Quickstart/hooks/useQuickstartStore';
import { QUICKSTART_TOTAL_ITEMS } from '@/components/Quickstart/QuickstartButton';
import { QuickstartMenuItem } from '@/components/Quickstart/QuickstartMenuItem';
import { QuickstartSuccess } from '@/components/Quickstart/QuickstartSuccess';
import { useGoogleLoginWrapper } from '@/components/Recording/hooks/useGoogleLoginWrapper';
import { useRecordingCalendarStore } from '@/components/Recording/hooks/useRecordingCalendarStore';
import { RecordButton } from '@/components/Shared/RecordButton';
import { UploadButton } from '@/components/Shared/UploadButton';
import { useRecordingSettings } from '@/hooks/useRecordingSettings';

const Divider: React.FC = () => (
  <div className="bg-main-gray h-[1px] w-full my-5"></div>
);

export interface QuickstartMenuProps {
  isCalendarConnected: boolean;
  isMeetingRecordingOptionSaved: boolean;
  hasCalls: boolean;
}

export const QuickstartMenu: React.FC<QuickstartMenuProps> = ({
  isCalendarConnected,
  isMeetingRecordingOptionSaved,
  hasCalls,
}) => {
  const [openIndexes, setOpenIndexes] = React.useState<{
    [key in number]: string;
  }>({});

  const [selectedRecordingOption, setSelectedRecordingOption] =
    React.useState<RecordingOption>(RecordingOption.JOIN);

  const { totalCompleted } = useQuickstartStore();
  const { isLoading } = useRecordingCalendarStore();
  const { closeQuickstartModal } = useQuickstartModalStore();

  const { recordingSettingsData } = useRecordingSettings();
  const { googleLogin } = useGoogleLoginWrapper();
  const { updateRecordingSettings, isPending: updateRecordingSettingsPending } =
    useUpdateRecordingSettings();

  useEffect(() => {
    if (!recordingSettingsData) return;

    setSelectedRecordingOption(
      recordingSettingsData.botJoinMeetings
        ? RecordingOption.JOIN
        : RecordingOption.DONT_JOIN,
    );
  }, [recordingSettingsData]);

  const changeRecordingOption = async (option: RecordingOption) => {
    if (option === selectedRecordingOption) return;

    setSelectedRecordingOption(option);
  };

  if (!recordingSettingsData) return null;

  const applyRecordingOption = async () => {
    try {
      await updateRecordingSettings({
        botJoinMeetings: true,
        botAutoJoinAllMeetings:
          selectedRecordingOption === RecordingOption.JOIN,
      });
    } catch (error) {
      console.error('Failed to update recording settings', error);
    }
  };

  return (
    <div
      className="bg-white border border-1 border-[#89ADFA] rounded-[10px]"
      style={{
        boxShadow:
          '0px 1px 32px -2px rgba(44, 108, 246, 0.16), 0px 2px 24px -2px rgba(44, 108, 246, 0.08)',
      }}
    >
      <div className="pb-5 bg-[#2c6cf614] px-4 pt-5">
        <div className="flex flex-row justify-between items-center">
          <span className="text-base font-bold text-dark">
            Get started with SilkChart
          </span>
          <X
            size={16}
            className="text-main-gray-dark cursor-pointer"
            onClick={closeQuickstartModal}
          />
        </div>
        <div className="h-1.5 mt-4">
          <ProgressBar
            sections={QUICKSTART_TOTAL_ITEMS}
            sectionsCompleted={totalCompleted}
          />
        </div>
      </div>
      {totalCompleted === QUICKSTART_TOTAL_ITEMS ? (
        <QuickstartSuccess />
      ) : (
        <div className="px-5 pt-5">
          <QuickstartMenuItem
            isStepCompleted={isCalendarConnected || false}
            itemIndex={0}
            openIndexes={openIndexes}
            setOpenIndexes={setOpenIndexes}
            title={'Connect your Calendar'}
          >
            <Button
              className="w-[208px] px-3 py-2.5 flex items-center justify-center mt-4"
              variant="primary"
              onClick={googleLogin}
              isLoading={isLoading}
              loadingText="Syncing your calendar..."
            >
              <Calendar color={'#FFFFFF'} size={16} />
              <span className="text-white text-[13px] font-semibold">
                Connect Google Calendar
              </span>
            </Button>
          </QuickstartMenuItem>
          <Divider />
          <QuickstartMenuItem
            isStepCompleted={isMeetingRecordingOptionSaved || false}
            itemIndex={1}
            openIndexes={openIndexes}
            setOpenIndexes={setOpenIndexes}
            title={'Which meetings do you want to record?'}
          >
            <div>
              <div
                className="mt-4"
                onClick={() => changeRecordingOption(RecordingOption.JOIN)}
              >
                <BlockOption
                  title="Auto-join all meetings"
                  className="flex flex-row items-center"
                  leftSlotWrapperClassName="flex items-center justify-center w-5 h-5 mr-2"
                  leftSlot={
                    <RadioButton
                      className="mr-0"
                      selected={
                        selectedRecordingOption === RecordingOption.JOIN
                      }
                    />
                  }
                />
              </div>
              <div
                className="mt-1"
                onClick={() => changeRecordingOption(RecordingOption.DONT_JOIN)}
              >
                <BlockOption
                  title="Only meetings I manually select"
                  className="flex flex-row items-center"
                  leftSlotWrapperClassName="flex items-center justify-center w-5 h-5 mr-2"
                  leftSlot={
                    <RadioButton
                      className="mr-0"
                      selected={
                        selectedRecordingOption === RecordingOption.DONT_JOIN
                      }
                    />
                  }
                />
              </div>
              <Button
                className="mt-4"
                variant="primary"
                onClick={applyRecordingOption}
                isLoading={updateRecordingSettingsPending}
              >
                Save
              </Button>
            </div>
          </QuickstartMenuItem>
          <Divider />
          <div className="flex flex-col pb-5">
            <QuickstartMenuItem
              isStepCompleted={hasCalls}
              itemIndex={2}
              openIndexes={openIndexes}
              setOpenIndexes={setOpenIndexes}
              title={'Record or Upload your first call'}
            >
              <div className="flex flex-row items-center mt-4">
                <UploadButton />
                <RecordButton variant="secondary" className="ml-1" />
              </div>
            </QuickstartMenuItem>
          </div>
        </div>
      )}
    </div>
  );
};
