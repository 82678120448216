/* eslint-disable no-console */
import pino from 'pino';

declare module 'pino' {
  interface BrowserOptions {
    asObject?: boolean;
    write?:
      | WriteFn
      | ({
          fatal?: WriteFn;
          error?: WriteFn;
          warn?: WriteFn;
          info?: WriteFn;
          debug?: WriteFn;
          trace?: WriteFn;
        } & { [logLevel: string]: WriteFn });
    serialize?: boolean | string[];
    transmit?: {
      level?: LevelOrString;
      send: (level: Level, logEvent: LogEvent) => void;
    };

    disabled?: boolean;
    formatters?: {
      /**
       * Changes the shape of the log level.
       * The default shape is { level: number }.
       */
      level?: (label: string, number: number) => object;
      /**
       * Changes the shape of the log object.
       */
      log?: (object: Record<string, unknown>) => Record<string, unknown>;
    };
  }

  interface LoggerOptions {
    browser?: BrowserOptions;
  }
}

/**
 * Get the log level from the environment or localStorage
 */
function getLogLevel() {
  if (typeof window === 'undefined') {
    return process.env.REACT_LOG_LEVEL ?? 'debug';
  }

  // On the client side, use localStorage
  return localStorage.getItem('logLevel') ?? 'debug';
}

const isProduction = process.env.NODE_ENV === 'production';
const logLevel = isProduction ? 'silent' : getLogLevel();

type PinoLogParams = {
  label: string;
  msg?: string | undefined;
};

const messageStyle = 'color: #788894;';

const levelStyles = {
  fatal:
    'color: #ff6b6b; font-weight: regular; border: 1px solid #ff6b6b; padding: 2px 4px 1px 4px;',
  error:
    'color: #fa8072; font-weight: regular; border: 1px solid #fa8072; padding: 2px 4px 1px 4px;',
  warn: 'color: #ffd700; font-weight: regular; border: 1px solid #ffd700; padding: 2px 4px 1px 4px;',
  info: 'color: #7bdd4e; font-weight: regular; border: 1px solid #7bdd4e; padding: 2px 4px 1px 4px;',
  debug:
    'color: #4fc3f7; font-weight: regular; border: 1px solid #4fc3f7; padding: 2px 4px 1px 4px;',
  trace:
    'color: #9c27b0; font-weight: regular; border: 1px solid #9c27b0; padding: 2px 4px 1px 4px;',
};

const formatLog = (o: object) => {
  const { label, msg, ...obj } = o as PinoLogParams;

  const levelStyle =
    levelStyles[label.toLowerCase() as keyof typeof levelStyles];

  const formattedLog = [
    `%c${label}${msg ? `%c ${msg}` : ''}`,
    levelStyle,
    messageStyle,
  ];

  return obj && Object.keys(obj).length ? [...formattedLog, obj] : formattedLog;
};

const logger = pino({
  level: logLevel,
  browser: {
    formatters: {
      level(label, number) {
        return { label: label.toUpperCase(), number };
      },
    },
    asObject: true,
    write: {
      // error: (o) => console.error(...formatLog(o)),
      // warn: o => console.warn(...formatLog(o)),
      info: o => console.info(...formatLog(o)),
      debug: o => {
        console.debug(...formatLog(o));
      },
      trace: o => console.trace(...formatLog(o)),
    },
  },
});

if (!isProduction && typeof window !== 'undefined') {
  window.setLogLevel = (level: string) => {
    localStorage.setItem('logLevel', level);
    logger.level = level;
  };
}

logger.info({ logLevel }, 'Logger initialized');
export default logger;
