import React from 'react';

import { AddNewCalendarEvent } from '@/components/Recording/AddNewCalendarEvent';
import { AddSilkchartAssistantHeader } from '@/components/Recording/AddSilkchartAssistantHeader';
import { ConnectCalendarTooltip } from '@/components/Recording/ConnectCalendarTooltip';
import { SyncingCalendarLoader } from '@/components/Recording/SyncingCalendarLoader';
import { useGoogleAuthStatus } from '@/hooks/useGoogleAuthStatus';

interface AddSilkchartAssistantProps {
  onClose: () => void;
}

export const AddSilkchartAssistant: React.FC<AddSilkchartAssistantProps> = ({
  onClose,
}) => {
  const { googleAuthStatusData, isLoading } = useGoogleAuthStatus();

  if (isLoading) {
    return <SyncingCalendarLoader />;
  }

  return (
    <div className="pt-6 w-[640px]">
      <AddSilkchartAssistantHeader containerClassName="px-6 pb-6" />
      <AddNewCalendarEvent onClose={onClose} containerClassName="p-6" />
      {!googleAuthStatusData?.isCalendarConnected && <ConnectCalendarTooltip />}
    </div>
  );
};
