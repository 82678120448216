import { API } from '@/api/base';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const endpoints = {
  getRecordingSettings: backendURL + '/api/calendar/settings',
};

export interface RecordingSettingsResponse {
  id: string;
  botJoinMeetings: boolean;
  botAutoJoinAllMeetings: boolean;
  isMeetingRecordingOptionSaved: boolean;
  googleCalendarEmail?: string;
}

export const getRecordingSettings =
  async (): Promise<RecordingSettingsResponse> => {
    const response = await API(endpoints.getRecordingSettings, {
      method: 'GET',
    });

    return response.json();
  };
