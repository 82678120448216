import { create } from 'zustand';

import { Call } from '../../../interfaces/call.interface';

interface ShareCallModalStore {
  isShareCallModalOpen: boolean;
  call: Call | undefined;
  openShareCallModal: (call: Call) => Promise<void>;
  closeShareCallModal: () => void;
}

export const useShareCallModalStore = create<ShareCallModalStore>(set => ({
  isShareCallModalOpen: false,
  call: undefined,
  shareLink: undefined,
  openShareCallModal: async call => {
    set(() => ({
      isShareCallModalOpen: true,
      call,
    }));
  },
  closeShareCallModal: () =>
    set(() => ({ isShareCallModalOpen: false, call: undefined })),
  getSharingLink: () => {},
}));
