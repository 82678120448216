// Component for displaying filler words list on hover

const FillerWordsList = ({ fillerWords }) => {
  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <ul className="flex gap-1 items-center">
      {Object.keys(fillerWords).map((word, index) => {
        return (
          <li
            key={index}
            className="px-[6px] py-1 bg-[#e7402929] rounded-[8px] text-xs font-medium text-[#E74029] whitespace-nowrap"
          >
            <span>{capitalizeFirstLetter(word)} - </span>
            <span>{fillerWords[word]}</span>
          </li>
        );
      })}
    </ul>
  );
};

export default FillerWordsList;
