import { API } from '@/api/base';
import { Response } from '@/api/response';

const backendURL = process.env.REACT_APP_BACKEND_URL as string;

const endpoints = {
  getCalendarEvents: (query?: string) =>
    backendURL +
    (query ? `/api/calendar/events?${query}` : '/api/calendar/events'),
};

export interface CalendarEventRequest {
  days?: number;
}

export interface CalendarEventResponse {
  id: string;
  isRecordingEnabled: boolean;
  title: string;
  start: string; // Date
  end: string; // Date
  callId: string | null;
}

export const getCalendarEvents =
  (request?: CalendarEventRequest) =>
  async (): Promise<CalendarEventResponse[]> => {
    let query;

    if (request?.days) {
      query = `days=${request.days}`;
    }

    const response = await API(endpoints.getCalendarEvents(query), {
      method: 'GET',
    });

    const result: Response<CalendarEventResponse[]> = await response.json();

    return result.data;
  };
