import { useMutation } from '@tanstack/react-query';

import { quickstartDismiss as quickstartDismissAPI } from '@/api/user/quickstartDismiss';
import { useQuickstartModalStore } from '@/components/Quickstart/hooks/useQuickstartModalStore';

export const useQuickstartDismiss = () => {
  const { hideQuickstart } = useQuickstartModalStore();

  const { mutateAsync: quickstartDismiss, ...rest } = useMutation({
    mutationFn: quickstartDismissAPI,
    onSuccess: () => {
      hideQuickstart();
    },
  });

  return { quickstartDismiss, ...rest };
};
