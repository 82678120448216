import React from 'react';

import { CallSummaryItemResponse } from '@/api/call/get-call-summary';
import { useNavigateToTranscript } from '@/hooks/useNavigateToTranscript';
import { formatTimestamp } from '@/utils/formatDate';

export interface CallSummaryItemProps {
  summary: Pick<CallSummaryItemResponse, 'callId' | 'text' | 'timestamp'>;
  baseUrl?: string;
}

export const CallSummaryItem: React.FC<CallSummaryItemProps> = ({
  summary,
  baseUrl = 'calls',
}) => {
  const { navigateToTranscript } = useNavigateToTranscript(baseUrl);

  const handleTimestampClick = () => {
    navigateToTranscript({
      callId: summary.callId,
      timestamp: Number(summary.timestamp),
    });
  };

  return (
    <div className="w-full bg-white rounded-[12px] border border-main-gray py-2.5 px-4 flex flex-row items-start">
      <div className="w-[5px] h-[5px] bg-accent-blue rounded-full mr-2 mt-2"></div>
      <div className="flex flex-row items-center justify-between w-full">
        <span className="text-dark text-sm tracking-[-0.14px] font-normal text-left">
          {summary.text}
        </span>
        {summary.timestamp && (
          <span
            className="text-main-gray-dark text-[10px] tracking-[-0.1px] ml-2 cursor-pointer font-normal hover:text-accent-blue"
            onClick={handleTimestampClick}
          >
            {formatTimestamp(summary.timestamp)}
          </span>
        )}
      </div>
    </div>
  );
};
