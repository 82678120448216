import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const formatWeekdayDayMonth = date => {
  return dayjs(date).format('ddd DD MMM');
};

const formatDateHeader = date => {
  const today = dayjs().format('YYYY-MM-DD');
  const yesterday = dayjs().subtract(1, 'days').format('YYYY-MM-DD');
  const callDate = dayjs(date).format('YYYY-MM-DD');
  if (callDate === today) {
    return `Today, ${dayjs(date).format('MMMM DD')}`;
  } else if (callDate === yesterday) {
    return `Yesterday, ${dayjs(date).format('MMMM DD')}`;
  } else {
    return dayjs(date).format('dddd, MMMM DD');
  }
};

export const formatDuration = seconds => {
  const duration = dayjs.duration(seconds, 'seconds');
  const hours = Math.floor(duration.asHours());
  const minutesRemainder = duration.minutes();
  const formattedDuration = `${hours}h ${minutesRemainder}min`;
  return formattedDuration;
};

export const formatDurationMin = (seconds, onlyNumber = false) => {
  const duration = dayjs.duration(seconds, 'seconds');
  const minutes = duration.minutes();

  if (onlyNumber) {
    return minutes;
  } else {
    return `${minutes} min`;
  }
};

export const formatDurationHMS = millisseconds => {
  const duration = dayjs.duration(millisseconds, 'ms');
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  const gte1H = hours > 0;
  const gte1Min = hours > 0 || minutes > 0;
  return [gte1H && `${hours}h`, gte1Min && `${minutes}min`, `${seconds}sec`]
    .filter(Boolean)
    .join(' ');
};

export const formatDurationHourMinute = millisseconds => {
  const duration = dayjs.duration(millisseconds, 'ms');
  const hours = duration.hours();
  const minutes = duration.minutes();

  const gte1H = hours > 0;
  const gte1Min = hours > 0 || minutes > 0;
  return [gte1H && `${hours}h`, gte1Min && `${minutes}min`]
    .filter(Boolean)
    .join(' ');
};

export const formatDurationMinSecond = seconds => {
  const duration = dayjs.duration(seconds, 'seconds');
  const minutes = duration.minutes();
  const secondsRemainder = duration.seconds();
  if (minutes === 0) return `${secondsRemainder}sec`;
  const formattedDuration = `${minutes}min ${secondsRemainder}sec`;
  return formattedDuration;
};

export const formatTimestamp = timestamp => {
  const duration = dayjs.duration(timestamp / 1000, 'seconds');
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (hours > 0) {
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};
export default formatDateHeader;

export const formatTimeWithHoursWithoutDescription = time => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);
  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export const formatTimeWithoutHoursWithoutDescription = time => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};
