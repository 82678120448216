import { API } from '@/api/base';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const endpoints = {
  updateRecordingSettings: backendURL + '/api/calendar/settings',
};

export interface UpdateSettingsResponse {
  botJoinMeetings: boolean;
  botAutoJoinAllMeetings: boolean;
  isMeetingRecordingOptionSaved: boolean;
}

export interface UpdateSettingsRequest {
  botJoinMeetings: boolean;
  botAutoJoinAllMeetings: boolean;
}

export const updateRecordingSettings = async ({
  botJoinMeetings,
  botAutoJoinAllMeetings,
}: UpdateSettingsRequest): Promise<UpdateSettingsResponse> => {
  const response = await API(endpoints.updateRecordingSettings, {
    method: 'PATCH',
    body: JSON.stringify({
      botJoinMeetings,
      botAutoJoinAllMeetings,
    }),
  });

  return response.json();
};
