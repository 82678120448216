import React from 'react';

import cn from '@/utils/cn';

import { LoaderIcon } from '../Shared/icons/LoaderIcon';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | 'primary'
    | 'secondary'
    | 'link'
    | 'danger'
    | 'ghost'
    | 'secondary-inverted'
    | 'primary-inverted';
  isLoading?: boolean;
  contentWrapperClassName?: string;
  loadingText?: string;
}

export const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  children,
  className,
  contentWrapperClassName,
  loadingText,
  disabled,
  isLoading,
  type = 'button',
  ...restProps
}) => (
  <button
    type={type}
    disabled={disabled || isLoading}
    className={cn(
      'h-9 px-4 py-2 rounded-lg transition-all duration-300 font-semibold text-sm text-center relative',
      {
        'text-white bg-accent-blue hover:bg-accent-dark-blue':
          variant === 'primary',
        'text-dark bg-light-gray hover:bg-main-gray': variant === 'secondary',
        'w-fit h-fit px-1 py-0.5 text-accent-blue hover:text-accent-dark-blue duration-75':
          variant === 'link',
        'bg-accent-red text-white hover:bg-accent-dark-red':
          variant === 'danger',
        'text-main-gray-dark bg-transparent hover:bg-light-gray active:bg-main-gray':
          variant === 'ghost',
        'text-dark bg-white hover:bg-[#F6F8FA] border-main-gray border':
          variant === 'secondary-inverted',
        'bg-white hover:bg-[#F6F8FA] text-accent-blue border-main-gray border':
          variant === 'primary-inverted',
        'bg-[#EDEDED] hover:bg-[#EDEDED] text-main-gray-dark text-opacity-40':
          disabled,
      },
      className,
    )}
    {...restProps}
  >
    <div
      className={cn(
        'inline-flex w-full h-full justify-center items-center transition-all gap-1',
        // eslint-disable-next-line no-nested-ternary
        isLoading ? (loadingText ? 'hidden' : 'invisible') : 'visible',
        contentWrapperClassName,
      )}
    >
      {children}
    </div>
    {isLoading && (
      <div className={loadingText && 'bg-red flex items-row items-center'}>
        <div
          className={
            loadingText ? '' : 'absolute w-4 h-4 top-1/2 left-1/2 -ml-2 -mt-2'
          }
        >
          <LoaderIcon className="animate-spin" />
        </div>
        {loadingText && (
          <span className="ml-1 text-white text-[13px] font-semibold">
            {loadingText}
          </span>
        )}
      </div>
    )}
  </button>
);
