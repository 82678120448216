import React from 'react';

import { CallNextSteps } from '@/components/CallDetailsPage/Summary/CallNextSteps';
import { CallSummary } from '@/components/CallDetailsPage/Summary/CallSummary';
import { Questions } from '@/components/CallDetailsPage/Summary/Questions';

export interface CallSummaryDetailsProps {
  callId: string;
}

export const CallSummaryDetails: React.FC<CallSummaryDetailsProps> = ({
  callId,
}) => (
  <div className="flex flex-col gap-4">
    <CallSummary callId={callId} />
    <CallNextSteps callId={callId} />
    <Questions callId={callId} />
  </div>
);
