import { PossibleMetrics } from '@/components/Shared/helpers/BasicMetricsEvaluationHelper';

import { formatDurationMinSecond } from './formatDate';

const COLORS = {
  RED: 'accent-red',
  YELLOW: 'accent-yellow',
  GREEN: 'accent-green',
};

const highlightText = {
  GREEN: 'Great!',
  YELLOW: 'Okay',
  RED: 'Opportunity',
};

const SPEECH_RATE = {
  LOW: 110,
  MEDIUM: 170,
  HIGH: 200,
};

const TALK_RATIO = {
  MEDIUM: 40,
  HIGH: 60,
};

const MONOLOGUE_DURATION = {
  LOW: 30,
  HIGH: 60,
};

const QUESTIONS_ASKED = {
  LOW: 12,
  HIGH: 18,
};

const JARGON = {
  LOW: 5,
  HIGH: 20,
};

// Attribute calculation selector function for realtime
// Chooses which calculation to use as a function of attribute
export const calculateMetricAttributeDisplay = (attribute, value) => {
  if (attribute === PossibleMetrics.MainSpeakerWordsPerMinute) {
    return caculateSpeechRateDisplay(value);
  } else if (attribute === PossibleMetrics.MainSpeakerTalkPercentage) {
    return caculateTalkRatioDisplay(value);
  } else if (attribute === PossibleMetrics.LongestMonologueDurationSeconds) {
    return caculateMonologueDisplay(value);
  } else if (attribute === PossibleMetrics.QuestionsAsked) {
    return caculateQuestionsAskedDisplay(value);
  } else if (attribute === PossibleMetrics.FillerWordsCount) {
    return caculateJargonDisplay(value);
  }
};

// Realtime attribute calculation for speech rate
const caculateSpeechRateDisplay = speechRate => {
  if (speechRate <= SPEECH_RATE.LOW) {
    return {
      displayAttributeName: 'Speech pace',
      displayAttributeDetails: `${Math.round(speechRate)} words per minute`,
      message: 'A bit slow',
      color: COLORS.YELLOW,
      highlightText: highlightText.YELLOW,
    };
  } else if (
    speechRate >= SPEECH_RATE.LOW &&
    speechRate <= SPEECH_RATE.MEDIUM
  ) {
    return {
      displayAttributeName: 'Speech pace',
      displayAttributeDetails: `${Math.round(speechRate)} words per minute`,
      message: 'Good pace',
      color: COLORS.GREEN,
      highlightText: highlightText.GREEN,
    };
  } else if (
    speechRate >= SPEECH_RATE.MEDIUM &&
    speechRate <= SPEECH_RATE.HIGH
  ) {
    return {
      displayAttributeName: 'Speech pace',
      displayAttributeDetails: `${Math.round(speechRate)} words per minute`,
      message: 'A bit fast',
      color: COLORS.YELLOW,
      highlightText: highlightText.YELLOW,
    };
  } else if (speechRate >= SPEECH_RATE.HIGH) {
    return {
      displayAttributeName: 'Speech pace',
      displayAttributeDetails: `${Math.round(speechRate)} words per minute`,
      message: 'Slow down',
      color: COLORS.RED,
      highlightText: highlightText.RED,
    };
  }
};

// Realtime attribute calculation for talk ratio
const caculateTalkRatioDisplay = talkRatio => {
  if (talkRatio > TALK_RATIO.HIGH) {
    return {
      displayAttributeName: 'Talk Ratio',
      displayAttributeDetails: `You spoke ${Math.round(talkRatio)}% of time`,
      value: "You're talking too much",
      color: COLORS.RED,
      highlightText: highlightText.RED,
    };
  } else if (talkRatio >= TALK_RATIO.MEDIUM && talkRatio <= TALK_RATIO.HIGH) {
    return {
      displayAttributeName: 'Talk Ratio',
      displayAttributeDetails: `You spoke ${Math.round(talkRatio)}% of time`,
      value: 'Consider talking less',
      color: COLORS.YELLOW,
      highlightText: highlightText.YELLOW,
    };
  } else if (talkRatio < TALK_RATIO.MEDIUM) {
    return {
      displayAttributeName: 'Talk Ratio',
      displayAttributeDetails: `You spoke ${Math.round(talkRatio)}% of time`,
      value: "You're doing great!",
      color: COLORS.GREEN,
      highlightText: highlightText.GREEN,
    };
  }
};

// Realtime attribute calculation for monologue
const caculateMonologueDisplay = monologueDuration => {
  if (monologueDuration < MONOLOGUE_DURATION.LOW) {
    return {
      displayAttributeName: 'Longest monologue',
      displayAttributeDetails: `${formatDurationMinSecond(monologueDuration)}`,
      value: 'Get prospect to speak more',
      color: COLORS.RED,
      highlightText: highlightText.RED,
    };
  } else if (
    monologueDuration >= MONOLOGUE_DURATION.LOW &&
    monologueDuration <= MONOLOGUE_DURATION.HIGH
  ) {
    return {
      displayAttributeName: 'Longest monologue',
      displayAttributeDetails: `${formatDurationMinSecond(monologueDuration)}`,
      value: 'Consider an open question',
      color: COLORS.YELLOW,
      highlightText: highlightText.YELLOW,
    };
  } else if (monologueDuration > MONOLOGUE_DURATION.HIGH) {
    return {
      displayAttributeName: 'Longest monologue',
      displayAttributeDetails: `${formatDurationMinSecond(monologueDuration)}`,
      value: "You're doing great!",
      color: COLORS.GREEN,
      highlightText: highlightText.GREEN,
    };
  }
};

// Realtime attribute calculation for questions asked
const caculateQuestionsAskedDisplay = questionsAsked => {
  if (questionsAsked < QUESTIONS_ASKED.LOW) {
    return {
      displayAttributeName: 'Questions asked',
      displayAttributeDetails: `${questionsAsked} questions`,
      value: 'Ask more questions',
      color: COLORS.RED,
      highlightText: highlightText.RED,
    };
  } else if (
    questionsAsked >= QUESTIONS_ASKED.LOW &&
    questionsAsked < QUESTIONS_ASKED.HIGH
  ) {
    return {
      displayAttributeName: 'Questions asked',
      displayAttributeDetails: `${questionsAsked} questions`,
      value: 'Consider asking more questions',
      color: COLORS.YELLOW,
      highlightText: highlightText.YELLOW,
    };
  } else if (questionsAsked >= QUESTIONS_ASKED.HIGH) {
    return {
      displayAttributeName: 'Questions asked',
      displayAttributeDetails: `${questionsAsked} questions`,
      value: "You're doing great!",
      color: COLORS.GREEN,
      highlightText: highlightText.GREEN,
    };
  }
};

// Realtime attribute calculation for jargon/filler words
const caculateJargonDisplay = jargon => {
  if (jargon < JARGON.LOW) {
    return {
      displayAttributeName: 'Filler words',
      displayAttributeDetails: `${jargon} Filler words`,
      value: "You're doing great!",
      color: COLORS.GREEN,
      highlightText: highlightText.GREEN,
    };
  } else if (jargon >= JARGON.LOW && jargon <= JARGON.HIGH) {
    return {
      displayAttributeName: 'Filler words',
      displayAttributeDetails: `${jargon} Filler words`,
      value: 'Try to avoid filler words',
      color: COLORS.YELLOW,
      highlightText: highlightText.YELLOW,
    };
  } else if (jargon > JARGON.HIGH) {
    return {
      displayAttributeName: 'Filler words',
      displayAttributeDetails: `${jargon} Filler words`,
      value: 'Too many filler words',
      color: COLORS.RED,
      highlightText: highlightText.RED,
    };
  }
};
