import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';

import {
  getPlaybookInsights,
  GetPlaybookInsightsData,
} from '@/api/insights/getPlaybookInsights';
import { ChartLineLegend } from '@/components/InsightsAcrossCallsPage/chart/ChartLineLegend';
import { EmptyBackgroundChart } from '@/components/InsightsAcrossCallsPage/chart/EmptyBackgroundChart';
import {
  InsightsAcrossCallChartRawDataItem,
  InsightsAcrossCallsChart,
} from '@/components/InsightsAcrossCallsPage/chart/InsightsAcrossCallsChart';
import { TemplateLoadingValueBlocks } from '@/components/InsightsAcrossCallsPage/consts/TemplateLoadingValueBlocks';
import {
  InsightsAcrossCallsStore,
  useInsightsAcrossCallsStore,
} from '@/components/InsightsAcrossCallsPage/hooks/useInsightsAcrossCallsStore';
import { InsightsStatistics } from '@/components/InsightsAcrossCallsPage/InsightsStatistics';
import {
  PlaybookChartValueItem,
  PlaybookEvaluationDataMapper,
  PlaybookItemData,
} from '@/components/InsightsAcrossCallsPage/mappers/PlaybookEvaluationDataMapper';
import { PersonalInsightsChartViewType } from '@/components/InsightsAcrossCallsPage/PersonalInsightsChartTooltip';
import cn from '@/utils/cn';
import logger from '@/utils/logger';

export const PlaybookEvaluation: React.FC = () => {
  const {
    selectedDateOption,
    rawChartData,
    isChartLoading,
    isFirstLoading,
    setIsFirstLoading,
    setIsPageDataLoading,
    selectedTimeRangeCallsCount,
    setCallsWithoutAllPlaybookResultsCount,
    setCallsWithAllPlaybookResultsCount,
  }: InsightsAcrossCallsStore = useInsightsAcrossCallsStore();
  const [chartValueItems, setChartValueItems] = useState<
    PlaybookChartValueItem[]
  >([]);
  const [selectedChartItem, setSelectedChartItem] =
    useState<PlaybookChartValueItem>();
  const [chartData, setChartData] = useState<
    InsightsAcrossCallChartRawDataItem[]
  >([]);

  const playbookResult = useQuery<GetPlaybookInsightsData>({
    queryFn: getPlaybookInsights(selectedDateOption?.value),
    queryKey: ['playbookInsights', selectedDateOption],
    enabled: !!selectedDateOption,
  });

  const playbookResultData: GetPlaybookInsightsData | undefined =
    playbookResult.data;

  useEffect(() => {
    setIsPageDataLoading(playbookResult.isLoading);
  }, [playbookResult.isLoading]);

  useEffect(() => {
    if (!playbookResultData) {
      return;
    }

    const playbookInsightsData: PlaybookItemData[] =
      playbookResultData.playbookInsights;

    const sortedPlaybookData: PlaybookItemData[] = playbookInsightsData.sort(
      (a, b) => a.order - b.order,
    );

    const newChartValueItems: PlaybookChartValueItem[] =
      PlaybookEvaluationDataMapper.map(sortedPlaybookData);

    setChartValueItems(newChartValueItems);
    if (!selectedChartItem) {
      const selectedItem: PlaybookChartValueItem | undefined =
        newChartValueItems.find(el => el.selected);

      setSelectedChartItem(selectedItem);
    }
    setIsFirstLoading(false);
    setCallsWithoutAllPlaybookResultsCount(
      playbookResultData.metaInfo.callsWithoutResultsCount,
    );
    setCallsWithAllPlaybookResultsCount(
      playbookResultData.metaInfo.callsWithAllPlaybookResultsCount,
    );
  }, [playbookResultData]);

  useEffect(() => {
    if (!rawChartData?.length || !selectedChartItem) {
      return;
    }
    logger.debug({ rawChartData }, 'rawChartData');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newChartData: any[] = rawChartData.map(el => ({
      ...el,
      value: el[selectedChartItem.key],
      items: el.items.map(call => ({
        value: call[selectedChartItem.key],
        ...call,
      })),
    }));

    setChartData(newChartData);
  }, [rawChartData, selectedChartItem]);

  const isDataLoading = isChartLoading || playbookResult.isLoading;
  const showStatisticBlocks = chartValueItems.length > 1;

  return (
    <>
      {(showStatisticBlocks || isDataLoading) && (
        <InsightsStatistics
          isFirstLoading={isFirstLoading || !showStatisticBlocks}
          beforeDataLoadItems={TemplateLoadingValueBlocks.Playbook}
          chartValueItems={chartValueItems}
          isLoading={playbookResult.isLoading}
          setSelectedChartItem={setSelectedChartItem}
        />
      )}
      <div
        className={cn(
          'w-full border-[1px] border-[#ECECEC] p-6',
          showStatisticBlocks || isDataLoading
            ? 'rounded-bl-[20px] rounded-br-[20px]'
            : 'rounded-[20px]',
        )}
      >
        <div className="flex flex-row justify-between mb-8">
          {/*<CallsComparisonTooltip*/}
          {/*  isLoading={isDataLoading}*/}
          {/*  currentValue={selectedChartItem?.value}*/}
          {/*  prevValue={selectedChartItem?.prevValue}*/}
          {/*/>*/}
          <ChartLineLegend
            isLoading={isDataLoading || !showStatisticBlocks}
            explanation={selectedChartItem?.chartLegend}
          />
        </div>
        {isDataLoading ||
        !selectedChartItem ||
        !selectedTimeRangeCallsCount ||
        !showStatisticBlocks ? (
          <EmptyBackgroundChart
            isLoading={isDataLoading}
            playbookItemsState={{
              hasPlaybookItemsOptions: showStatisticBlocks,
              errorText: 'Seller Evaluation',
            }}
          />
        ) : (
          <InsightsAcrossCallsChart
            chartData={chartData}
            selectedMetric={selectedChartItem?.key}
            percentageView={true}
            type={PersonalInsightsChartViewType.Playbook}
          />
        )}
      </div>
    </>
  );
};
