import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { CallAssets } from '@/components/CallDetailsPage/Assets/CallAssets';
import { CallNextSteps } from '@/components/CallDetailsPage/Summary/CallNextSteps';
import { CallSummary } from '@/components/CallDetailsPage/Summary/CallSummary';
import { Questions } from '@/components/CallDetailsPage/Summary/Questions';
import { LinkButton } from '@/components/Link/LinkButton';
import { Logo } from '@/components/Shared/Logo';

import { Header } from './components/Header';
import { useGetCallPublicData } from './hooks/useGetCallPublicData';

/**
 * Share Call Page
 * @returns ShareCallPage
 */
function ShareCallPage() {
  const { id } = useParams();

  const { callData: call } = useGetCallPublicData(id || '');

  return (
    <div>
      <div className="w-full h-[72px] bg-white flex items-center px-6 justify-between border-b-[1px] border border-[#EEE]">
        <Logo to="https://silkchart.com" />

        <Link to="/auth/login" className="text-[#152543] font-medium text-sm">
          <button className="text-[#152543] rounded-lg border-1 border border-[rgba(21, 37, 67, 0.16)] p-2.5 text-sm font-semibold">
            Sign in
          </button>
        </Link>
      </div>
      <div className="flex flex-row" style={{ height: 'calc(100vh - 72px)' }}>
        {call && (
          <div
            className={
              'bg-white p-6 overflow-y-scroll overflow-x-hidden hide-scrollbar h-[calc(100vh-72px)] w-full flex flex-col'
            }
          >
            <div className="flex justify-between mb-6">
              <Header call={call} readOnly />
              <div className="p-2 pl-4 flex items-center gap-4 rounded-[10px] border border-solid bg-accent-blue/5 border-accent-blue/10 justify-start text-sm font-semibold text-accent-blue">
                <span>Get your own AI Sales Coach</span>
                <LinkButton
                  to="https://app.silkchart.com/"
                  variant="primary"
                  buttonClassName="px-[12px] py-[10px] leading-[0px]"
                >
                  Try SilkChart for Free
                </LinkButton>
              </div>
            </div>
            <div className="flex flex-1">
              <div className="flex-1 flex flex-col gap-4">
                {id && (
                  <>
                    <CallSummary callId={id} isCallPublic className="!mt-0" />
                    <CallNextSteps callId={id} isCallPublic readOnly />
                    <Questions callId={id} isCallPublic />
                  </>
                )}
              </div>

              {/* Transcript Column */}
              <div className="ml-6 flex-1">
                <CallAssets call={call} isPublic />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export { ShareCallPage };
