import React from 'react';
import { CheckSquare, FileText, HelpCircle } from 'react-feather';

import { getExistingResponsibleSpeakersWithColors } from '@/utils/getExistingResponsibleSpeakersWithColors';

import { CollapseCallSection } from '../../CollapseCallSection/CollapseCallSection';
import { CallNextStepItem } from '../Summary/CallNextStepItem';
import { CallSummaryItem } from '../Summary/CallSummaryItem';
import { QuestionItem } from '../Summary/QuestionItem';
import {
  DemoCallSummaryItems,
  DemoNextSteps,
  DemoTopQuestions,
} from './DemoCallMockData';

interface DemoCallSummaryDetailsProps {
  callId: string;
}

function DemoCallSummary({ callId }: DemoCallSummaryDetailsProps) {
  return (
    <div className="flex flex-col mt-4">
      <CollapseCallSection
        chevronStyles="text-main-gray-dark"
        titleStyles="gap-1.5"
        itemsContainerStyles="pt-3"
        openByDefault={true}
        title="Call summary"
        icon={<FileText size={20} className="text-main-gray-dark" />}
      >
        <div
          key="prospects-top-questions"
          className="h-full flex flex-col gap-1"
        >
          {DemoCallSummaryItems.map(summary => (
            <CallSummaryItem
              key={summary.id}
              summary={{ ...summary, callId }}
            />
          ))}
        </div>
      </CollapseCallSection>
    </div>
  );
}

function DemoCallNextSteps({ callId }: DemoCallSummaryDetailsProps) {
  const existingResponsibleSpeakers =
    getExistingResponsibleSpeakersWithColors(DemoNextSteps);

  return (
    <div className="flex flex-col">
      <CollapseCallSection
        chevronStyles="text-main-gray-dark"
        titleStyles="gap-1.5"
        itemsContainerStyles="pt-3"
        openByDefault={true}
        title="Next Steps"
        icon={<CheckSquare size={20} className="text-main-gray-dark" />}
      >
        <div className="flex flex-col gap-1">
          {DemoNextSteps.map(nextStep => (
            <CallNextStepItem
              key={nextStep.id}
              existingResponsibleSpeakers={existingResponsibleSpeakers}
              nextStep={{
                ...nextStep,
                callId,
                isDone: false,
              }}
              readOnly
            />
          ))}
        </div>
      </CollapseCallSection>
    </div>
  );
}

function DemoCallQuestions({ callId }: DemoCallSummaryDetailsProps) {
  return (
    <div className="flex flex-col">
      <CollapseCallSection
        chevronStyles="text-main-gray-dark"
        titleStyles="gap-1.5"
        itemsContainerStyles="pt-3"
        openByDefault={true}
        title="Questions and Objections"
        icon={<HelpCircle size={20} className="text-main-gray-dark" />}
      >
        <div
          key="prospects-top-questions"
          className="h-full flex flex-col gap-1"
        >
          {DemoTopQuestions.map(({ id, summary, answer, timestamp }) => (
            <QuestionItem
              key={id}
              summary={summary}
              answer={answer}
              timestamp={timestamp}
              callId={callId}
            />
          ))}
        </div>
      </CollapseCallSection>
    </div>
  );
}

function DemoCallSummaryDetails({ callId }: DemoCallSummaryDetailsProps) {
  return (
    <div className="flex flex-col gap-4">
      <DemoCallSummary callId={callId} />
      <DemoCallNextSteps callId={callId} />
      <DemoCallQuestions callId={callId} />
    </div>
  );
}

export { DemoCallSummaryDetails };
