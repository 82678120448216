import React from 'react';
import { Check } from 'react-feather';

import { Button } from '@/components/Button/Button';
import { useQuickstartDismiss } from '@/components/Quickstart/hooks/useQuickstartDismiss';

export const QuickstartSuccess: React.FC = () => {
  const { quickstartDismiss, isPending } = useQuickstartDismiss();

  const handleDismiss = async () => {
    await quickstartDismiss();
  };

  return (
    <div className="my-10 flex items-center flex-col">
      <div className="bg-accent-green/10 h-16 w-16 flex items-center justify-center rounded-full">
        <Check className="text-accent-green" />
      </div>
      <span className="text-dark font-semibold text-base mt-4">
        You’re all set up!
      </span>
      <Button
        className="mt-5"
        variant="primary"
        onClick={handleDismiss}
        isLoading={isPending}
      >
        Dismiss
      </Button>
    </div>
  );
};
