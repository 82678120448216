import React from 'react';
import { ArrowRight } from 'react-feather';
import { Link } from 'react-router-dom';

import { Button } from '@/components/Button/Button';
import Modal from '@/components/Shared/Modal';
import { CHROME_DOWNLOAD_URL } from '@/constants';
import { isOpera, isSafari } from '@/utils/browserDetection';

const IS_SAFARI = isSafari();
const IS_OPERA = isOpera();

interface UnsupportedBrowserErrorModalProps {
  show: boolean;
  onClose: () => void;
}

export const UnsupportedBrowserErrorModal: React.FC<
  UnsupportedBrowserErrorModalProps
> = ({ show, onClose }) => (
  <Modal show={show} onClose={onClose}>
    <div className="flex flex-col items-center w-[800px]">
      <SwitchFromBrowser />
      <Modal.Title className="mt-4 mb-1">
        Oops! Please switch to a Chrome browser...
      </Modal.Title>
      <Modal.Subtitle className="mb-4">
        For the best experience, please switch to
        <Link to={CHROME_DOWNLOAD_URL} target="_blank">
          <Button variant="link">Google Chrome</Button>
        </Link>
      </Modal.Subtitle>

      <Button className="w-[100px]" onClick={onClose}>
        Got it
      </Button>
    </div>
  </Modal>
);

interface BrowserIconProps {
  browser: string;
}

const BrowserIcon: React.FC<BrowserIconProps> = ({ browser }) => {
  const getIconSrc = () => {
    if (browser === 'opera') {
      return '/opera-icon.png';
    }
    if (browser === 'safari') {
      return '/safari-icon.png';
    }
    return '/chrome-icon.png';
  };
  return (
    <img src={getIconSrc()} alt="chrome-browser-icon" className="h-12 w-12" />
  );
};

const SwitchFromBrowser: React.FC = () => {
  if (!IS_OPERA && !IS_SAFARI) {
    return <BrowserIcon browser="chrome" />;
  }

  return (
    <div className="flex items-center gap-2">
      {IS_OPERA && <BrowserIcon browser="opera" />}
      {IS_SAFARI && <BrowserIcon browser="safari" />}
      <ArrowRight size={16} strokeWidth={3} className="text-accent-blue" />
      <BrowserIcon browser="chrome" />
    </div>
  );
};
