import { useEffect } from 'react';
import { Link } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import { BotJoining } from '@/components/CallDetailsPage/BotJoining';
import { BotJoiningFailed } from '@/components/CallDetailsPage/BotJoiningFailed';
import { CallHeader } from '@/components/CallDetailsPage/CallHeader';
import { DoneState } from '@/components/CallDetailsPage/DoneState';
import { EditMenu } from '@/components/CallDetailsPage/EditMenu';
import { FailedState } from '@/components/CallDetailsPage/FailedState';
import ProcessingState from '@/components/CallDetailsPage/ProcessingState';
import { Record } from '@/components/CallDetailsPage/Record';
import ReprocessingState from '@/components/CallDetailsPage/ReprocessingState';
import { ShareCallModal } from '@/components/CallDetailsPage/ShareCallModal/ShareCallModal';
import { useShareCallModalStore } from '@/components/CallDetailsPage/ShareCallModal/useShareCallModalStore';
import StuckProcessingState from '@/components/CallDetailsPage/StuckProcessingState';
import { ChangeSpeakerModal } from '@/components/HomePage/ChangeSpeakerModal';
import { DeleteCallModal } from '@/components/HomePage/DeleteCallModal';
import { EditCallDetailsModal } from '@/components/HomePage/EditCallDetailsModal';
import { SubscriptionBanner } from '@/components/subscription/SubscriptionBanner/SubscriptionBanner';
import { EXPECTED_MAX_TIME_TO_PROCESS_CALL_MS } from '@/constants';
import { CallStatus } from '@/enums/callStatus.enum';
import { useModal } from '@/hooks/useModal';
import { selectIsCollapsed } from '@/pages/CallsTabPage/slice/selectors';
import { canDeleteCall } from '@/utils/callHelper';
import { validParamId } from '@/utils/validParamId';
import { useBotRecordingStore } from '@/widgets/RecordWidget/useBotRecordingStore';
import { useCallRecordingStore } from '@/widgets/RecordWidget/useCallRecordingStore';

import { Button } from '../../components/Button/Button';
import EmptyState from '../../components/HomePage/EmptyState';
import { selectFetchCallsLoading } from '../HomePage/slice/selectors';
import { useCallDetailsSlice } from './slice';
import {
  selectCall,
  selectFetchCallFailed,
  selectLoading,
} from './slice/selectors';

const CallDetailsPage = () => {
  const call = useSelector(selectCall);
  const recordingCall = useCallRecordingStore(store => store.recordingCall);
  const { isBotRecording, callId: botRecordingCallId } = useBotRecordingStore();
  const loading = useSelector(selectLoading);
  const fetchCallFailed = useSelector(selectFetchCallFailed);
  const dispatch = useDispatch();
  const { actions } = useCallDetailsSlice();
  let { id } = useParams();
  const navigate = useNavigate();
  const fetchCallsLoading = useSelector(selectFetchCallsLoading);
  const isMenuCollapsed = useSelector(selectIsCollapsed);
  const status = call?.status;
  const { openShareCallModal } = useShareCallModalStore();

  const {
    isOpen: isOpenEditCallDetailsModal,
    open: openEditCallDetailsModal,
    close: closeEditCallDetailsModal,
  } = useModal();
  const {
    isOpen: isOpenDeleteCallModal,
    open: openDeleteCallModal,
    close: closeDeleteCallModal,
  } = useModal();

  useEffect(() => {
    if (fetchCallFailed) {
      dispatch(actions.setDefaultFetchCallFailed());

      navigate('/calls');
    }
  }, [fetchCallFailed]);

  const isRecordingCall = id && id === recordingCall?.id;

  useEffect(() => {
    if (!validParamId(id) || isRecordingCall) return;

    dispatch(actions.fetchCall({ id }));
  }, [id]);

  useEffect(() => {
    if (isRecordingCall) {
      dispatch(actions.fetchCallSuccess(recordingCall));
    }
    /*
    else {
      console.log('fetching from second useEffect');
      dispatch(actions.fetchCall({ id }));
    }
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecordingCall]);

  const fetchingHydratedCall =
    status === 'done' && !call?.metrics && !call?.playbookItemResults;
  if (fetchCallsLoading || loading || (id && !status) || fetchingHydratedCall) {
    return (
      <div className="flex justify-center items-center w-full h-[calc(100vh - 128px)] bg-white">
        <ClipLoader color="rgb(37 99 235)" />
      </div>
    );
  }

  if (!id) {
    return (
      <div className="flex justify-center items-center w-full h-[calc(100vh - 128px)] bg-white">
        <EmptyState />
      </div>
    );
  }

  const isCallStuckInProcessing =
    ![
      CallStatus.Done,
      CallStatus.Failed,
      CallStatus.Recording,
      CallStatus.BotJoining,
      CallStatus.BotRecording,
      CallStatus.BotJoiningFailed,
    ].includes(status) &&
    new Date() - new Date(call.queuedAt) >=
      EXPECTED_MAX_TIME_TO_PROCESS_CALL_MS;
  const leftMargin = isMenuCollapsed && call?.status === 'done';
  const canEditCall = ['processing', 'failed', 'done'].includes(call?.status);

  const styleMl = leftMargin ? 'ml-call-list-collapsed' : '';
  // don't use space inside Tailwind's calc
  const styleW = leftMargin
    ? 'w-[calc(100%_-_theme(spacing[call-list-collapsed]))]'
    : 'w-full';

  return (
    <div
      className={`bg-white p-6 overflow-y-scroll overflow-x-hidden hide-scrollbar h-[calc(100vh-70px)] ${styleMl} ${styleW}`}
    >
      <SubscriptionBanner className="mb-10" />
      <EditCallDetailsModal
        show={isOpenEditCallDetailsModal}
        onClose={closeEditCallDetailsModal}
      />
      {canDeleteCall(call) && (
        <DeleteCallModal
          call={call}
          show={isOpenDeleteCallModal}
          onClose={closeDeleteCallModal}
        />
      )}
      <ChangeSpeakerModal />
      <ShareCallModal />
      <div className="flex justify-between mb-6">
        <CallHeader
          call={call}
          openEditCallDetailsModal={openEditCallDetailsModal}
        />
        <div className="flex gap-1">
          <div className="flex items-center gap-1">
            {canEditCall && (
              <EditMenu
                call={call}
                openEditCallDetailsModal={openEditCallDetailsModal}
                openDeleteCallModal={openDeleteCallModal}
              />
            )}

            {call?.status === CallStatus.Done && !call?.isDemoCall && (
              <Button
                variant="secondary"
                className="whitespace-nowrap"
                onClick={() => openShareCallModal(call)}
              >
                <Link size={16} />
                Share
              </Button>
            )}
          </div>
        </div>
      </div>
      <div>
        {isCallStuckInProcessing && <StuckProcessingState />}
        {!isCallStuckInProcessing && (
          <>
            {(isRecordingCall ||
              (isBotRecording && call.id === botRecordingCallId)) && <Record />}
            {call?.status === CallStatus.BotJoiningFailed && (
              <BotJoiningFailed
                botRecordingStatus={call?.botRecordingStatus}
                callId={call.id}
              />
            )}
            {call?.status === CallStatus.BotJoining && (
              <BotJoining
                botRecordingStatus={call?.botRecordingStatus}
                callId={call.id}
              />
            )}
            {call?.status === CallStatus.Processing &&
              call?.processedCount == 0 && <ProcessingState />}
            {call?.status === CallStatus.Processing &&
              call?.processedCount > 0 && <ReprocessingState />}
            {call?.status === CallStatus.Done && <DoneState />}
            {call?.status === CallStatus.Failed && (
              <FailedState
                call={call}
                openDeleteCallModal={openDeleteCallModal}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CallDetailsPage;
