import axios from 'axios';
import dayjs from 'dayjs';

import { getAudioDuration } from '@/utils/getAudioDuration';
import logger from '@/utils/logger';

const backendURL = process.env.REACT_APP_BACKEND_URL;

export type GetCallUploadUrlParams = {
  file: File;
  callId?: string;
  fileExtension?: string;
  authToken?: string;
};

export type PostNewCallParams = {
  key: string;
  callId: string;
  file: File;
  duration?: number;
  authToken?: string;
};

export type CreateNewCallParams = {
  file: File;
  duration?: number;
  callId?: string;
  fileExtension?: string;
  onUploadProgress?: (progress: number) => void;
  authToken?: string;
};

export const getCallUploadUrl = async (params: GetCallUploadUrlParams) => {
  const response = await fetch(`${backendURL}/api/calls/uploadUrl`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization:
        params.authToken ?? `jwt ${localStorage.getItem('authToken')}`,
    },
    body: JSON.stringify({
      callId: params.callId,
      fileName: params.file.name,
      fileType: params.file.type,
      fileExtension: params.fileExtension,
    }),
  });

  const body = await response.json();
  const key: string = body.key;
  const callId: string = params.callId || body.callId;
  const uploadUrl: string = body.uploadUrl;

  return { uploadUrl, callId, key };
};

export const postNewCall = async (params: PostNewCallParams) => {
  let duration = params.duration;
  if (!duration) {
    duration = await getAudioDuration(params.file);
  }

  if (!duration) {
    logger.error('Failed to get audio duration');
  }

  const response = await fetch(`${backendURL}/api/calls`, {
    method: 'POST',
    headers: {
      Authorization:
        params.authToken ?? `jwt ${localStorage.getItem('authToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      key: params.key,
      callId: params.callId,
      fileName: params.file.name,
      duration,
      callType: 'Discovery',
      date: dayjs().format('YYYY-MM-DD'),
      noOfSpeakers: null,
    }),
  });

  return response.json();
};

export const createNewCall = async (params: CreateNewCallParams) => {
  logger.info('Creating new call, getting the Upload URL');
  const { uploadUrl, key, callId } = await getCallUploadUrl({
    file: params.file,
    callId: params.callId,
    fileExtension: params.fileExtension,
    authToken: params.authToken,
  });

  logger.info('Uploading the file');
  //Using Axios to upload so it uses XHR and can track progress
  await axios
    .put(uploadUrl, params.file, {
      headers: {
        'Content-Type': params.file.type,
      },
      onUploadProgress: progressEvent => {
        if (
          !params.onUploadProgress ||
          !progressEvent.loaded ||
          !progressEvent.total
        ) {
          return;
        }

        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        params.onUploadProgress(percentage);
      },
    })
    .catch(err => {
      logger.error(err, 'Error uploading file');
      throw err;
    });

  const res = await postNewCall({
    key,
    callId,
    file: params.file,
    duration: params.duration,
    authToken: params.authToken,
  });

  return res.data;
};
