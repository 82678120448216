import { HelpCircle } from 'react-feather';
import { ClipLoader } from 'react-spinners';

import { useGetCallQuestions } from '@/components/CallDetailsPage/Summary/hooks/useGetCallQuestions';
import { SummaryBlockEmptyState } from '@/components/CallDetailsPage/Summary/SummaryBlockEmptyState';

import { CollapseCallSection } from '../../CollapseCallSection/CollapseCallSection';
import { QuestionItem } from './QuestionItem';

export interface QuestionsProps {
  callId: string;
  isCallPublic?: boolean;
}

export const Questions = ({ callId, isCallPublic = false }: QuestionsProps) => {
  const { callQuestions, isLoading } = useGetCallQuestions(
    callId,
    isCallPublic,
  );

  return (
    <div className="flex flex-col">
      <CollapseCallSection
        chevronStyles="text-main-gray-dark"
        titleStyles="gap-1.5"
        itemsContainerStyles="pt-3"
        openByDefault
        title="Questions and Objections"
        icon={<HelpCircle size={20} className="text-main-gray-dark" />}
      >
        {isLoading && (
          <div className="flex justify-center">
            <ClipLoader color="rgb(37 99 235)" />
          </div>
        )}
        {callQuestions && callQuestions.length > 0 ? (
          <div
            key="prospects-top-questions"
            className="h-full flex flex-col gap-1"
          >
            {callQuestions.map(({ id, summary, answer, timestamp }) => (
              <QuestionItem
                key={id}
                summary={summary}
                answer={answer}
                timestamp={timestamp}
                callId={callId}
                baseUrl={isCallPublic ? 'share-call' : 'calls'}
              />
            ))}
          </div>
        ) : (
          callQuestions &&
          callQuestions.length === 0 && (
            <SummaryBlockEmptyState description="We didn’t detect prospect questions during this call" />
          )
        )}
      </CollapseCallSection>
    </div>
  );
};
