import { getGoogleAuthStatus } from '@/api/auth/google/get-google-auth-status';
import { googleAuthRedirect } from '@/api/auth/google/google-auth-redirect';
import { getCalendarEvents } from '@/api/calendar/get-calendar-events';
import { getRecordingSettings } from '@/api/calendar/get-recording-settings';
import { inviteAssistantAgain } from '@/api/calendar/invite-assistant-again';
import { joinRecorderByUrl } from '@/api/calendar/join-recorder-by-url';
import { joinRecorderToEvent } from '@/api/calendar/join-recorder-to-event';
import { requestBotRecordingPermission } from '@/api/calendar/request-bot-recording-permission';
import { stopEventRecording } from '@/api/calendar/stop-event-recording';
import { toggleCalendarEventRecording } from '@/api/calendar/toggle-calendar-event-recording';
import { updateRecordingSettings } from '@/api/calendar/update-recording-settings';
import { forceCallAnalyze } from '@/api/call/forceCallAnalyze';
import { activateIsDemoMode } from '@/api/insights/activate-is-demo-mode';

import { reanalyzeCall } from './call/reanalyzeCall';
import { getAvailablePlaybooks } from './playbook/getAvailablePlaybooks';
import { getCustomPlaybook } from './playbook/getCustomPlaybook';
import { getItems } from './playbook/getItems';
import { setActivePlaybook } from './playbook/setActivePlaybook';
import { updateCustomPlaybook } from './playbook/updateCustomPlaybook';

export const Api = {
  auth: {
    googleAuthRedirect,
    getGoogleAuthStatus,
  },
  call: {
    reanalyzeCall,
    forceCallAnalyze,
  },
  playbook: {
    getItems,
    getCustomPlaybook,
    getAvailablePlaybooks,
    updateCustomPlaybook,
    setActivePlaybook,
  },
  calendar: {
    toggleCalendarEventRecording,
    getCalendarEvents,
    joinRecorderByUrl,
    joinRecorderToEvent,
    stopEventRecording,
    requestBotRecordingPermission,
    inviteAssistantAgain,
    getRecordingSettings,
    updateRecordingSettings,
  },
  insights: {
    activateIsDemoMode,
  },
};
