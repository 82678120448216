import React from 'react';

import { useNavigateToTranscript } from '../../../hooks/useNavigateToTranscript';
import { formatTimestamp } from '../../../utils/formatDate';
import { CollapsibleItem } from '../../CollapsibleItem/CollapsibleItem';

interface QuestionItemProps {
  summary: string;
  answer: string;
  timestamp: string;
  callId: string;
  baseUrl?: string;
}

function QuestionItem({
  summary,
  answer,
  timestamp,
  callId,
  baseUrl = 'calls',
}: QuestionItemProps) {
  const { navigateToTranscript } = useNavigateToTranscript(baseUrl);

  return (
    <CollapsibleItem
      title={summary}
      wrapperStyles="py-2.5 px-2.5 rounded-[10px]"
      boldTitle={false}
    >
      <div className="bg-[#F6F6F6] rounded-[14px] p-[14px]">
        <div className="flex flex-row mb-2 items-center">
          <p className="text-xs text-[#5C6A82] font-semibold">Your answer</p>
          <p
            className="text-[#2C6CF6] text-xs font-normal cursor-pointer flex ml-[3px]"
            onClick={() => {
              navigateToTranscript({
                callId,
                text: answer,
                timestamp: Number(timestamp),
              });
            }}
          >
            at {formatTimestamp(timestamp)}
          </p>
        </div>
        <p className="text-[#1c1c1c] text-sm font-normal leading-tight">
          {answer}
        </p>
      </div>
    </CollapsibleItem>
  );
}

export { QuestionItem };
