import { API } from '../../base';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const endpoints = {
  deleteGoogleAuth: backendURL + '/api/google/auth',
};

export const deleteGoogleAuth = async (): Promise<void> => {
  try {
    await API(endpoints.deleteGoogleAuth, {
      method: 'DELETE',
    });
  } catch (error) {
    console.error('Error deleting Google authentication:', error);
  }
};
