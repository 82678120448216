import { API } from '@/api/base';
import { Response } from '@/api/response';

export interface GetShareLinkResponse {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

const backendURL = process.env.REACT_APP_BACKEND_URL as string;

const endpoints = {
  getCallShareLink: (callId: string) =>
    backendURL + `/api/calls/${callId}/share-link`,
};

export const getCallShareLink =
  (callId: string) => async (): Promise<GetShareLinkResponse | undefined> => {
    try {
      const response = await API(endpoints.getCallShareLink(callId), {
        method: 'GET',
      });

      const result: Response<GetShareLinkResponse> = await response.json();

      return result.data;
    } catch (err) {
      console.error('fetch call next steps', err);
      return undefined;
    }
  };
