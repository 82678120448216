import dayjs from 'dayjs';
import _groupBy from 'lodash/groupBy';
import { useEffect, useMemo, useState } from 'react';
import { ChevronDown, ChevronUp, Info } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { eventTracker } from '@/amplitude/eventTracker';
import { DemoCallPlaybookPercentage } from '@/components/CallDetailsPage/DemoCall/DemoCallMockData';
import { CallsListSearch } from '@/components/CallsList/CallsListSearch';
import { CallsListToggleButton } from '@/components/CallsList/CallsListToggleButton';
import { useCallsList } from '@/components/CallsList/useCallsList';
import { NotSalesCallIcon } from '@/components/Shared/icons/NotSalesCallIcon';
import { CallStatus } from '@/enums/callStatus.enum';
import { selectIsCollapsed } from '@/pages/CallsTabPage/slice/selectors';
import { useHomeSlice } from '@/pages/HomePage/slice';
import {
  selectCalls,
  selectFetchCallsLoading,
  selectIsFetchingCallStatuses,
  selectRedirectAfterDeleteCall,
} from '@/pages/HomePage/slice/selectors';
import cn from '@/utils/cn';
import { useBotRecordingStore } from '@/widgets/RecordWidget/useBotRecordingStore';
import { useCallRecordingStore } from '@/widgets/RecordWidget/useCallRecordingStore';

import useInterval from '../../hooks/useInterval';
import formatDateHeader from '../../utils/formatDate';
import { WithHoverTooltip } from '../HoverTooltip/WithHoverTooltip';
import { OnboardingTooltip } from '../Onboarding/OnboardingTooltip/OnboardingTooltip';
import { ScoreCircle } from '../Shared/ScoreCircle';
import { DemoCallActionButtons } from './DemoCallActionButtons/DemoCallActionButtons';

type CallListItem = {
  id: string;
  name: string;
  isDemoCall: boolean;
  isSalesCall: boolean;
  lastReanalyzedPlaybookId: string | null;
  isValid: boolean;
  includeInAnalysis: boolean;
  status: CallStatus;
  playbookStats: {
    salesPlaybookPercentTotal: number;
    prospectPlaybookPercentTotal: number;
    totalSellerEvaluationItems: number;
    processingItems: number;
  };
};

export const CallsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { actions } = useHomeSlice();
  const calls = useSelector(selectCalls);
  const recordingCall = useCallRecordingStore(store => store.recordingCall);
  const fetchCallsLoading = useSelector(selectFetchCallsLoading);
  const isFetchingCallStatuses = useSelector(selectIsFetchingCallStatuses);
  const redirectAfterCallDelete = useSelector(selectRedirectAfterDeleteCall);
  const isCollapsed = useSelector(selectIsCollapsed);
  const [searchText, setSearchText] = useState('');
  const [datesCollapsed, setDatesCollapsed] = useState<{
    [key: string]: boolean;
  }>({});
  const botRecordingCall = calls.find((call: CallListItem) =>
    [CallStatus.BotRecording].includes(call.status),
  );

  const callInBotRecordingState = calls.find((call: CallListItem) =>
    [CallStatus.BotRecording, CallStatus.BotJoining].includes(call.status),
  );

  const {
    startBotRecording,
    setupBotRecordingFlow,
    isBotRecording,
    inStopRecordingProcess,
    isGeneralBotFlowActive,
    setInStopRecordingProcess,
    stopRecording,
  } = useBotRecordingStore();

  if (!botRecordingCall && inStopRecordingProcess) {
    setInStopRecordingProcess(false);
  }

  if (botRecordingCall && !isBotRecording && !inStopRecordingProcess) {
    startBotRecording(botRecordingCall);
  }

  if (
    callInBotRecordingState &&
    !isGeneralBotFlowActive &&
    !inStopRecordingProcess
  ) {
    setupBotRecordingFlow(callInBotRecordingState);
  }

  if (!callInBotRecordingState && (isBotRecording || isGeneralBotFlowActive)) {
    stopRecording();
  }

  const { isOpen } = useCallsList();
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    dispatch(actions.fetchCalls());
  }, []);

  const displayCalls = useMemo(() => {
    if (fetchCallsLoading) {
      return {};
    }

    const filteredCalls = [recordingCall, ...calls]
      .filter(Boolean)
      .filter(call => {
        if (!searchText) {
          return true;
        }
        return call.name.toLowerCase().includes(searchText.toLowerCase());
      });

    return _groupBy(filteredCalls, call => {
      const date = dayjs(call.date).format('YYYY-MM-DD');
      return date;
    });
  }, [recordingCall, calls, fetchCallsLoading, searchText]);

  const sortedDisplayDates = useMemo(
    () => Object.keys(displayCalls).sort((a, b) => b.localeCompare(a)),
    [displayCalls],
  );

  useEffect(() => {
    const sortedCallsArr = Object.values(displayCalls).flat();
    // TODO: remove sorted calls
    dispatch(actions.setSortedCalls(sortedCallsArr));
  }, [displayCalls]);

  useEffect(() => {
    if (isCollapsed) {
      setSearchText('');
    }
  }, [isCollapsed]);

  useEffect(() => {
    if (redirectAfterCallDelete) {
      setSearchText('');
      navigate('/calls');

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      dispatch(actions.disableRedirectAfterCallDelete());
    }
  }, [redirectAfterCallDelete]);

  useInterval(() => {
    const inProcessCallIds = calls
      .filter((call: CallListItem) => call.status === CallStatus.Processing)
      .map((call: CallListItem) => call.id);

    const inBotRecordingCallIds = calls
      .filter((call: CallListItem) =>
        [
          CallStatus.BotRecording,
          CallStatus.BotJoining,
          CallStatus.BotJoiningFailed,
        ].includes(call.status),
      )
      .map((call: CallListItem) => call.id);

    if (
      (inProcessCallIds.length > 0 || inBotRecordingCallIds.length > 0) &&
      !isFetchingCallStatuses
    ) {
      dispatch(
        actions.fetchCallStatuses({
          callIds: [...inProcessCallIds, ...inBotRecordingCallIds],
        }),
      );
    }
  }, 10000);

  const shouldShowCallsList = calls.length > 0;

  if (!shouldShowCallsList) {
    return null;
  }

  return (
    <div
      data-testid="calls-list"
      className={cn(
        isOpen ? 'w-call-list-expanded' : 'w-call-list-collapsed',
        'ease-in-out duration-500',
        'grow-0 shrink-0',
        'hide-scrollbar h-[calc(100vh-69px)] overflow-y-auto group',
      )}
    >
      <div
        className={cn(
          isOpen ? 'w-call-list-expanded' : 'w-0 min-w-0',
          'ease-in-out duration-500',
          'sticky top-0 px-0 pb-2 z-20 bg-light-gray',
          'border-b-[1px] border-solid border-light-gray group-hover:border-[#EEE]',
        )}
      >
        <CallsListToggleButton
          isOpen={isOpen}
          // toggle={toggle}
        />
        {calls.length > 1 && (
          <CallsListSearch
            show={isOpen}
            value={searchText}
            onChange={setSearchText}
          />
        )}
      </div>
      <div
        className={cn(
          'call-list overflow-x-hidden overflow-y-auto bg-light-gray truncate min-h-[calc(100vh-102px)]',
          isOpen
            ? 'w-call-list-expanded min-w-call-list-expanded px-8 pb-28'
            : 'w-0 px-0 min-w-0',
          'ease-in-out duration-500',
        )}
      >
        {sortedDisplayDates.length > 0 &&
          sortedDisplayDates.map(k => (
            <div key={k}>
              <div className="flex text-xs text-dark font-semibold mt-6 mb-2">
                {formatDateHeader(k)}
                {datesCollapsed[k]}
                {!datesCollapsed[k] ? (
                  <ChevronUp
                    onClick={() => {
                      setDatesCollapsed({ ...datesCollapsed, [k]: true });
                    }}
                    className="w-4 h-4 inline-block items-center cursor-pointer"
                  ></ChevronUp>
                ) : (
                  <ChevronDown
                    onClick={() => {
                      setDatesCollapsed({ ...datesCollapsed, [k]: false });
                    }}
                    className="w-4 h-4 inline-block items-center cursor-pointer"
                  ></ChevronDown>
                )}
              </div>
              {!datesCollapsed[k] && (
                <ul>
                  {displayCalls[k].map(call => (
                    <CallItem
                      key={call.id}
                      call={call}
                      // toggleSidebar={toggle}
                    />
                  ))}
                </ul>
              )}
            </div>
          ))}

        {calls.length === 1 && calls[0].isDemoCall ? (
          <DemoCallActionButtons className="mt-4" />
        ) : null}
      </div>
    </div>
  );
};

const CallProcessingSpinner = () => (
  <svg
    className="animate-spin"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1 9C17.5971 9 18.0047 9.40396 17.955 9.89853C17.8036 11.4084 17.2721 12.8607 16.4038 14.1171C15.3643 15.621 13.8915 16.7728 12.1813 17.419C10.4711 18.0652 8.60462 18.1753 6.83032 17.7346C5.05602 17.2938 3.45802 16.3232 2.249 14.9518C1.03998 13.5804 0.277268 11.8734 0.0623838 10.0578C-0.1525 8.24229 0.19063 6.40435 1.04611 4.78863C1.90159 3.17291 3.22884 1.85604 4.85123 1.01328C6.20647 0.309287 7.71396 -0.0359866 9.23092 0.00295562C9.72781 0.0157115 10.0775 0.470734 10.0152 0.963871C9.9529 1.45701 9.50181 1.80032 9.00475 1.79999C7.85218 1.79923 6.71156 2.07528 5.68098 2.61062C4.38307 3.28483 3.32127 4.33833 2.63689 5.6309C1.9525 6.92348 1.678 8.39383 1.84991 9.84627C2.02181 11.2987 2.63198 12.6644 3.5992 13.7614C4.56642 14.8585 5.84482 15.6351 7.26426 15.9876C8.6837 16.3402 10.1769 16.2522 11.545 15.7352C12.9132 15.2182 14.0914 14.2968 14.923 13.0936C15.5833 12.1383 16.0001 11.0413 16.1438 9.89769C16.2058 9.40451 16.6029 9 17.1 9Z"
      fill="#2C6CF6"
    />
  </svg>
);

const CallItem = ({
  call,
  // toggleSidebar,
}: {
  call: CallListItem;
  // toggleSidebar: () => void;
}) => {
  const { id } = useParams();

  if (!call || !id) {
    return <></>;
  }

  const recordingStatus = [
    CallStatus.Recording,
    CallStatus.BotJoining,
    CallStatus.BotRecording,
  ];

  const isSalesCall =
    call.isSalesCall ||
    call.isSalesCall === null ||
    call.isSalesCall === undefined;

  const hasPlaybookData = isSalesCall && call.lastReanalyzedPlaybookId !== null;

  return (
    <Link
      to={`/calls/${call?.id}`}
      onClick={() => {
        eventTracker.calls.callButtonClick({ callId: call.id });
        // toggleSidebar();
      }}
      className="flex items-center"
    >
      <div
        className={cn(
          'flex items-center text-sm cursor-pointer font-medium leading-4 p-2 w-full',
          call.id === id
            ? 'bg-[#2C6CF6] text-[#2C6CF6] bg-opacity-10 rounded-lg border-[1px] border-accent-blue/8'
            : 'text-[#5C6A82]',
        )}
      >
        <span className="w-[18px] h-[18px] flex justify-center items-center mr-1">
          {call.status === CallStatus.Done &&
            !hasPlaybookData &&
            !call.isDemoCall && <NotSalesCallIcon />}
          {call.status === CallStatus.Failed && (
            <Info size={40} className="text-accent-red" />
          )}
          {(call.status === CallStatus.Processing ||
            (call.status !== CallStatus.Failed &&
              call.playbookStats?.processingItems > 0 &&
              hasPlaybookData) ||
            call.isDemoCall) && <CallProcessingSpinner />}
          {recordingStatus.includes(call.status) && (
            <img src="/radio.svg" width={18} height={18} alt="recording" />
          )}
          {call.status === CallStatus.Done &&
            isSalesCall &&
            call.playbookStats &&
            (call.playbookStats.processingItems <= 0 || call.isDemoCall) && (
              <ScoreCircle
                percent={
                  call.isDemoCall
                    ? DemoCallPlaybookPercentage
                    : call.playbookStats.totalSellerEvaluationItems > 0
                      ? call.playbookStats.salesPlaybookPercentTotal
                      : call.playbookStats.prospectPlaybookPercentTotal
                }
                showText
              />
            )}
        </span>
        <span className="truncate whitespace-nowrap font-medium">
          {call.name}
        </span>
        {call.status === CallStatus.Done &&
          !call.includeInAnalysis &&
          !call.isDemoCall && (
            <WithHoverTooltip
              tooltip={
                <p>
                  This call is excluded from <br /> insights analysis
                </p>
              }
              placement="bottom"
            >
              <img
                src="/minus-circle.svg"
                width={16}
                height={16}
                alt="excluded-icon"
                className="ml-1"
              />
            </WithHoverTooltip>
          )}
      </div>
      {call.isDemoCall && (
        <div className="relative">
          <OnboardingTooltip tooltipId="welcome" placement="right-start" />
        </div>
      )}
    </Link>
  );
};
