import React from 'react';
import { CheckCircle } from 'react-feather';

const AuthBenefits = () => (
  <div
    className="hidden md:flex flex-1 items-center justify-center"
    style={{
      backgroundImage: "url('/signup-bg.png')",
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  >
    <div className="w-[72%] mx-auto">
      <h2 className="text-dark font-semibold text-[32px] leading-tight mb-10">
        Double your revenue <br />
        without doubling your budget
      </h2>
      <span className="flex gap-2 mb-4 items-center">
        <CheckCircle size={20} color="#1774F8" />
        <p className="text-base font-medium text-dark ">
          Increase sales playbook adoption
        </p>
      </span>
      <span className="flex gap-2 mb-4 items-center">
        <CheckCircle size={20} color="#1774F8" />

        <p className="text-base font-medium text-dark ">
          Review calls 10x faster
        </p>
      </span>
      <span className="flex gap-2 mb-4 items-center">
        <CheckCircle size={20} color="#1774F8" />

        <p className="text-base font-medium text-dark ">
          Empower reps to train themselves to higher win rates
        </p>
      </span>
      <span className="flex gap-2 mb-4 items-center">
        <CheckCircle size={20} color="#1774F8" />

        <p className="text-base font-medium text-dark ">
          Sign up in under 30 seconds
        </p>
      </span>
    </div>
  </div>
);

export default AuthBenefits;
