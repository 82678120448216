import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import {
  CreditCard,
  HelpCircle,
  List,
  LogOut,
  Settings,
  Upload,
  User,
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { eventTracker } from '@/amplitude/eventTracker';
import { Button } from '@/components/Button/Button';
import { useRecordingSettingsModalStore } from '@/components/Calendar/RecordingSettings/hooks/useRecordingSettingsModalStore';
import { useHelpModalStore } from '@/components/HelpModal/useHelpModalStore';
import { CalendarButton } from '@/components/Shared/CalendarButton';
import { Logo } from '@/components/Shared/Logo';
import { useSubscribeModalStore } from '@/components/subscription/SubscribeModal/useSubscribeModalStore';
import { useSubscription } from '@/hooks/useSubscription';
import { useHomeSlice } from '@/pages/HomePage/slice';
import { selectUser } from '@/pages/HomePage/slice/selectors';
import cn from '@/utils/cn';
import { isDesktop } from '@/utils/deviceDetection';

import Toggle from '../HomePage/Toggle';
import { useUploadModalStore } from '../UploadCallModal/hooks/useUploadModalStore';
import { HeaderLinks } from './Header/HeaderLinks';
import { RecordButton } from './RecordButton';

const USERS_WITH_HIDDEN_SUBSCRIPTIONS = [
  'harsh@juicebox.work',
  'jordan@system2.fitness',
];

// Header bar component

const Header = () => {
  const dispatch = useDispatch();
  const { actions } = useHomeSlice();
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { setShowDefaultTrialBanner, openStripeCustomerPortal, isFree } =
    useSubscription();
  const { openSubscribeModal } = useSubscribeModalStore();
  const { openHelpModal } = useHelpModalStore();
  const { openRecordingSettingsModal } = useRecordingSettingsModalStore();

  const handleLogout = () => {
    setShowDefaultTrialBanner(true);
    eventTracker.auth.signOut();
    dispatch(actions.logout(null));
  };

  const goToEditPlaybookTemplate = () => {
    navigate('/playbook');
  };

  const toggleMode = () => {
    dispatch(actions.toggleMode());
  };

  const recordFlowEnabled =
    isDesktop() || process.env.REACT_APP_ENABLE_MOBILE_RECORD;

  const { openUploadModal } = useUploadModalStore();

  const { canCreateNewCalls } = useSubscription();

  return (
    <div
      data-testid="header"
      className="relative flex justify-between items-center px-12 pt-4 pb-3 border-b-[1px] border-[#EEE] text-[#717D96] bg-white z-10"
    >
      <Logo to="/calls" className="w-[300px]" />
      <HeaderLinks />
      <div className={cn('flex gap-1 justify-end')}>
        {recordFlowEnabled && <RecordButton withRecordingDropdown={true} />}
        <CalendarButton />
        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button as="div">
            <Button
              data-testid="header-menu"
              className="h-9 w-9 rounded-[10px] p-0"
              variant="secondary"
            >
              <User size={16} />
            </Button>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute z-100 right-0 mt-2 min-w-[198px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-2 py-3 flex flex-col gap-1">
                <Menu.Item>
                  <div className="pb-2 pl-2 z-100">
                    <div className="text-[#1C1C1C] font-medium">
                      {user?.username}
                    </div>
                    <div className="text-[10px] leading-3">{user?.email}</div>
                  </div>
                </Menu.Item>
                <div className="border-t border-t-main-gray"></div>
                <Menu.Item>
                  <div className="p-2">
                    <button
                      className="group flex w-full items-center rounded-md text-sm gap-2 disabled:opacity-30"
                      disabled={!canCreateNewCalls}
                      onClick={() => {
                        openUploadModal();
                        eventTracker.upload.uploadButtonClick();
                      }}
                    >
                      <Upload size={16} color="#1C1C1C" />
                      <span className="text-[#1C1C1C] font-medium">
                        Upload Call
                      </span>
                    </button>
                  </div>
                </Menu.Item>

                <Menu.Item>
                  <div className="p-2">
                    <button
                      className="group flex w-full items-center rounded-md text-sm gap-2"
                      onClick={openRecordingSettingsModal}
                    >
                      <Settings size={16} color="#1C1C1C" />
                      <span className="text-[#1C1C1C] font-medium">
                        Recording Settings
                      </span>
                    </button>
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div className="p-2">
                    <button
                      className="group flex w-full items-center rounded-md text-sm gap-2"
                      onClick={goToEditPlaybookTemplate}
                    >
                      <List size={16} color="#1C1C1C" />
                      <span className="text-[#1C1C1C] font-medium">
                        Edit Sales Playbook
                      </span>
                    </button>
                  </div>
                </Menu.Item>
                <Menu.Item
                  className="p-2 text-dark flex w-full items-center text-sm gap-2"
                  as="button"
                  onClick={openHelpModal}
                >
                  <HelpCircle size={16} />
                  <span className="font-medium">Help</span>
                </Menu.Item>
                {!USERS_WITH_HIDDEN_SUBSCRIPTIONS.includes(user.email) && (
                  <Menu.Item
                    className="p-2 text-dark flex w-full items-center text-sm gap-2"
                    as="button"
                    onClick={() => {
                      if (isFree) {
                        openSubscribeModal();
                      } else {
                        openStripeCustomerPortal();
                      }
                    }}
                  >
                    <CreditCard size={16} />
                    <span className="font-medium">Subscription</span>
                  </Menu.Item>
                )}
                {(user?.email?.includes('@silkchart.com') ||
                  process.env.NODE_ENV === 'development') && (
                  <Menu.Item>
                    <div className="p-2 text-dark flex w-full items-center text-sm justify-between">
                      <span className="font-medium">Live Mode</span>
                      <Toggle
                        value={user?.mode === 'live'}
                        onChange={toggleMode}
                      />
                    </div>
                  </Menu.Item>
                )}
                <Menu.Item>
                  <div className="p-2">
                    <button
                      className="group flex w-full items-center rounded-md text-sm gap-2"
                      onClick={handleLogout}
                    >
                      <LogOut size={16} color="#F00" />
                      <span className="text-[#F00] font-medium">Sign out</span>
                    </button>
                  </div>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default Header;
