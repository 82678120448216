import { useMutation } from '@tanstack/react-query';

import { forceCallAnalyze as forceCallAnalyzeAPI } from '@/api/call/forceCallAnalyze';

export const useForceCallReanalyze = () => {
  const { mutateAsync: forceCallAnalyze, ...rest } = useMutation({
    mutationFn: forceCallAnalyzeAPI,
  });

  return { forceCallAnalyze, ...rest };
};
