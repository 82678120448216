import { API } from '@/api/base';
import { PerformanceFeedbackItemType } from '@/enums/performanceFeedbackItemTypeEnum';

const backendURL = process.env.REACT_APP_BACKEND_URL as string;

const endpoints = {
  updateCallShowQualitativeFeedback: (callId: string) =>
    `${backendURL}/api/calls/${callId}/qualitative-analysis/show`,
};

export interface CallQualitativeFeedbackItemResponse {
  id: string;
  callId: string;
  description: string;
  title: string;
  type: PerformanceFeedbackItemType;
}

export const updateCallShowQualitativeFeedback =
  (callId: string) =>
  async (): Promise<CallQualitativeFeedbackItemResponse[]> => {
    try {
      const response = await API(
        endpoints.updateCallShowQualitativeFeedback(callId),
        {
          method: 'PUT',
        },
      );

      return response.json();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('fetch call qualitative feedback', err);
      return [];
    }
  };
