import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteGoogleAuth as deleteGoogleAuthAPI } from '@/api/auth/google/delete-google-auth';

export const useDeleteGoogleAuth = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: deleteGoogleAuth, ...rest } = useMutation({
    mutationFn: deleteGoogleAuthAPI,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['googleAuthStatus'] });
    },
  });

  return { deleteGoogleAuth, ...rest };
};
