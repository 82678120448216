import { useVirtualizer } from '@tanstack/react-virtual';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { ChevronDown, ChevronUp, Search, Trash } from 'react-feather';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import { Input } from '@/components/Input/Input';
import { Call } from '@/interfaces/call.interface';
import { formatTimestamp } from '@/utils/formatDate';
import logger from '@/utils/logger';
import { mapSpeakerLetterToNumber } from '@/utils/speakerMapping';

import { Button } from '../../Button/Button';
import {
  mapSpeakerNameToColor,
  mapSpeakerNumberToColor,
} from '../../HomePage/mapSpeakerNumberToColor';
import { useGetCallTranscript } from '../Summary/hooks/useGetCallTranscript';

export interface SelectedTranscriptionBlock {
  timestamp: number;
  text: string | null;
}

export interface CallTranscriptProps {
  call: Call;
  currentAudioTime: number;
  selectedBlock?: SelectedTranscriptionBlock;
  isPublic?: boolean;
}

const getStartEnd = (str: string, sub: string) => [
  str.indexOf(sub),
  str.indexOf(sub) + sub?.length + 1,
];

interface MappedUtterance {
  active: boolean;
  textContent: string;
  content: {
    content: string;
    done: boolean;
    selected: boolean;
    highlight?: boolean;
    highlightIndex?: number;
  }[];
  start: number;
  done: boolean;
  selected: boolean;
}

// Component for displaying transcript of calls
export const CallTranscript: React.FC<CallTranscriptProps> = ({
  call,
  currentAudioTime,
  selectedBlock,
  isPublic = false,
}) => {
  const { id } = useParams();
  const { callTranscript, isLoading } = useGetCallTranscript(
    id || '',
    isPublic,
  );

  const listRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  const [searchTerms, setSearchTerms] = useState('');
  const [searchResults, setSearchResults] = useState<number[]>([]);
  const [selectedSearchResultIndex, setSelectedSearchResultIndex] = useState(0);

  const speakersNameMap = Object.fromEntries(
    callTranscript?.nameMappings?.map(
      ({
        speakerCode,
        speakerName,
      }: {
        speakerCode: string;
        speakerName: string;
      }) => [speakerCode, speakerName],
    ) ?? [],
  );

  const formatTranscript = () => {
    const utterances = callTranscript?.utterances;
    if (!utterances) {
      return [];
    }

    const mappedUtterances: MappedUtterance[] = utterances.map(utterance => {
      const [startIndex, endIndex] = selectedBlock?.text
        ? getStartEnd(utterance.text, selectedBlock?.text)
        : [0, 0];

      let num = 0;

      return {
        active:
          utterance.startTimeInMs / 1000 <= currentAudioTime &&
          utterance.endTimeInMs / 1000 >= currentAudioTime,
        textContent: `${utterance.speaker}: ${utterance.words}`,
        content: utterance.words.map(w => {
          num += w.text.length + 1;

          const selected =
            utterance.startTimeInMs === selectedBlock?.timestamp
              ? ((startIndex === 0 && num >= startIndex) || num > startIndex) &&
                num <= endIndex
              : false;

          return {
            content: w.text,
            done: w.endTimeInMs / 1000 < currentAudioTime,
            selected,
          };
        }),
        start: utterance.startTimeInMs,
        done: utterance.endTimeInMs / 1000 < currentAudioTime,
        selected: utterance.startTimeInMs === selectedBlock?.timestamp,
      };
    });

    if (searchTerms) {
      const searchTermsWords = searchTerms
        .split(' ')
        .map(w => w.toLowerCase().trim())
        .filter(w => w);

      let highlighIndex = 0;

      //Perform word sequential search

      mappedUtterances.forEach(utterance => {
        utterance.content.forEach((word, wordIndex: number) => {
          const wordContent = word.content.toLowerCase();
          const index = wordContent.indexOf(searchTermsWords[0]);

          if (index !== -1) {
            let match = true;
            for (let j = 1; j < searchTermsWords.length; j++) {
              const nextWordObj = utterance.content[wordIndex + j];
              if (!nextWordObj) {
                match = false;
                break;
              }

              const nextWordContent = nextWordObj.content.toLowerCase();
              if (nextWordContent.indexOf(searchTermsWords[j]) === -1) {
                match = false;
                break;
              }
            }
            if (match) {
              for (let j = 0; j < searchTermsWords.length; j++) {
                utterance.content[wordIndex + j].highlight = true;
                utterance.content[wordIndex + j].highlightIndex = highlighIndex;
              }
              highlighIndex++;
            }
          }
        });
      });
    }

    return mappedUtterances;
  };

  const transcriptText = formatTranscript();

  useEffect(() => {
    if (!searchTerms) {
      setSearchResults([]);
      return;
    }

    const singleString = transcriptText
      .map(interaction =>
        interaction.content.map(word => word.content).join(' '),
      )
      .join(' ');

    const regex = new RegExp(searchTerms, 'gi');
    const results = [];

    let match;
    while ((match = regex.exec(singleString))) {
      results.push(match.index);
    }

    const firstElement = transcriptText.findIndex(text =>
      text.content.some(w => w.highlightIndex === 0),
    );
    if (firstElement) {
      rowVirtualizer.scrollToIndex(firstElement, {
        align: 'start',
      });
    }

    setSearchResults(results);
    setSelectedSearchResultIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerms]);

  const rowVirtualizer = useVirtualizer({
    count: transcriptText.length,
    getScrollElement: () => listRef.current,
    overscan: 15,
    estimateSize: (index: number) => {
      //We will use a thumb rule function to return the size in pixels of the text block based in its text content

      const calculateTextBlockHeight = (
        charCount: number,
        containerWidth: number,
        fontSize: number,
        lineHeight: number,
      ) => {
        // Estimate the average character width (0.5 * font size is a rough approximation)
        const avgCharWidth = 0.5 * fontSize;

        // Calculate the number of characters per line
        const charsPerLine = containerWidth / avgCharWidth;

        // Calculate the number of lines needed
        const numLines = Math.ceil(charCount / charsPerLine);

        // Calculate the height of the text block
        const textBlockHeight = numLines * lineHeight;

        return textBlockHeight;
      };

      const textContent = transcriptText[index].content
        .map(w => w.content)
        .join(' ');
      const charCount = textContent.length;

      const containerWidth =
        document.getElementById('transcript-content')?.clientWidth;
      const fontSize = 16;
      const lineHeight = 24;

      const textBlockHeight = calculateTextBlockHeight(
        charCount,
        containerWidth || 500,
        fontSize,
        lineHeight,
      );

      logger.debug(
        {
          textBlockHeight,
          transcriptUtterance: textContent,
        },
        'Text Block Height',
      );

      return textBlockHeight + 20;
    },
  });

  useEffect(() => {
    if (!selectedBlock || isLoading) {
      return;
    }

    const timer = setTimeout(() => {
      const selectedIndex = transcriptText.findIndex(text => text.selected);

      if (selectedIndex !== -1) {
        rowVirtualizer.scrollToIndex(selectedIndex, {
          align: 'center',
        });

        setTimeout(() => {
          const element = document.getElementById('selected');
          if (element) {
            element.scrollIntoView({
              block: 'nearest',
            });
          }
        }, 200);
      }
    }, 250);

    return () => {
      clearTimeout(timer);
    };
  }, [selectedBlock, isLoading]);

  useEffect(() => {
    if (!searchTerms) {
      return;
    }

    const transcriptElement = transcriptText.findIndex(text =>
      text.content.some(w => w.highlightIndex === selectedSearchResultIndex),
    );

    rowVirtualizer.scrollToIndex(transcriptElement, {
      behavior: 'smooth',
      align: 'start',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowVirtualizer, selectedSearchResultIndex]);

  useEffect(() => {
    logger.debug({ callTranscript }, 'Call Transcript');
  }, [callTranscript]);

  if (isLoading) {
    return (
      <div className="flex justify-center p-4">
        <ClipLoader color="rgb(37 99 235)" />
      </div>
    );
  }

  const renderItems = rowVirtualizer.getVirtualItems();

  const isEmpty = !transcriptText?.length;

  return (
    <div className="flex flex-col">
      {isEmpty && (
        <div className="flex flex-col items-center py-6 my-4">
          <span className="text-base text-dark font-bold">
            Transcript Empty
          </span>
          <span className="text-sm text-main-gray-dark font-medium mt-1">
            No words were captured in this recording.
          </span>
          {!isPublic && (
            <Button variant="danger" className="mt-4">
              <Trash size={16} className="text-white" />
              Delete this call
            </Button>
          )}
        </div>
      )}
      {!isEmpty && (
        <div className="flex border-b p-[16px] gap-[8px] items-center sticky top-[-48px] bg-white rounded-tr-2xl z-[5] rounded-tl-2xl">
          <Input
            placeholder="Search transcript"
            leftSlot={<Search size={20} />}
            value={searchTerms}
            onChange={e => {
              setSearchTerms(e.target.value);
            }}
            onKeyDown={
              searchTerms
                ? e => {
                    if (e.key === 'Enter') {
                      setSelectedSearchResultIndex(prev =>
                        searchResults.length > 0
                          ? (prev + 1) % searchResults.length
                          : 0,
                      );
                    }
                  }
                : undefined
            }
            className="flex-1 transition-all"
          />

          {searchTerms && (
            <>
              <div className="flex flex-row gap-3 flex-shrink-0 items-center w-42 justify-center">
                {searchResults.length > 0 ? (
                  <>
                    <span className="text-sm">
                      {selectedSearchResultIndex + 1} of {searchResults.length}
                    </span>
                    <div
                      className="inline-flex rounded-md shadow-sm"
                      role="group"
                    >
                      <Button
                        variant="secondary"
                        className="!rounded-tr-none !rounded-br-none !p-2"
                        onClick={() =>
                          setSelectedSearchResultIndex(prev => {
                            if (prev === 0) {
                              return searchResults.length - 1;
                            }
                            return prev - 1;
                          })
                        }
                      >
                        <ChevronUp size={16} />
                      </Button>
                      <Button
                        className="!rounded-tl-none !rounded-bl-none !p-2"
                        variant="secondary"
                        onClick={() =>
                          setSelectedSearchResultIndex(
                            prev => (prev + 1) % searchResults.length,
                          )
                        }
                      >
                        <ChevronDown size={16} />
                      </Button>
                    </div>
                  </>
                ) : (
                  <span className="text-[#2C6CF6] text-sm">
                    {searchResults.length} results found
                  </span>
                )}
              </div>
              <div>
                <Button onClick={() => setSearchTerms('')} variant="secondary">
                  Cancel
                </Button>
              </div>
            </>
          )}
        </div>
      )}
      {!isEmpty && (
        <div className="p-6">
          {callTranscript && (
            <div
              ref={listRef}
              id="transcript-content"
              className="max-h-[40rem] overflow-y-auto"
            >
              <div
                style={{
                  height: rowVirtualizer.getTotalSize(),
                  width: '100%',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    transform: `translateY(${renderItems[0]?.start ?? 0}px)`,
                  }}
                >
                  {renderItems.map(virtualRow => {
                    const text = transcriptText[virtualRow.index];

                    const textSpeaker = text?.textContent.split(':')[0];
                    const speakerName = speakersNameMap[textSpeaker];

                    let backgroundColor = undefined;

                    if (textSpeaker === call.mainSpeaker) {
                      backgroundColor = '#1774F8';
                    } else if (speakerName) {
                      backgroundColor = mapSpeakerNameToColor(speakerName);
                    } else {
                      backgroundColor = mapSpeakerNumberToColor(
                        mapSpeakerLetterToNumber(textSpeaker),
                      );
                    }

                    return (
                      <div
                        key={virtualRow.key}
                        data-index={virtualRow.index}
                        ref={rowVirtualizer.measureElement}
                        className="mb-4"
                        id={text.selected ? 'selected' : ''}
                      >
                        <div>
                          <div className="flex items-center gap-1 mb-1">
                            <div
                              className="rounded-full w-2 h-2"
                              style={{
                                backgroundColor,
                              }}
                            ></div>
                            <p className="text-sm font-semibold">
                              {speakerName
                                ? speakerName
                                : `Speaker ${mapSpeakerLetterToNumber(textSpeaker)}`}

                              {call.mainSpeaker === textSpeaker && ' (Seller)'}
                            </p>
                            <span className="ml-1.5 text-[#717D96] text-sm">
                              {formatTimestamp(text.start)}
                            </span>
                          </div>
                          <p
                            className={`${text.active ? 'bg-[#1774F80F] rounded-l p-[4px] text-black' : ''} ${
                              text.selected
                                ? 'bg-[#1774F80F] rounded-[10px] py-1 px-2'
                                : ''
                            }`}
                          >
                            {text?.content.map((w, index: number) => (
                              <span
                                key={index}
                                className={`${w.done ? 'font-semibold' : ''} ${w.selected ? 'text-[#2C6CF6]' : ''}`}
                              >
                                {w.highlight ? (
                                  <>
                                    <span
                                      className={`${w.highlightIndex === selectedSearchResultIndex ? 'bg-[#F89B11]/60 font-bold' : 'bg-[#F89B11]/20'}`}
                                      data-highlight-index={w.highlightIndex}
                                    >
                                      {w?.content}
                                    </span>{' '}
                                  </>
                                ) : (
                                  <> {w?.content} </>
                                )}
                              </span>
                            ))}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
