import React from 'react';
import { BarChart2, PhoneCall } from 'react-feather';
import { Link, useLocation } from 'react-router-dom';

import { OnboardingTooltip } from '@/components/Onboarding/OnboardingTooltip/OnboardingTooltip';
import cn from '@/utils/cn';

interface HeaderLinkProps extends React.ComponentProps<typeof Link> {
  title: string;
  icon: React.ReactNode;
  selected?: boolean;
}

const HeaderLink: React.FC<HeaderLinkProps> = ({
  to,
  icon,
  title,
  selected,
  className,
  ...restProps
}) => (
  <Link
    to={to}
    className={cn(
      'flex items-center gap-1 text-sm font-semibold',
      selected ? 'text-accent-blue' : 'text-gray-dark',
      className,
    )}
    {...restProps}
  >
    {icon}
    <span>{title}</span>
  </Link>
);

const links = [
  {
    title: 'Calls',
    icon: <PhoneCall size={16} />,
    to: '/calls',
  },
  {
    title: 'Insights',
    icon: <BarChart2 size={16} />,
    to: '/insights',
  },
] as const satisfies HeaderLinkProps[];

export const HeaderLinks: React.FC = () => {
  const location = useLocation();

  const activeTabIndex = links.findIndex(tab =>
    location.pathname.startsWith(tab.to),
  );

  return (
    <div className="flex items-center">
      <HeaderLink
        {...links[0]}
        selected={activeTabIndex === 0}
        className="mr-10"
      />

      <div>
        <OnboardingTooltip
          tooltipId="insights"
          placement="bottom-start"
          padding={24}
        />
      </div>
      <HeaderLink {...links[1]} selected={activeTabIndex === 1} />
    </div>
  );
};
