import React from 'react';
import Collapsible from 'react-collapsible';
import { Check, ChevronDown } from 'react-feather';

import { useQuickstartStore } from '@/components/Quickstart/hooks/useQuickstartStore';
import cn from '@/utils/cn';

interface ListChevronProps {
  isOpen: boolean;
}

const ListChevron: React.FC<ListChevronProps> = ({ isOpen }) => (
  <div>
    <ChevronDown
      size={16}
      className={cn(
        'text-main-gray-dark transition-transform',
        isOpen && 'rotate-180',
      )}
      aria-hidden="true"
    />
  </div>
);

const Checkmark: React.FC = () => (
  <div className="w-6 h-6 bg-accent-blue rounded-full flex items-center justify-center">
    <Check className="text-white" size={16} />
  </div>
);

interface NumberListItemProps {
  number: number;
}

const NumberListItem: React.FC<NumberListItemProps> = ({ number }) => (
  <div className="rounded-full bg-main-gray w-6 h-6 flex items-center justify-center">
    <span className="text-main-gray-dark font-medium text-sm">{number}</span>
  </div>
);

export interface QuickstartMenuProps {
  isStepCompleted: boolean;
  setOpenIndexes: React.Dispatch<React.SetStateAction<Record<number, string>>>;
  openIndexes: Record<number, string>;
  itemIndex: number;
  children: React.ReactNode;
  title: string;
}

export const QuickstartMenuItem: React.FC<QuickstartMenuProps> = ({
  isStepCompleted,
  setOpenIndexes,
  openIndexes,
  itemIndex,
  children,
  title,
}) => {
  const [clicked, setClicked] = React.useState<boolean>(false);
  const { itemsCompleted } = useQuickstartStore();

  const firstUncompletedIndex = Object.keys(itemsCompleted).findIndex(
    (key) => !itemsCompleted[parseInt(key)],
  );

  const isOpenByDefault =
    !isStepCompleted &&
    (openIndexes[itemIndex] === 'opened' ||
      firstUncompletedIndex === itemIndex);

  const isOpen =
    !!openIndexes[itemIndex] ||
    (!clicked &&
      !openIndexes[itemIndex] &&
      firstUncompletedIndex === itemIndex);

  return (
    <Collapsible
      triggerDisabled={isStepCompleted}
      transitionTime={100}
      open={isOpenByDefault}
      onOpening={() => {
        setClicked(true);
        setOpenIndexes({
          ...openIndexes,
          [itemIndex]: 'opened',
        });
      }}
      onClosing={() => {
        setClicked(true);
        setOpenIndexes({
          ...openIndexes,
          [itemIndex]: '',
        });
      }}
      trigger={
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center">
            {isStepCompleted ? (
              <Checkmark />
            ) : (
              <NumberListItem number={itemIndex + 1} />
            )}
            <span
              className={cn(
                'ml-1.5 font-semibold text-dark text-sm',
                isStepCompleted && 'text-main-gray-dark line-through',
              )}
            >
              {title}
            </span>
          </div>
          {!isStepCompleted && <ListChevron isOpen={isOpen} />}
        </div>
      }
    >
      {children}
    </Collapsible>
  );
};
