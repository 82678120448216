import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';

import { getInsightsCallsQuestionsInfo } from '@/api/insights/getInsightsCallsQuestionsInfo';
import { useInsightsAcrossCallsStore } from '@/components/InsightsAcrossCallsPage/hooks/useInsightsAcrossCallsStore';
import { InsightsEmptyState } from '@/components/InsightsAcrossCallsPage/InsightsEmptyState';
import { TopQuestionsLoadingState } from '@/components/InsightsAcrossCallsPage/TopQuestionsLoadingState';
import { useNavigateToTranscript } from '@/hooks/useNavigateToTranscript';
import cn from '@/utils/cn';
import { formatTimestamp } from '@/utils/formatDate';
import logger from '@/utils/logger';

import { CollapsibleItem } from '../CollapsibleItem/CollapsibleItem';
import { SectionTitle } from '../SectionTitle/SectionTitle';

const getPercentStyles = (percent: number) => {
  if (percent > 0.8) {
    return {
      color: '#0A7C00',
      background: '#C9E9C6',
      border: '1px solid #BCEAB8',
    };
  }

  if (percent > 0.4) {
    return {
      color: '#0FB600',
      background: '#E7F8E6',
      border: '1px solid #D1F1CF',
    };
  }

  return {
    color: '#BE7200',
    background: '#FEF5E7',
    border: '1px solid #FDEACD',
  };
};

const Title = ({
  title,
  percentage,
}: {
  title: string;
  percentage: number;
}) => {
  const percent = Number((percentage * 100).toFixed());

  return (
    <div className="flex gap-2 items-center text-sm font-medium">
      <div
        className="font-semibold w-[55px] flex items-center justify-center text-[14px] px-2.5 py-[4px] rounded-lg"
        style={getPercentStyles(percentage)}
      >
        <span>{percent}%</span>
      </div>
      <span>{title}</span>
    </div>
  );
};

export interface TopQuestionsProps {
  baseUrl?: string;
}

export const TopQuestions: React.FC<TopQuestionsProps> = ({
  baseUrl = 'calls',
}) => {
  const { selectedDateOption } = useInsightsAcrossCallsStore();

  const [showMoreIndexes, setShowMoreIndexes] = useState<number[]>([]);

  const { data, isLoading } = useQuery({
    queryFn: getInsightsCallsQuestionsInfo(selectedDateOption?.value),
    queryKey: ['insightsTopQuestions', selectedDateOption],
  });

  logger.debug({ data, isLoading }, 'TopQuestions');

  const { navigateToTranscript } = useNavigateToTranscript(baseUrl);

  const showMore = (categoryIndex: number, paginationIndex: number) => {
    showMoreIndexes[categoryIndex] = paginationIndex + 1;
    setShowMoreIndexes([...showMoreIndexes]);
  };

  const showLess = (categoryIndex: number) => {
    showMoreIndexes[categoryIndex] = 1;
    setShowMoreIndexes([...showMoreIndexes]);
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    const newIndexesArr: number[] = [];

    data.forEach(() => {
      newIndexesArr.push(1);
    });

    setShowMoreIndexes(newIndexesArr);
  }, [data]);

  if (!isLoading && !data?.length) {
    return (
      <div className="w-full py-[80px] border border-1 border-main-gray rounded-[20px]">
        <InsightsEmptyState isQuestionsView={true} />
      </div>
    );
  }

  return (
    <div className="mt-8">
      <SectionTitle className="mb-2">
        % of calls in which prospects raised questions about each topic
      </SectionTitle>
      {isLoading && <TopQuestionsLoadingState />}
      {!isLoading &&
        data?.map(([category, { topQuestions, percentage }], categoryIndex) => {
          const itemIndex = showMoreIndexes[categoryIndex];

          const hasMoreQuestions = topQuestions.length > itemIndex * 3;
          const questions = topQuestions.slice(0, itemIndex * 3);

          return (
            <div key={category} className="mb-1.5">
              <CollapsibleItem
                title={({ isOpen }) => (
                  <div className="flex justify-between items-center w-full">
                    <Title title={category} percentage={percentage} />

                    <button className="text-accent-blue focus:outline-accent-blue">
                      <ChevronUp
                        size={20}
                        className={cn(
                          'transition-transform',
                          !isOpen && 'rotate-180',
                        )}
                      />
                    </button>
                  </div>
                )}
                hideDefaultChevron
              >
                <div className="h-[1px] w-full my-[12px] bg-[#F6F6F6]" />
                {questions.map(
                  (
                    {
                      quote,
                      callId,
                      timestamp,
                      prospectTimestamp,
                      callName,
                      answer,
                    },
                    index: number,
                  ) => (
                    <div
                      className="mt-1 bg-[#F6F6F6] rounded-lg p-[14px]"
                      key={timestamp + index}
                    >
                      <span className="inline">
                        <span className="text-sm text-[#5C6A82] font-semibold inline">
                          {callName}
                        </span>
                        <span
                          className="text-[#2C6CF6] text-sm font-normal cursor-pointer inline ml-[3px]"
                          onClick={() =>
                            navigateToTranscript(
                              prospectTimestamp
                                ? {
                                    text: quote,
                                    timestamp: Number(prospectTimestamp),
                                    callId,
                                  }
                                : {
                                    text: answer,
                                    timestamp: Number(timestamp),
                                    callId,
                                  },
                            )
                          }
                        >
                          at {formatTimestamp(prospectTimestamp || timestamp)}
                        </span>
                      </span>
                      <p className="text-black text-sm font-semibold italic tracking-[-0.14px]">
                        `{quote}`
                      </p>
                    </div>
                  ),
                )}
                {topQuestions.length > 3 && (
                  <p
                    className="text-[12px] font-semibold text-[#2C6CF6] cursor-pointer mt-[8px] align-center px-[12px] flex items-center"
                    onClick={() =>
                      hasMoreQuestions
                        ? showMore(categoryIndex, itemIndex)
                        : showLess(categoryIndex)
                    }
                  >
                    {hasMoreQuestions ? (
                      <ChevronDown className="mr-[4px]" size="16px" />
                    ) : (
                      <ChevronUp className="mr-[4px]" size="16px" />
                    )}
                    {hasMoreQuestions ? 'Show more' : 'Show less'}
                  </p>
                )}
              </CollapsibleItem>
            </div>
          );
        })}
    </div>
  );
};
