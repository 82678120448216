import { API } from '@/api/base';
import { Response } from '@/api/response';

const backendURL = process.env.REACT_APP_BACKEND_URL as string;

const endpoints = {
  getCallNextSteps: (callId: string, isPublic: boolean) =>
    `${backendURL}/api/calls/${callId}/${isPublic ? 'public/' : ''}next-steps`,
};

export interface CallNextStepResponse {
  id: string;
  callId: string;
  text: string;
  timestamp: string | null;
  responsibleSpeaker: string | null;
  isDone: boolean;
}

export const getCallNextSteps =
  (callId: string, isPublic: boolean) =>
  async (): Promise<CallNextStepResponse[]> => {
    try {
      const response = await API(endpoints.getCallNextSteps(callId, isPublic), {
        method: 'GET',
      });

      const result: Response<CallNextStepResponse[]> = await response.json();

      return result.data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('fetch call next steps', err);
      return [];
    }
  };
