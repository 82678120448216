import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateRecordingSettings as updateRecordingSettingsAPI } from '@/api/calendar/update-recording-settings';

export const useUpdateRecordingSettings = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateRecordingSettings, ...rest } = useMutation({
    mutationFn: updateRecordingSettingsAPI,
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: ['recordingSettings'] });
      queryClient.refetchQueries({
        predicate: (query) => {
          return query.queryKey[0] === 'calendarEvents';
        },
      });
    },
  });

  return { updateRecordingSettings, ...rest };
};
