import React from 'react';
import { Zap } from 'react-feather';

import { Button } from '@/components/Button/Button';
import {
  FeedbackLimitType,
  useFeedbackLimitModalStore,
} from '@/components/CallDetailsPage/FeedbackLimitModal/useFeedbackLimitModalStore';
import { useSubscribeModalStore } from '@/components/subscription/SubscribeModal/useSubscribeModalStore';
import { useSubscription } from '@/hooks/useSubscription';

import Modal from '../../Shared/Modal';

export const FeedbackLimitModal: React.FC = () => {
  const { isFeedbackLimitModalOpen, closeFeedbackLimitModal, type } =
    useFeedbackLimitModalStore();
  const { isFree, openStripeCustomerPortal } = useSubscription();

  const { openSubscribeModal } = useSubscribeModalStore();

  const handleClick = () => {
    if (isFree) {
      openSubscribeModal();
      return;
    } else {
      openStripeCustomerPortal();
    }
    closeFeedbackLimitModal();
  };

  const renderSubtitle = (): string | undefined => {
    switch (type) {
      case FeedbackLimitType.PLAYBOOK:
        return 'Upgrade for unlimited Sales Playbook feedback';
      case FeedbackLimitType.SALES_COACH:
        return 'Upgrade for unlimited AI Sales Coaching';
      default:
        return;
    }
  };

  return (
    <Modal
      show={isFeedbackLimitModalOpen}
      onClose={closeFeedbackLimitModal}
      showCloseButton
      containerClassName="w-full max-w-[608px] m-0 p-[24px] flex flex-col"
    >
      <div className="flex flex-col items-center justify-center gap-6">
        <div className="w-16 h-16 bg-accent-yellow bg-opacity-16 rounded-full flex items-center justify-center">
          <div className="text-[28px]">🥺</div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <span className="text-2xl text-dark font-semibold">{`You've reached your feedback limit`}</span>
          {type && (
            <span className="text-base text-main-gray-dark font-medium mt-1">
              {renderSubtitle()}
            </span>
          )}
        </div>
        <Button variant="primary" onClick={handleClick}>
          <Zap size={16} className="text-white" />
          Upgrade & Get feedback
        </Button>
      </div>
    </Modal>
  );
};
