import React, { useEffect } from 'react';

import { RadioButton } from '@/components/Button/RadioButton';
import { useUpdateRecordingSettings } from '@/components/Calendar/RecordingSettings/hooks/useUpdateRecordingSettings';
import { RecordingOption } from '@/components/Calendar/RecordingSettings/RecordingOption';
import { useRecordingSettings } from '@/hooks/useRecordingSettings';

export const RecordButtonOptions: React.FC = () => {
  const [selectedRecordingOption, setSelectedRecordingOption] =
    React.useState<RecordingOption>(RecordingOption.JOIN);

  const { recordingSettingsData } = useRecordingSettings();
  const { updateRecordingSettings } = useUpdateRecordingSettings();

  useEffect(() => {
    if (!recordingSettingsData) return;

    setSelectedRecordingOption(
      recordingSettingsData.botJoinMeetings
        ? RecordingOption.JOIN
        : RecordingOption.DONT_JOIN,
    );
  }, [recordingSettingsData]);

  if (!recordingSettingsData) return null;

  const changeRecordingOption = async (option: RecordingOption) => {
    setSelectedRecordingOption(option);
    await updateRecordingSettings({
      botJoinMeetings: option === RecordingOption.JOIN,
      botAutoJoinAllMeetings: recordingSettingsData.botAutoJoinAllMeetings,
    });
  };

  return (
    <div
      className="min-w-[237px] bg-white rounded-[10px] flex flex-col justify-center p-2"
      style={{
        boxShadow:
          '0px 1px 6px -2px rgba(24, 39, 75, 0.10), 0px 2px 6px -2px rgba(24, 39, 75, 0.06)',
      }}
    >
      <div
        className="flex flex-row p-2 items-center"
        onClick={() => changeRecordingOption(RecordingOption.JOIN)}
      >
        <RadioButton
          selected={selectedRecordingOption === RecordingOption.JOIN}
        />
        <div className="flex flex-col justify-center">
          <div className="flex flex-row items-center">
            <span className="text-sm text-dark font-medium">Join meeting</span>
            <div className="flex bg-accent-blue/10 py-0.5 px-1 rounded-lg ml-1">
              <span className="text-[8px] text-accent-dark-blue font-medium">
                Recommended
              </span>
            </div>
          </div>
          <span className="text-[10px] text-main-gray-dark font-medium">
            SilkChart joins for best audio quality
          </span>
        </div>
      </div>
      <div
        className="flex flex-row p-2 items-center"
        onClick={() => changeRecordingOption(RecordingOption.DONT_JOIN)}
      >
        <RadioButton
          selected={selectedRecordingOption === RecordingOption.DONT_JOIN}
        />
        <div className="flex flex-col justify-center">
          <span className="text-sm text-dark font-medium">
            Don’t join meeting
          </span>
          <span className="text-[10px] text-main-gray-dark font-medium">
            Record privately from browser
          </span>
        </div>
      </div>
    </div>
  );
};
